import React, { useEffect } from 'react';
import {
  Footer,
  IconSpinner,
  LayoutSideNavigation,
  LayoutWrapperAccountSideNav,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';

import css from './SuccessSubscriptionPage.module.css';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getStripeSubscriptionLineItems } from '../../util/api';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { useHistory } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
import { authInfo } from '../../ducks/Auth.duck';

export const SuccessSubscriptionPageComponent = ({ location, intl }) => {
  const title = 'Subscription succes | Collabreate';
  const history = useHistory();

  const { id } = useParams();
  const dispatch = useDispatch();
  const { updateInProgress, updateProfileError } = useSelector(state => state.ProfileSettingsPage);

  useEffect(() => {
    try {
      const data = getStripeSubscriptionLineItems({ sessionId: id });
      data
        .then(res => {
          dispatch(
            updateProfile({
              protectedData: {
                checkoutSessionId: id,
                paidProductId: res.data[0].price.product,
                paidProductName: res.data[0].description,
                expires_at: res.expires_at,
              },
            })
          );
          if (!updateInProgress && !updateProfileError) {
            dispatch(authInfo());
            history.push('/campaigns');
          }
        })
        .catch(error => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Page title={title} scrollingDisabled={false}>
      <LayoutSideNavigation containerClassName={css.sidebarContainer}>
        <LayoutWrapperTopbar>
          <TopbarContainer
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSideNav />
        <LayoutWrapperMain>
          <div className={css.subscriptionInfoContainer}>
            <h2 className={css.sectionTitle}>
              {intl.formatMessage({
                id: 'SuccessSubscriptionPage.header',
              })}
            </h2>
            <div className={css.info}>
              {intl.formatMessage({
                id: 'SuccessSubscriptionPage.infoMessage',
              })}
            </div>
            {updateInProgress && <IconSpinner />}
            {updateProfileError && (
              <div className={css.errorInfo}>
                {intl.formatMessage({
                  id: 'SuccessSubscriptionPage.errorMessage',
                })}
              </div>
            )}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

const SuccessSubscriptionPage = injectIntl(SuccessSubscriptionPageComponent);

export default SuccessSubscriptionPage;
