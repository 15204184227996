import { Field } from 'formik';
import _ from 'lodash';
import { useRef } from 'react';

export const InputFieldComp = ({ name, type, label, placeholder, ...props }) => (
  <Field name={name}>
    {({
      field, // { name, value, onChange, onBlur }
      form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      meta,
    }) => (
      <div className="inputField">
        {type === 'textarea' ? (
          <textarea placeholder={placeholder} {...props} {...field} />
        ) : (
          <input type={type || 'text'} placeholder={placeholder} {...props} {...field} />
        )}
        {((meta.error && meta.touched) || props.error) && <div className="error">{meta.error}</div>}
      </div>
    )}
  </Field>
);

export const SelectFieldComp = ({ name, options, error }) => {
  const ref = useRef();

  return (
    <>
      <Field as="select" name={name} innerRef={ref} className={!ref.current?.value && 'invalid'}>
        <option value={''}>Select</option>
        {_.map(options, ({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Field>
      {error && <div className="error">{error}</div>}
    </>
  );
};
