import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { NamedLink, PaginationLinks } from '..';
import css from './CategoriesListingsPanel.module.css';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import ListingCard from '../ListingCard/ListingCard';
import Category from './Category/Category';
import TrainWithPro from './TrainWithAPro/TrainWithAPro';

const Icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.19959 13.8977C5.93347 13.6781 5.93347 13.3219 6.19959 13.1023L10.6875 9.39775C10.9536 9.17808 10.9536 8.82192 10.6875 8.60225L6.19959 4.89775C5.93347 4.67808 5.93347 4.32192 6.19959 4.10225C6.46572 3.88258 6.89719 3.88258 7.16331 4.10225L11.6512 7.80676C12.4496 8.46577 12.4496 9.53423 11.6512 10.1932L7.16331 13.8977C6.89719 14.1174 6.46572 14.1174 6.19959 13.8977Z"
      fill="#959595"
    />
  </svg>
);

const CategoriesListingsPanel = props => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    search,
    setActiveListing,
    origin,
    urlQueryParams,
    onMessage,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="CategoriesPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  return (
    <div className={classes}>
      <div className={css.listingCardFourColumn}>
        {!!listings.length ? (
          <>
            {listings.map(l => (
              <ListingCard
                className={css.listingCard}
                key={l.id.uuid}
                listing={l}
                renderSizes={cardRenderSizes}
                setActiveListing={setActiveListing}
                origin={origin}
                isFindPro={urlQueryParams.is_find_pro}
                onMessage={onMessage}
              />
            ))}
            {props.children}
          </>
        ) : (
          <h2>Nothing was found</h2>
        )}
      </div>
      {paginationLinks}
    </div>
  );
};

CategoriesListingsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

CategoriesListingsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default CategoriesListingsPanel;
