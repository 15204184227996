import React, { useState } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_TEXT,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { convertMoneyToNumber } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import {
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';

import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

import {
  H4,
  Page,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  LayoutSingleColumn,
  LayoutSideNavigation,
  LayoutWrapperTopbar,
  LayoutWrapperAccountSideNav,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  Modal,
} from '../../components';

// import TopbarContainer from '../TopbarContainer/TopbarContainer';
// import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
} from './ListingPage.shared';
import ActionBarMaybe from './ActionBarMaybe';
// import SectionTextMaybe from './SectionTextMaybe';
// import SectionDetailsMaybe from './SectionDetailsMaybe';
// import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
// import SectionReviews from './SectionReviews';
// import SectionAuthorMaybe from './SectionAuthorMaybe';
// import SectionMapMaybe from './SectionMapMaybe';
import SectionGallery from './SectionGallery';

import css from './ListingPage.module.css';
import TopbarContainer from '../TopbarContainer/TopbarContainer.js';
import { PinkButton } from '../../components/Button/Button.js';
import { EnquiryForm } from '../../forms/index.js';
import routeConfiguration from '../../routing/routeConfiguration';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

export const ListingPageComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );
  const [isDescriptionExpand, setIsDescriptionExpand] = useState(false);
  const [isBriefOpen, setIsBriefOpen] = useState(false);
  const [enquiryModalOpen, setEnquiryModalOpen] = useState(false);

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    sendEnquiryInProgress,
    sendEnquiryError,
  } = props;
  const config = useConfiguration();
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  // const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const transactionProcessAlias = publicData?.transactionProcessAlias;
  const processName = resolveLatestProcessName(transactionProcessAlias?.split('/')[0]);
  const isBooking = isBookingProcess(processName);
  const isPurchase = isPurchaseProcess(processName);
  const processType = isBooking ? ('booking' ? isPurchase : 'purchase') : 'inquiry';

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);
  const noPayoutDetailsSetWithOwnListing =
    isOwnListing && processType !== 'inquiry' && !currentUser?.attributes?.stripeConnected;
  const payoutDetailsWarning = noPayoutDetailsSetWithOwnListing ? (
    <span className={css.payoutDetailsWarning}>
      <FormattedMessage id="ListingPage.payoutDetailsWarning" values={{ processType }} />
      <NamedLink name="StripePayoutPage">
        <FormattedMessage id="ListingPage.payoutDetailsWarningLink" />
      </NamedLink>
    </span>
  ) : null;

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { formattedPrice } = priceData(price, config.currency, intl);

  const commonParams = { params, history, routes: routeConfiguration() };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      onSubmit(values);
    }
  };

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
        price: intl.formatNumber(convertMoneyToNumber(price), {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        priceCurrency: price.currency,
      }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));

  const isDescriptionTextShort = description?.length < 117;

  const onSubmitEnquiry = values => {
    const listingId = params?.id;
    const { message } = values;
    onSendInquiry(listingId, message.trim())
      .then(txId => {
        setEnquiryModalOpen(false);
        // Redirect to OrderDetailsPage
        history.push(`/order/${txId?.uuid}`);
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  };

  description;
  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSideNavigation containerClassName={css.sidebarContainer}>
        <LayoutWrapperTopbar>
          <TopbarContainer
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSideNav
          userProfile={true}
          isAvatar={true}
          currentUser={currentUser}
          isProUser={false}
        />
        <LayoutWrapperMain className={css.wrapperMain}>
          <div className={css.contentWrapperForProductLayout}>
            <div className={css.mainColumnForProductLayout}>
              {currentListing.id && noPayoutDetailsSetWithOwnListing ? (
                <ActionBarMaybe
                  className={css.actionBarForProductLayout}
                  isOwnListing={isOwnListing}
                  listing={currentListing}
                  showNoPayoutDetailsSet={noPayoutDetailsSetWithOwnListing}
                />
              ) : null}
              {/* {currentListing.id ? (
                <ActionBarMaybe
                  className={css.actionBarForProductLayout}
                  isOwnListing={isOwnListing}
                  listing={currentListing}
                  editParams={{
                    id: listingId.uuid,
                    slug: listingSlug,
                    type: listingPathParamType,
                    tab: listingTab,
                  }}
                />
              ) : null} */}
              <div className={css.listingCard}>
                <SectionGallery
                  className={css.gallery}
                  listing={currentListing}
                  variantPrefix={config.layout.listingImage.variantPrefix}
                />
                {/* <div className={css.imageContainer}>
                  <img
                    className={css.listingImage}
                    src={currentListing.images[0]?.attributes?.variants['scaled-medium']?.url}
                    alt="listing image"
                  ></img>
                </div> */}
                <div className={css.infoBlock}>
                  <div>
                    <h2 className={css.listingTitle}>{richTitle}</h2>
                    <p className={css.lisingLabel}>
                      {currentListing?.attributes?.publicData?.category ? currentListing?.attributes.publicData?.category[0] : '-'}
                    </p>
                    <p className={css.price}>
                      {formattedPrice}
                      <span className={css.itemPrice}>/ 1 item</span>
                    </p>
                    <p className={css.description}>
                      {' '}
                      {isDescriptionTextShort ? (
                        <div>{description}</div>
                      ) : (
                        <div>
                          {isDescriptionExpand ? (
                            <div>
                              {description}
                              <div
                                onClick={() => setIsDescriptionExpand(false)}
                                className={css.showMoreButton}
                              >
                                <svg
                                  width="18"
                                  height="16"
                                  viewBox="0 0 18 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g id="wrapper">
                                    <path
                                      id="Union"
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M4.10225 6.36713C4.32192 6.12762 4.67808 6.12762 4.89775 6.36713L8.60225 10.4063C8.82192 10.6458 9.17808 10.6458 9.39775 10.4063L13.1023 6.36714C13.3219 6.12762 13.6781 6.12762 13.8977 6.36714C14.1174 6.60665 14.1174 6.99497 13.8977 7.23448L10.1932 11.2736C9.53423 11.9921 8.46577 11.9921 7.80676 11.2736L4.10225 7.23448C3.88258 6.99497 3.88258 6.60665 4.10225 6.36713Z"
                                      fill="#DD58D6"
                                    />
                                  </g>
                                </svg>{' '}
                                See less
                              </div>
                            </div>
                          ) : (
                            <div>
                              <span>{`${description?.substring(0, 117)}...`}</span>
                              <div
                                onClick={() => setIsDescriptionExpand(true)}
                                className={css.showMoreButton}
                              >
                                <svg
                                  width="18"
                                  height="16"
                                  viewBox="0 0 18 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g id="wrapper">
                                    <path
                                      id="Union"
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M4.10225 6.36713C4.32192 6.12762 4.67808 6.12762 4.89775 6.36713L8.60225 10.4063C8.82192 10.6458 9.17808 10.6458 9.39775 10.4063L13.1023 6.36714C13.3219 6.12762 13.6781 6.12762 13.8977 6.36714C14.1174 6.60665 14.1174 6.99497 13.8977 7.23448L10.1932 11.2736C9.53423 11.9921 8.46577 11.9921 7.80676 11.2736L4.10225 7.23448C3.88258 6.99497 3.88258 6.60665 4.10225 6.36713Z"
                                      fill="#DD58D6"
                                    />
                                  </g>
                                </svg>{' '}
                                See more
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </p>
                  </div>
                  <div className={css.buttonContainer}>
                    <PinkButton
                      className={css.greyButton}
                      onClick={() => {
                        setEnquiryModalOpen(true);
                      }}
                    >
                      Ask a question
                    </PinkButton>
                    <PinkButton className={css.nextButton} onClick={() => setIsBriefOpen(true)}>
                      Next: Brief
                    </PinkButton>
                  </div>
                </div>
                {/* <div className={css.mobileHeading}>
                  <H4 as="h1" className={css.orderPanelTitle}>
                    <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                  </H4>
                </div> */}
                {/* <SectionTextMaybe text={description} showAsIngress /> */}
                {/* <SectionDetailsMaybe
            publicData={publicData}
            metadata={metadata}
            listingConfig={listingConfig}
            intl={intl}
          /> */}
                {/* {listingConfig.listingFields.reduce((pickedElements, config) => {
            const { key, enumOptions, includeForListingTypes, scope = 'public' } = config;
            const listingType = publicData?.listingType;
            const isTargetListingType =
              includeForListingTypes == null || includeForListingTypes.includes(listingType);

            const value =
              scope === 'public' ? publicData[key] : scope === 'metadata' ? metadata[key] : null;
            const hasValue = value != null;
            return isTargetListingType && config.schemaType === SCHEMA_TYPE_MULTI_ENUM
              ? [
                  ...pickedElements,
                  <SectionMultiEnumMaybe
                    key={key}
                    heading={config?.showConfig?.label}
                    options={createFilterOptions(enumOptions)}
                    selectedOptions={value || []}
                  />,
                ]
              : isTargetListingType && hasValue && config.schemaType === SCHEMA_TYPE_TEXT
              ? [
                  ...pickedElements,
                  <SectionTextMaybe key={key} heading={config?.showConfig?.label} text={value} />,
                ]
              : pickedElements;
          }, [])} */}

                {/* <SectionMapMaybe
            geolocation={geolocation}
            publicData={publicData}
            listingId={currentListing.id}
            mapsConfig={config.maps}
          />
          <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />
          <SectionAuthorMaybe
            title={title}
            listing={currentListing}
            authorDisplayName={authorDisplayName}
            onContactUser={onContactUser}
            isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
            onCloseInquiryModal={() => setInquiryModalOpen(false)}
            sendInquiryError={sendInquiryError}
            sendInquiryInProgress={sendInquiryInProgress}
            onSubmitInquiry={onSubmitInquiry}
            currentUser={currentUser}
            onManageDisableScrolling={onManageDisableScrolling}
          /> */}
              </div>
            </div>
            <div className={css.orderColumnForProductLayout}>
              <OrderPanel
                className={css.productOrderPanel}
                listing={currentListing}
                isOwnListing={isOwnListing}
                onSubmit={handleOrderSubmit}
                // authorLink={
                //   <NamedLink
                //     className={css.authorNameLink}
                //     name="ListingPage"
                //     params={params}
                //     to={{ hash: '#author' }}
                //   >
                //     {authorDisplayName}
                //   </NamedLink>
                // }
                payoutDetailsWarning={payoutDetailsWarning}
                author={ensuredAuthor}
                onManageDisableScrolling={onManageDisableScrolling}
                onContactUser={onContactUser}
                monthlyTimeSlots={monthlyTimeSlots}
                onFetchTimeSlots={onFetchTimeSlots}
                onFetchTransactionLineItems={onFetchTransactionLineItems}
                lineItems={lineItems}
                fetchLineItemsInProgress={fetchLineItemsInProgress}
                fetchLineItemsError={fetchLineItemsError}
                validListingTypes={config.listing.listingTypes}
                marketplaceCurrency={config.currency}
                dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
                marketplaceName={config.marketplaceName}
                currentListing={currentListing}
                isBriefOpen={isBriefOpen}
              />
            </div>
          </div>
          <Modal
            id="ListingPage.enquiry"
            contentClassName={css.enquiryModalContent}
            isOpen={isAuthenticated && enquiryModalOpen}
            onClose={() => {
              setEnquiryModalOpen(false);
            }}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <EnquiryForm
              className={css.enquiryForm}
              submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
              listingTitle={richTitle}
              authorDisplayName={authorDisplayName}
              sendEnquiryError={sendEnquiryError}
              onSubmit={onSubmitEnquiry}
              inProgress={sendEnquiryInProgress}
            />
          </Modal>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
  } = state.ListingPage;
  const { currentUser } = state.user;
  const { sendEnquiryInProgress, sendEnquiryError } = state.ListingPage;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
    sendEnquiryInProgress,
    sendEnquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(connect(mapStateToProps, mapDispatchToProps))(EnhancedListingPage);

export default ListingPage;
