import React, { useState } from 'react';
import { arrayOf, bool, func, object, oneOfType, shape, string } from 'prop-types';

// Import contexts and util modules
import { FormattedMessage, intlShape } from '../../util/reactIntl.js';
import { pathByRouteName } from '../../util/routes.js';
import { propTypes, LINE_ITEM_HOUR, DATE_TYPE_DATE, DATE_TYPE_DATETIME } from '../../util/types.js';
import { ensureTransaction } from '../../util/data.js';
import { createSlug } from '../../util/urlHelpers.js';
import { isTransactionInitiateListingNotFoundError } from '../../util/errors.js';
import { getProcess, isBookingProcessAlias } from '../../transactions/transaction.js';

// Import shared components
import {
  H3,
  H4,
  NamedLink,
  BookingBreakdown,
  Page,
  OrderBreakdown,
  LayoutSideNavigation,
  LayoutWrapperTopbar,
  LayoutWrapperAccountSideNav,
  LayoutWrapperMain,
} from '../../components/index.js';

import {
  bookingDatesMaybe,
  getBillingDetails,
  getFormattedTotalPrice,
  getShippingDetailsMaybe,
  getTransactionTypeData,
  hasDefaultPaymentMethod,
  hasPaymentExpired,
  hasTransactionPassedPendingPayment,
  processCheckoutWithPayment,
  setOrderPageInitialValues,
} from './CheckoutPageTransactionHelpers.js';
import { getErrorMessages } from './ErrorMessages.js';

import CustomTopbar from './CustomTopbar.js';
import StripePaymentForm from './StripePaymentForm/StripePaymentForm.js';
import DetailsSideCard from './DetailsSideCard.js';
import MobileListingImage from './MobileListingImage.js';
import MobileOrderBreakdown from './MobileOrderBreakdown.js';

import css from './CheckoutPage.module.css';
import { updateTranasctionData } from './CheckoutPage.duck.js';
import LineItemTotalPrice from '../../components/OrderBreakdown/LineItemTotalPrice.js';
import TopbarContainer from '../TopbarContainer/TopbarContainer.js';
import { useHistory } from 'react-router-dom';

// Stripe PaymentIntent statuses, where user actions are already completed
// https://stripe.com/docs/payments/payment-intents/status
const STRIPE_PI_USER_ACTIONS_DONE_STATUSES = ['processing', 'requires_capture', 'succeeded'];

// Payment charge options
const ONETIME_PAYMENT = 'ONETIME_PAYMENT';
const PAY_AND_SAVE_FOR_LATER_USE = 'PAY_AND_SAVE_FOR_LATER_USE';
const USE_SAVED_CARD = 'USE_SAVED_CARD';

const paymentFlow = (selectedPaymentMethod, saveAfterOnetimePayment) => {
  // Payment mode could be 'replaceCard', but without explicit saveAfterOnetimePayment flag,
  // we'll handle it as one-time payment
  return selectedPaymentMethod === 'defaultCard'
    ? USE_SAVED_CARD
    : saveAfterOnetimePayment
    ? PAY_AND_SAVE_FOR_LATER_USE
    : ONETIME_PAYMENT;
};

/**
 * Construct orderParams object using pageData from session storage, shipping details, and optional payment params.
 * Note: This is used for both speculate transition and real transition
 *       - Speculate transition is called, when the the component is mounted. It's used to test if the data can go through the API validation
 *       - Real transition is made, when the user submits the StripePaymentForm.
 *
 * @param {Object} pageData data that's saved to session storage.
 * @param {Object} shippingDetails shipping address if applicable.
 * @param {Object} optionalPaymentParams (E.g. paymentMethod or setupPaymentMethodForSaving)
 * @param {Object} config app-wide configs. This contains hosted configs too.
 * @returns orderParams.
 */
const getOrderParams = (pageData, shippingDetails, optionalPaymentParams, config) => {
  const quantity = pageData.orderData?.quantity;
  const quantityMaybe = quantity ? { quantity } : {};
  const deliveryMethod = pageData.orderData?.deliveryMethod;
  const deliveryMethodMaybe = deliveryMethod ? { deliveryMethod } : {};

  const { listingType, unitType } = pageData?.listing?.attributes?.publicData || {};
  const protectedDataMaybe = {
    protectedData: {
      ...getTransactionTypeData(listingType, unitType, config),
      ...deliveryMethodMaybe,
      ...shippingDetails,
    },
  };

  let addOns = [];

  if (pageData.orderData?.addOns) {
    addOns = Object.entries(pageData.orderData?.addOns)
      .filter(([key, value]) => Array.isArray(value) && value.length > 0)
      .map(([key, value]) =>
        value.map(item => ({ ...item, amount: +(item.amount + '00'), label: key }))
      )
      .flat();
  }

  // These are the order parameters for the first payment-related transition
  // which is either initiate-transition or initiate-transition-after-enquiry
  const orderParams = {
    listingId: pageData?.listing?.id,
    ...deliveryMethodMaybe,
    ...quantityMaybe,
    ...bookingDatesMaybe(pageData.orderData?.bookingDates),
    ...protectedDataMaybe,
    ...optionalPaymentParams,
    addOns,
  };
  return orderParams;
};

const fetchSpeculatedTransactionIfNeeded = (orderParams, pageData, fetchSpeculatedTransaction) => {
  const tx = pageData ? pageData.transaction : null;
  const pageDataListing = pageData.listing;
  const processName =
    tx?.attributes?.processName ||
    pageDataListing?.attributes?.publicData?.transactionProcessAlias?.split('/')[0];
  const process = processName ? getProcess(processName) : null;

  // If transaction has passed payment-pending state, speculated tx is not needed.
  const shouldFetchSpeculatedTransaction =
    !!pageData?.listing?.id &&
    !!pageData.orderData &&
    !!process &&
    !hasTransactionPassedPendingPayment(tx, process);

  if (shouldFetchSpeculatedTransaction) {
    const processAlias = pageData.listing.attributes.publicData?.transactionProcessAlias;
    const transactionId = tx ? tx.id : null;
    const isInquiryInPaymentProcess =
      tx?.attributes?.lastTransition === process.transitions.INQUIRE;

    const requestTransition = isInquiryInPaymentProcess
      ? process.transitions.REQUEST_PAYMENT_AFTER_INQUIRY
      : process.transitions.REQUEST_PAYMENT;
    const isPrivileged = process.isPrivileged(requestTransition);

    fetchSpeculatedTransaction(
      orderParams,
      processAlias,
      transactionId,
      requestTransition,
      isPrivileged
    );
  }
};

/**
 * Load initial data for the page
 *
 * Since the data for the checkout is not passed in the URL (there
 * might be lots of options in the future), we must pass in the data
 * some other way. Currently the ListingPage sets the initial data
 * for the CheckoutPage's Redux store.
 *
 * For some cases (e.g. a refresh in the CheckoutPage), the Redux
 * store is empty. To handle that case, we store the received data
 * to window.sessionStorage and read it from there if no props from
 * the store exist.
 *
 * This function also sets of fetching the speculative transaction
 * based on this initial data.
 */
export const loadInitialDataForStripePayments = ({
  pageData,
  fetchSpeculatedTransaction,
  fetchStripeCustomer,
  config,
}) => {
  // Fetch currentUser with stripeCustomer entity
  // Note: since there's need for data loading in "componentWillMount" function,
  //       this is added here instead of loadData static function.
  fetchStripeCustomer();
  // Fetch speculated transaction for showing price in order breakdown
  // NOTE: if unit type is line-item/item, quantity needs to be added.
  // The way to pass it to checkout page is through pageData.orderData
  const shippingDetails = {};
  const optionalPaymentParams = {};
  const orderParams = getOrderParams(pageData, shippingDetails, optionalPaymentParams, config);

  fetchSpeculatedTransactionIfNeeded(orderParams, pageData, fetchSpeculatedTransaction);
};

const handleSubmit = (values, process, props, stripe, submitting, setSubmitting) => {
  if (submitting) {
    return;
  }
  setSubmitting(true);

  const {
    history,
    config,
    routeConfiguration,
    speculatedTransaction,
    currentUser,
    stripeCustomerFetched,
    paymentIntent,
    dispatch,
    onInitiateOrder,
    onConfirmCardPayment,
    onConfirmPayment,
    onSendMessage,
    onSavePaymentMethod,
    onSubmitCallback,
    pageData,
    setPageData,
    sessionStorageKey,
  } = props;
  const { card, message, paymentMethod: selectedPaymentMethod, formValues } = values;
  const { saveAfterOnetimePayment: saveAfterOnetimePaymentRaw } = formValues;

  const saveAfterOnetimePayment =
    Array.isArray(saveAfterOnetimePaymentRaw) && saveAfterOnetimePaymentRaw.length > 0;
  const selectedPaymentFlow = paymentFlow(selectedPaymentMethod, saveAfterOnetimePayment);
  const hasDefaultPaymentMethodSaved = hasDefaultPaymentMethod(stripeCustomerFetched, currentUser);
  const stripePaymentMethodId = hasDefaultPaymentMethodSaved
    ? currentUser?.stripeCustomer?.defaultPaymentMethod?.attributes?.stripePaymentMethodId
    : null;

  // If paymentIntent status is not waiting user action,
  // confirmCardPayment has been called previously.
  const hasPaymentIntentUserActionsDone =
    paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);

  const requestPaymentParams = {
    pageData,
    speculatedTransaction,
    stripe,
    card,
    billingDetails: getBillingDetails(formValues),
    message,
    paymentIntent,
    hasPaymentIntentUserActionsDone,
    stripePaymentMethodId,
    process,
    onInitiateOrder,
    onConfirmCardPayment,
    onConfirmPayment,
    onSendMessage,
    onSavePaymentMethod,
    sessionStorageKey,
    stripeCustomer: currentUser?.stripeCustomer,
    isPaymentFlowUseSavedCard: selectedPaymentFlow === USE_SAVED_CARD,
    isPaymentFlowPayAndSaveCard: selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE,
    setPageData,
  };

  const shippingDetails = getShippingDetailsMaybe(formValues);
  // Note: optionalPaymentParams contains Stripe paymentMethod,
  // but that can also be passed on Step 2
  // stripe.confirmCardPayment(stripe, { payment_method: stripePaymentMethodId })
  const optionalPaymentParams =
    selectedPaymentFlow === USE_SAVED_CARD && hasDefaultPaymentMethodSaved
      ? { paymentMethod: stripePaymentMethodId }
      : selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE
      ? { setupPaymentMethodForSaving: true }
      : {};

  // These are the order parameters for the first payment-related transition
  // which is either initiate-transition or initiate-transition-after-enquiry
  const orderParams = getOrderParams(pageData, shippingDetails, optionalPaymentParams, config);

  // There are multiple XHR calls that needs to be made against Stripe API and Sharetribe Marketplace API on checkout with payments
  processCheckoutWithPayment(orderParams, requestPaymentParams)
    .then(response => {
      const { orderId, messageSuccess, paymentMethodSaved } = response;
      setSubmitting(false);

      const initialMessageFailedToTransaction = messageSuccess ? null : orderId;
      const orderDetailsPath = pathByRouteName('OrderDetailsPage', routeConfiguration, {
        id: orderId.uuid,
      });
      const initialValues = {
        initialMessageFailedToTransaction,
        savePaymentMethodFailed: !paymentMethodSaved,
      };
      updateTranasctionData({
        campaignId: pageData.orderData?.campaignId,
        briefName: pageData.orderData?.briefName,
        location: pageData.orderData?.location,
        location_address: pageData.orderData?.location_address,
        orderId: orderId,
        shipping_address: pageData.orderData?.shipping_address,
        deadline: pageData.orderData?.time,
        deliveryDate: pageData.orderData?.deliveryDate,
        reservationDate: pageData.orderData?.reservationDate,
        orderDate: pageData.orderData?.orderDate,
        description: pageData.orderData?.description,
        links: pageData.orderData?.links,
      });

      setOrderPageInitialValues(initialValues, routeConfiguration, dispatch);
      onSubmitCallback();
      history.push(orderDetailsPath);
    })
    .catch(err => {
      console.error(err);
      setSubmitting(false);
    });
};

const onStripeInitialized = (stripe, process, props) => {
  const { paymentIntent, onRetrievePaymentIntent, pageData } = props;
  const tx = pageData?.transaction || null;

  // We need to get up to date PI, if payment is pending but it's not expired.
  const shouldFetchPaymentIntent =
    stripe &&
    !paymentIntent &&
    tx?.id &&
    process?.getState(tx) === process?.states.PENDING_PAYMENT &&
    !hasPaymentExpired(tx, process);

  if (shouldFetchPaymentIntent) {
    const { stripePaymentIntentClientSecret } =
      tx.attributes.protectedData?.stripePaymentIntents?.default || {};

    // Fetch up to date PaymentIntent from Stripe
    onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret });
  }
};

export const CheckoutPageWithPayment = props => {
  const [submitting, setSubmitting] = useState(false);
  // Initialized stripe library is saved to state - if it's needed at some point here too.
  const [stripe, setStripe] = useState(null);
  const history = useHistory();

  const {
    scrollingDisabled,
    speculateTransactionError,
    speculatedTransaction: speculatedTransactionMaybe,
    initiateOrderError,
    confirmPaymentError,
    intl,
    currentUser,
    confirmCardPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    stripeCustomerFetched,
    pageData,
    processName,
    listingTitle,
    title,
    config,
  } = props;

  // Since the listing data is already given from the ListingPage
  // and stored to handle refreshes, it might not have the possible
  // deleted or closed information in it. If the transaction
  // initiate or the speculative initiate fail due to the listing
  // being deleted or closed, we should dig the information from the
  // errors and not the listing data.
  const listingNotFound =
    isTransactionInitiateListingNotFoundError(speculateTransactionError) ||
    isTransactionInitiateListingNotFoundError(initiateOrderError);

  const { listing, transaction, orderData } = pageData;
  const existingTransaction = ensureTransaction(transaction);
  const speculatedTransaction = ensureTransaction(speculatedTransactionMaybe, {}, null);

  // If existing transaction has line-items, it has gone through one of the request-payment transitions.
  // Otherwise, we try to rely on speculatedTransaction for order breakdown data.
  const tx =
    existingTransaction?.attributes?.lineItems?.length > 0
      ? existingTransaction
      : speculatedTransaction;
  const timeZone = listing?.attributes?.availabilityPlan?.timezone;
  const transactionProcessAlias = listing?.attributes?.publicData?.transactionProcessAlias;
  const unitType = listing?.attributes?.publicData?.unitType;
  const lineItemUnitType = `line-item/${unitType}`;
  const dateType = lineItemUnitType === LINE_ITEM_HOUR ? DATE_TYPE_DATETIME : DATE_TYPE_DATE;
  const txBookingMaybe = tx?.booking?.id ? { booking: tx.booking, dateType, timeZone } : {};

  // Show breakdown only when (speculated?) transaction is loaded
  // (i.e. it has an id and lineItems)
  //

  const breakdown =
    tx.id && tx.attributes.lineItems?.length > 0 ? (
      <>
        <OrderBreakdown
          className={css.orderBreakdown}
          userRole="customer"
          transaction={tx}
          {...txBookingMaybe}
          currency={config.currency}
          marketplaceName={config.marketplaceName}
        />
        <div className={css.horizontalRule}></div>
        <p className={css.subTitle}>Order summary</p>
        <p className={css.breakdownRow}>
          <div className={css.key}>Item</div>
          <div className={css.value}>{listingTitle}</div>
        </p>
        {/* <p className={css.breakdownRow}>
          <div className={css.key}>Order date</div>
          <div className={css.value}>{orderDate}</div>
        </p> */}
        <p className={css.breakdownRow}>
          <div className={css.key}>Reservation/PR arrival</div>
          <div className={css.value}>{pageData?.orderData?.reservationDate}</div>
          <div className={css.key}>Delivery date</div>
          <div className={css.value}>{pageData?.orderData?.deliveryDate}</div>
        </p>
        <div className={css.horizontalRule}></div>
        <LineItemTotalPrice transaction={tx} isProvider={false} intl={intl} />
      </>
    ) : null;
  //
  const totalPrice =
    tx?.attributes?.lineItems?.length > 0 ? getFormattedTotalPrice(tx, intl) : null;

  const process = processName ? getProcess(processName) : null;
  const transitions = process.transitions;
  const isPaymentExpired = hasPaymentExpired(existingTransaction, process);

  // Allow showing page when currentUser is still being downloaded,
  // but show payment form only when user info is loaded.
  const showPaymentForm = !!(
    currentUser &&
    !listingNotFound &&
    !initiateOrderError &&
    !speculateTransactionError &&
    !retrievePaymentIntentError &&
    !isPaymentExpired
  );

  const firstImage = listing?.images?.length > 0 ? listing.images[0] : null;

  const listingLink = (
    <NamedLink
      name="ListingPage"
      params={{ id: listing?.id?.uuid, slug: createSlug(listingTitle) }}
    >
      <FormattedMessage id="CheckoutPage.errorlistingLinkText" />
    </NamedLink>
  );

  const errorMessages = getErrorMessages(
    listingNotFound,
    initiateOrderError,
    isPaymentExpired,
    retrievePaymentIntentError,
    speculateTransactionError,
    listingLink
  );

  const txTransitions = existingTransaction?.attributes?.transitions || [];
  const hasInquireTransition = txTransitions.find(tr => tr.transition === transitions.INQUIRE);
  const showInitialMessageInput = !hasInquireTransition;

  // Get first and last name of the current user and use it in the StripePaymentForm to autofill the name field
  const userName = currentUser?.attributes?.profile
    ? `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`
    : null;

  // If paymentIntent status is not waiting user action,
  // confirmCardPayment has been called previously.
  const hasPaymentIntentUserActionsDone =
    paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);

  // If your marketplace works mostly in one country you can use initial values to select country automatically
  // e.g. {country: 'FI'}

  const initalValuesForStripePayment = { name: userName, recipientName: userName };
  const askShippingDetails = false;
  // orderData?.deliveryMethod === 'shipping' &&
  // !hasTransactionPassedPendingPayment(existingTransaction, process);

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation containerClassName={css.sidebarContainer}>
        <LayoutWrapperTopbar>
          <TopbarContainer
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSideNav
          userProfile={true}
          isAvatar={true}
          currentUser={currentUser}
          isProUser={false}
        />
        <LayoutWrapperMain className={css.wrapperMain}>
          {/* <CustomTopbar intl={intl} /> */}
          <div className={css.contentContainer}>
            {/* <MobileListingImage
              listingTitle={listingTitle}
              author={listing?.author}
              firstImage={firstImage}
              layoutListingImageConfig={config.layout.listingImage}
            /> */}

            <div className={css.orderFormContainer}>
              <div className={css.breadcrumbs}>
                <NamedLink className={css.breadcrumbGray} name="CategoriesPage">
                  Marketplace
                </NamedLink>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.19959 12.3536C4.93347 12.1583 4.93347 11.8417 5.19959 11.6464L9.6875 8.35355C9.95362 8.15829 9.95362 7.84171 9.6875 7.64645L5.19959 4.35355C4.93347 4.15829 4.93347 3.84171 5.19959 3.64645C5.46572 3.45118 5.89719 3.45118 6.16331 3.64645L10.6512 6.93934C11.4496 7.52513 11.4496 8.47487 10.6512 9.06066L6.16331 12.3536C5.89719 12.5488 5.46572 12.5488 5.19959 12.3536Z"
                    fill="#656565"
                  />
                </svg>
                <NamedLink
                  className={css.breadcrumbGray}
                  name="ProfilePage"
                  params={{ id: listing?.author.id.uuid }}
                >
                  {listing?.author.attributes.profile.displayName}
                </NamedLink>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.19959 12.3536C4.93347 12.1583 4.93347 11.8417 5.19959 11.6464L9.6875 8.35355C9.95362 8.15829 9.95362 7.84171 9.6875 7.64645L5.19959 4.35355C4.93347 4.15829 4.93347 3.84171 5.19959 3.64645C5.46572 3.45118 5.89719 3.45118 6.16331 3.64645L10.6512 6.93934C11.4496 7.52513 11.4496 8.47487 10.6512 9.06066L6.16331 12.3536C5.89719 12.5488 5.46572 12.5488 5.19959 12.3536Z"
                    fill="#656565"
                  />
                </svg>
                <NamedLink
                  className={css.breadcrumbGray}
                  name="ListingPage"
                  params={{ id: listing?.id?.uuid, slug: createSlug(listingTitle) }}
                >
                  {listing?.attributes.title}
                </NamedLink>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.19959 12.3536C4.93347 12.1583 4.93347 11.8417 5.19959 11.6464L9.6875 8.35355C9.95362 8.15829 9.95362 7.84171 9.6875 7.64645L5.19959 4.35355C4.93347 4.15829 4.93347 3.84171 5.19959 3.64645C5.46572 3.45118 5.89719 3.45118 6.16331 3.64645L10.6512 6.93934C11.4496 7.52513 11.4496 8.47487 10.6512 9.06066L6.16331 12.3536C5.89719 12.5488 5.46572 12.5488 5.19959 12.3536Z"
                    fill="#656565"
                  />
                </svg>
                <span className={css.breadcrumbCurrent}>Checkout</span>
              </div>
              <div className={css.headingContainer}>
                <H3 as="h1" className={css.heading}>
                  Checkout
                </H3>
                <H4 as="h2" className={css.subtitle}>
                  Your payment will be held as a deposit and will begin processing once the content
                  has been delivered and approved.
                </H4>
              </div>

              {/* <MobileOrderBreakdown
                speculateTransactionErrorMessage={errorMessages.speculateTransactionErrorMessage}
                breakdown={breakdown}
              /> */}

              <section className={css.paymentContainer}>
                {errorMessages.initiateOrderErrorMessage}
                {errorMessages.listingNotFoundErrorMessage}
                {errorMessages.speculateErrorMessage}
                {errorMessages.retrievePaymentIntentErrorMessage}
                {errorMessages.paymentExpiredMessage}

                {showPaymentForm ? (
                  <StripePaymentForm
                    className={css.paymentForm}
                    onSubmit={values =>
                      handleSubmit(values, process, props, stripe, submitting, setSubmitting)
                    }
                    inProgress={submitting}
                    formId="CheckoutPagePaymentForm"
                    authorDisplayName={listing?.author?.attributes?.profile?.displayName}
                    showInitialMessageInput={showInitialMessageInput}
                    initialValues={initalValuesForStripePayment}
                    initiateOrderError={initiateOrderError}
                    confirmCardPaymentError={confirmCardPaymentError}
                    confirmPaymentError={confirmPaymentError}
                    hasHandledCardPayment={hasPaymentIntentUserActionsDone}
                    loadingData={!stripeCustomerFetched}
                    defaultPaymentMethod={
                      hasDefaultPaymentMethod(stripeCustomerFetched, currentUser)
                        ? currentUser.stripeCustomer.defaultPaymentMethod
                        : null
                    }
                    paymentIntent={paymentIntent}
                    onStripeInitialized={stripe => {
                      setStripe(stripe);
                      return onStripeInitialized(stripe, process, props);
                    }}
                    askShippingDetails={askShippingDetails}
                    showPickUplocation={orderData?.deliveryMethod === 'pickup'}
                    listingLocation={listing?.attributes?.publicData?.location}
                    totalPrice={totalPrice}
                    locale={config.localization.locale}
                    stripePublishableKey={config.stripe.publishableKey}
                    marketplaceName={config.marketplaceName}
                    isBooking={isBookingProcessAlias(transactionProcessAlias)}
                    isFuzzyLocation={config.maps.fuzzy.enabled}
                    history={history}
                  />
                ) : null}
              </section>
            </div>
            {/* <DetailsSideCard
              listing={listing}
              listingTitle={listingTitle}
              author={listing?.author}
              firstImage={firstImage}
              layoutListingImageConfig={config.layout.listingImage}
              speculateTransactionErrorMessage={errorMessages.speculateTransactionErrorMessage}
              isInquiryProcess={false}
              processName={processName}
              breakdown={breakdown}
              intl={intl}
            /> */}
          </div>
        </LayoutWrapperMain>
      </LayoutSideNavigation>
    </Page>
  );
};

CheckoutPageWithPayment.defaultProps = {
  initiateOrderError: null,
  confirmPaymentError: null,
  listing: null,
  orderData: {},
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  currentUser: null,
  paymentIntent: null,
};

CheckoutPageWithPayment.propTypes = {
  scrollingDisabled: bool.isRequired,
  listing: propTypes.listing,
  orderData: object,
  fetchStripeCustomer: func.isRequired,
  stripeCustomerFetched: bool.isRequired,
  fetchSpeculatedTransaction: func.isRequired,
  speculateTransactionInProgress: bool.isRequired,
  speculateTransactionError: propTypes.error,
  speculatedTransaction: propTypes.transaction,
  transaction: propTypes.transaction,
  currentUser: propTypes.currentUser,
  params: shape({
    id: string,
    slug: string,
  }).isRequired,
  onConfirmPayment: func.isRequired,
  onInitiateOrder: func.isRequired,
  onConfirmCardPayment: func.isRequired,
  onRetrievePaymentIntent: func.isRequired,
  onSavePaymentMethod: func.isRequired,
  onSendMessage: func.isRequired,
  initiateOrderError: propTypes.error,
  confirmPaymentError: propTypes.error,
  // confirmCardPaymentError comes from Stripe so that's why we can't expect it to be in a specific form
  confirmCardPaymentError: oneOfType([propTypes.error, object]),
  paymentIntent: object,

  // from connect
  dispatch: func.isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default CheckoutPageWithPayment;
