import { Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import SocialMediaSignup from './SocialMediaSignup';
import { FormattedMessage, injectIntl } from 'react-intl';
import CreatSingupStyle from '../styles/CreatSingup.style';
import { InputFieldComp } from './CommonComp/FieldComponent';
import { compose } from 'redux';
import { PinkButton } from '../../components/Button/Button';
import { useSelector } from 'react-redux';

const CreateAccountForm = props => {
  const {
    _handleCreateAccountSubmit,
    createInitialValues,
    validationSchema,
    _intelMessage,
    setLoginPage,
    setTosModalOpen,
    setIsCreator,
    signupError,
    signupErrorMessage,
    authWithGoogle,
    authWithFacebook,
    history,
    section,
    setSelectedSignupProcess,
    selectedSignupProcess,
    authInProgress,
  } = props;

  useEffect(() => {
    setIsCreator(section === 'learner' ? false : true);
  }, [section]);

  const termsLink = (
    <a
      className="linksingup"
      onClick={() => setTosModalOpen(true)}
      role="button"
      tabIndex="0"
      // onKeyUp={handleTermsKeyUp}
    >
      <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
    </a>
  );

  return (
    <CreatSingupStyle>
      <div className="MainCreateAccount_Class">
        <section className="createFirstSection commonWidth">
          {section === 'learner' ? (
            <>
              <h1 className="header">Create a brand or agency account</h1>
              <p className="desc">
                Are you a creator?{' '}
                <span onClick={() => setSelectedSignupProcess(2)}>Sign Up Here</span>
              </p>
            </>
          ) : (
            <>
              <h1 className="header">Create a creator account</h1>
              <p className="desc">
                Are you a brand or agency?{' '}
                <span onClick={() => setSelectedSignupProcess(1)}>Sign Up Here</span>
              </p>
            </>
          )}
          {/* {section === 'pro' && (
            <div className="actionsButton">
              <SocialMediaSignup {...{ authWithGoogle, authWithFacebook }} />
            </div>
          )} */}

          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={createInitialValues}
            onSubmit={(values, action) => _handleCreateAccountSubmit(values)}
          >
            {props => (
              <Form className="signupForm" style={{ marginTop: 40 }}>
                <div className="allInput_section fieldCommonCss">
                  <div className="field firstLast_name">
                    <InputFieldComp
                      name="firstName"
                      label={_intelMessage('SignupForm.firstNameLabel')}
                      placeholder={_intelMessage('SignupForm.firstNamePlaceholder')}
                    />
                    <InputFieldComp
                      name="lastName"
                      label={_intelMessage('SignupForm.lastNameLabel')}
                      placeholder={_intelMessage('SignupForm.lastNamePlaceholder')}
                    />
                  </div>
                  {section === 'learner' && (
                    <InputFieldComp
                      name="company"
                      label={_intelMessage('SignupForm.companyLabel')}
                      placeholder={_intelMessage('SignupForm.companyPlaceholder')}
                    />
                  )}
                  <InputFieldComp
                    name="email"
                    label={_intelMessage('SignupForm.emailLabel')}
                    placeholder={_intelMessage('SignupForm.emailPlaceholder')}
                  />
                  <InputFieldComp
                    type="password"
                    name="password"
                    label={_intelMessage('SignupForm.passwordLabel')}
                    placeholder={_intelMessage('SignupForm.passwordPlaceholder')}
                  />

                  <div className="checkboxInput">
                    <Field id="term" type="checkbox" name="term" />
                    <label htmlFor="term">
                      <FormattedMessage
                        id="SignupForm.termsAndConditionsAcceptText"
                        values={{ termsLink }}
                      />
                    </label>
                    <div className="error">{props.errors.term}</div>
                  </div>
                </div>
                <div className="formsubmit">
                  <PinkButton type="submit" disabled={authInProgress}>
                    Create account
                  </PinkButton>
                  <p>
                    Already have an account?{' '}
                    <a
                      onClick={() => {
                        setLoginPage(true);
                        history.push('/login');
                      }}
                    >
                      Log in
                    </a>
                  </p>
                </div>
                {signupError && <div>{signupErrorMessage}</div>}
              </Form>
            )}
          </Formik>
        </section>
        <section className="createSecondSection imageSection">
          <img src={require('../assest/createSignupImage.webp')} alt="image1" />
          <div className="imageContent">
            <div>
              {section === 'learner' ? (
                <h3>Collaborate with multiple vetted creators at once</h3>
              ) : (
                <h3>Create More, Earn More</h3>
              )}

              <span>Connect and collaborate to produce engaging content </span>
            </div>
          </div>
        </section>
      </div>
    </CreatSingupStyle>
  );
};

export default CreateAccountForm;
