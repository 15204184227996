import React, { Component } from 'react';
import { arrayOf, bool, func, node, object, oneOf, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { displayPrice } from '../../../util/configHelpers';
import { propTypes } from '../../../util/types';
import { userDisplayNameAsString } from '../../../util/data';
import { isMobileSafari } from '../../../util/userAgent';
import lightningIcon from '../../../assets/icons/lightning.svg';

import {
  AvatarMedium,
  Button,
  Modal,
  NamedLink,
  PrimaryButton,
  UserDisplayName,
} from '../../../components';

import { stateDataShape } from '../TransactionPage.stateData';
import SendMessageForm from '../SendMessageForm/SendMessageForm';

// These are internal components that make this file more readable.
import BreakdownMaybe from './BreakdownMaybe';
import DeliveryInfoMaybe from './DeliveryInfoMaybe';
import BookingLocationMaybe from './BookingLocationMaybe';
import FeedSection from './FeedSection';
import ActionButtonsMaybe from './ActionButtonsMaybe';
import DiminishedActionButtonMaybe from './DiminishedActionButtonMaybe';

import css from './TransactionPanel.module.css';
import { SecondaryButton, TertiaryButton } from '../../../components/Button/Button';
import ModalV2Standard from '../../../components/ModalV2/ModalV2Standard';

// Helper function to get display names for different roles
const displayNames = (currentUser, provider, customer, intl) => {
  const authorDisplayName = <UserDisplayName user={provider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={customer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && customer?.id && currentUser.id.uuid === customer?.id?.uuid;
  const currentUserIsProvider =
    currentUser.id && provider?.id && currentUser.id.uuid === provider?.id?.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(provider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(customer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      showFileInput: false,
      url: null,
      clearFileInput: false,
      isSubmitModalOpen: false,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.onVideoMessageSubmit = this.onVideoMessageSubmit.bind(this);
    this.onCloseFileInput = this.onCloseFileInput.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onCloseFileInput() {
    this.setState({ showFileInput: false });
  }

  onMessageSubmit(values, form) {
    const message = values?.message ? values?.message.trim() : null;
    const { transactionId, onSendMessage, config } = this.props;

    if (!message) {
      return;
    }
    onSendMessage(transactionId, message, config)
      .then(messageId => {
        form.reset();
        if (this.state.showFileInput) {
          this.setState({ clearFileInput: true });
          this.setState({ showFileInput: false });
        }

        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
    //
  }

  onVideoMessageSubmit(values, form) {
    const message = values?.message ? values?.message.trim() : null;
    const { transactionId, onSendMessage, config } = this.props;

    if (!message) {
      return;
    }
    onSendMessage(transactionId, message, config)
      .then(messageId => {
        form.reset();
        if (this.state.showFileInput) {
          this.setState({ clearFileInput: true });
          this.setState({ showFileInput: false });
        }
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
    //
  }

  onRevisionMessage(values, form) {
    values.message = 'Customer requested revision';
    this.onMessageSubmit(values, form);
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transactionRole,
      listing,
      customer,
      provider,
      hasTransitions,
      protectedData,
      metadata,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      onOpenDisputeModal,
      intl,
      stateData,
      showBookingLocation,
      activityFeed,

      //
      isInquiryProcess,
      orderBreakdown,
      orderPanel,
      config,
    } = this.props;

    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';

    const listingDeleted = !!listing?.attributes?.deleted;
    const isCustomerBanned = !!customer?.attributes?.banned;
    const isCustomerDeleted = !!customer?.attributes?.deleted;
    const isProviderBanned = !!provider?.attributes?.banned;
    const isProviderDeleted = !!provider?.attributes?.deleted;

    const providerDisplayName = provider?.attributes?.profile?.publicData?.displayName;

    const customerDisplayName = customer?.attributes?.profile?.publicData?.displayName;

    const { authorDisplayName, otherUserDisplayNameString } = displayNames(
      currentUser,
      provider,
      customer,
      intl
    );

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const listingTitle = listingDeleted ? deletedListingTitle : listing?.attributes?.title;
    const firstImage = listing?.images?.length > 0 ? listing?.images[0] : null;

    const actionButtons = (
      <ActionButtonsMaybe
        showButtons={stateData.showActionButtons}
        primaryButtonProps={stateData?.primaryButtonProps}
        secondaryButtonProps={stateData?.secondaryButtonProps}
        isListingDeleted={listingDeleted}
        isProvider={isProvider}
      />
    );

    const listingType = listing?.attributes?.publicData?.listingType;
    const listingTypeConfigs = config.listing.listingTypes;
    const listingTypeConfig = listingTypeConfigs.find(conf => conf.listingType === listingType);
    const showPrice = isInquiryProcess && displayPrice(listingTypeConfig);

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const deliveryMethod = protectedData?.deliveryMethod;

    const classes = classNames(rootClassName || css.root, className);

    const setUrl = url => {
      this.setState({ url: url });
    };

    return (
      <div className={classes}>
        <div className={css.container}>
          <div className={css.txInfo}>
            {/* <DetailCardImage
              rootClassName={css.imageWrapperMobile}
              avatarWrapperClassName={css.avatarWrapperMobile}
              listingTitle={listingTitle}
              image={firstImage}
              provider={provider}
              isCustomer={isCustomer}
              listingImageConfig={config.layout.listingImage}
            /> */}
            {isProvider ? (
              <div className={css.avatarWrapperProviderDesktop}>
                <AvatarMedium
                  user={customer}
                  className={css.topbarAvatarDesktop}
                  disableProfileLink={true}
                />
                <p className={css.userName}>{customerDisplayName}</p>
              </div>
            ) : (
              <div className={css.avatarWrapperProviderDesktop}>
                <AvatarMedium user={provider} className={css.topbarAvatarDesktop} />
                <p className={css.userName}>{providerDisplayName}</p>
              </div>
            )}
            {/* <PanelHeading
              processName={stateData.processName}
              processState={stateData.processState}
              showExtraInfo={stateData.showExtraInfo}
              showPriceOnMobile={showPrice}
              price={listing?.attributes?.price}
              intl={intl}
              deliveryMethod={deliveryMethod}
              isPendingPayment={!!stateData.isPendingPayment}
              transactionRole={transactionRole}
              providerName={authorDisplayName}
              customerName={customerDisplayName}
              isCustomerBanned={isCustomerBanned}
              listingId={listing?.id?.uuid}
              listingTitle={listingTitle}
              listingDeleted={listingDeleted}
            /> */}
            {/* <InquiryMessageMaybe
              protectedData={protectedData}
              showInquiryMessage={isInquiryProcess}
              isCustomer={isCustomer}
            /> */}
            {!isInquiryProcess ? (
              <div className={css.orderDetails}>
                <div className={css.orderDetailsMobileSection}>
                  <BreakdownMaybe
                    orderBreakdown={orderBreakdown}
                    processName={stateData.processName}
                  />
                  {/*  */}
                  {/* <DiminishedActionButtonMaybe
                    showDispute={stateData.showDispute}
                    onOpenDisputeModal={onOpenDisputeModal}
                  /> */}
                </div>

                {savePaymentMethodFailed ? (
                  <p className={css.genericError}>
                    <FormattedMessage
                      id="TransactionPanel.savePaymentMethodFailed"
                      values={{
                        paymentMethodsPageLink: (
                          <NamedLink name="PaymentMethodsPage">
                            <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
                          </NamedLink>
                        ),
                      }}
                    />
                  </p>
                ) : null}
                <DeliveryInfoMaybe
                  className={css.deliveryInfoSection}
                  protectedData={protectedData}
                  metadata={metadata}
                  listing={listing}
                  locale={config.localization.locale}
                />
                <BookingLocationMaybe
                  className={css.deliveryInfoSection}
                  listing={listing}
                  showBookingLocation={showBookingLocation}
                />
              </div>
            ) : null}
            <div>
              <FeedSection
                rootClassName={css.feedContainer}
                hasMessages={messages.length > 0}
                hasTransitions={hasTransitions}
                fetchMessagesError={fetchMessagesError}
                initialMessageFailed={initialMessageFailed}
                activityFeed={activityFeed}
                isConversation={isInquiryProcess}
                metadata={metadata}
                onSendMessage={this.onMessageSubmit}
                config={config}
                // primaryButtonProps={stateData?.primaryButtonProps}
              />
              {showSendMessageForm ? (
                <div className={css.messageInputContainer}>
                  {/* <div>
                  <
                    onClick={() => {
                      this.setState({ showFileInput: true });
                    }}
                  >
                    Upload file
                  </             {/* <div>
                  <PrimaryButton
                    onClick={() => {
                      this.setState({ showFileInput: true });
                    }}
                  >
                    Upload file
                  </PrimaryButton>
                </div>
                {this.state.showFileInput && (
                  <div className={css.modalBackground}>
                    <div className={css.modal}>
                      <Input setUrl={setUrl} clearFileInput={this.state.clearFileInput} />
                      <Button onClick={this.onVideoMessageSubmit}>Send file</Button>
                    </div>
                  </div>
                )} */}
                  <div
                    className={css.attachButton}
                    onClick={() => {
                      this.setState({ showFileInput: true });
                    }}
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="heroicons-mini/paper-clip">
                        <path
                          id="Vector (Stroke)"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14.059 3.94086C13.0046 2.88645 11.295 2.88645 10.2406 3.94086L3.94062 10.2409C2.8862 11.2953 2.8862 13.0048 3.94062 14.0592C4.99476 15.1134 6.7037 15.1137 7.75818 14.06C7.75845 14.0598 7.75872 14.0595 7.75899 14.0592L8.20594 13.6093C8.46867 13.3449 8.89605 13.3434 9.16052 13.6062C9.425 13.8689 9.42641 14.2963 9.16368 14.5608L8.71516 15.0123L8.71359 15.0138C7.13196 16.5955 4.56765 16.5955 2.98602 15.0138C1.4044 13.4322 1.4044 10.8679 2.98602 9.28627L9.28602 2.98627C10.8676 1.40464 13.432 1.40464 15.0136 2.98627C16.594 4.56671 16.5952 7.12839 15.0171 8.7103L11.9079 11.9082C10.9853 12.8308 9.48938 12.8307 8.56677 11.9081C7.64416 10.9855 7.64416 9.48964 8.56677 8.56703L11.6725 5.46129C11.9361 5.19768 12.3635 5.19768 12.6271 5.46129C12.8907 5.72489 12.8907 6.15228 12.6271 6.41588L9.52136 9.52162C9.12596 9.91703 9.12596 10.5581 9.52136 10.9535C9.91578 11.3479 10.5547 11.3489 10.9503 10.9565L14.0589 7.75919C15.1134 6.70478 15.1134 4.99528 14.059 3.94086Z"
                          fill="#494656"
                        />
                      </g>
                    </svg>
                  </div>

                  <SendMessageForm
                    //
                    formId={this.sendMessageFormName}
                    rootClassName={css.sendMessageForm}
                    messagePlaceholder={intl.formatMessage(
                      { id: 'TransactionPanel.sendMessagePlaceholder' },
                      { name: otherUserDisplayNameString }
                    )}
                    inProgress={sendMessageInProgress}
                    sendMessageError={sendMessageError}
                    onFocus={this.onSendMessageFormFocus}
                    onBlur={this.onSendMessageFormBlur}
                    onSubmit={this.onMessageSubmit}
                    videoUrl={this.state.url}
                    showFileInput={this.state.showFileInput}
                    closeFileInput={this.onCloseFileInput}
                    clearFileInput={this.state.clearFileInput}
                    onVideoMessageSubmit={this.onVideoMessageSubmit}
                    onRevisionMessage={this.onRevisionMessage}
                    setUrl={setUrl}
                    //
                  />
                </div>
              ) : (
                <div className={css.sendingMessageNotAllowed}>
                  <FormattedMessage id="TransactionPanel.sendingMessageNotAllowed" />
                </div>
              )}
            </div>

            {stateData.showActionButtons ? (
              <>
                <div className={css.mobileActionButtonSpacer}></div>
                <div className={css.mobileActionButtons}>{actionButtons}</div>
              </>
            ) : null}
          </div>

          <div className={css.asideDesktop}>
            <div className={css.stickySection}>
              <div className={css.detailCard}>
                {/* <DetailCardImage
                  avatarWrapperClassName={css.avatarWrapperDesktop}
                  listingTitle={listingTitle}
                  image={firstImage}
                  provider={provider}
                  isCustomer={isCustomer}
                  listingImageConfig={config.layout.listingImage}
                /> */}
                <div className={css.providerInfoContainer}>
                  <div className={css.avatarContainer}>
                    <AvatarMedium user={provider} className={css.avatarDesktop} />
                  </div>
                  <div className={css.providerName}>{providerDisplayName}</div>
                </div>
                {/* <DetailCardHeadingsMaybe
                  showDetailCardHeadings={stateData.showDetailCardHeadings}
                  listingTitle={
                    listingDeleted ? (
                      listingTitle
                    ) : (
                      <NamedLink
                        name="ListingPage"
                        params={{ id: listing.id?.uuid, slug: createSlug(listingTitle) }}
                      >
                        {listingTitle}
                      </NamedLink>
                    )
                  }
                  showPrice={showPrice}
                  price={listing?.attributes?.price}
                  intl={intl}
                /> */}
                {stateData.showOrderPanel ? orderPanel : null}
                <BreakdownMaybe
                  className={css.breakdownContainer}
                  orderBreakdown={orderBreakdown}
                  processName={stateData.processName}
                />
                {/* show buttons only for creator */}
                {stateData.showActionButtons && isProvider ? (
                  <div className={css.desktopActionButtons}>{actionButtons}</div>
                ) : null}
                {/* Show close project button before creator approves request */}
                {isCustomer &&
                  (stateData.processState === 'purchased' ||
                    stateData.processState === 'free-inquiry') && (
                    <TertiaryButton
                      className={css.closeProjectButton}
                      onClick={stateData?.secondaryButtonProps?.onAction}
                    >
                      Close project
                    </TertiaryButton>
                  )}
                {isCustomer && stateData.processState === 'delivered' && (
                  <TertiaryButton
                    className={css.closeProjectButton}
                    onClick={() => this.setState({ isSubmitModalOpen: true })}
                  >
                    Complete the campaign
                  </TertiaryButton>
                )}
              </div>
              <DiminishedActionButtonMaybe
                showDispute={stateData.showDispute}
                onOpenDisputeModal={onOpenDisputeModal}
              />
            </div>
          </div>
        </div>
        <ModalV2Standard
          isOpen={this.state.isSubmitModalOpen}
          onClose={() => this.setState({ isSubmitModalOpen: false })}
          width={600}
          icon={<img src={lightningIcon} />}
        >
          <div className={css.modal}>
            <div className={css.modalContent}>
              <h3>Are you sure you want to complete the campaign?</h3>
              <div className={css.buttonsContainer}>
                <PrimaryButton onClick={stateData?.primaryButtonProps?.onAction}>
                  Complete the campaign
                </PrimaryButton>
                <SecondaryButton onClick={() => this.setState({ isSubmitModalOpen: false })}>
                  Cancel
                </SecondaryButton>
              </div>
            </div>
          </div>
        </ModalV2Standard>
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  listing: null,
  customer: null,
  provider: null,
  hasTransitions: false,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  stateData: {},
  activityFeed: null,
  showBookingLocation: false,
  orderBreakdown: null,
  orderPanel: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transactionRole: oneOf(['customer', 'provider']).isRequired,
  listing: propTypes.listing,
  customer: propTypes.user,
  provider: propTypes.user,
  hasTransitions: bool,
  transactionId: propTypes.uuid.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  onOpenDisputeModal: func.isRequired,
  onSendMessage: func.isRequired,
  stateData: stateDataShape,
  showBookingLocation: bool,
  activityFeed: node,
  orderBreakdown: node,
  orderPanel: node,
  config: object.isRequired,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
