import globe from '../../../assets/become-pro/globe.svg';
import camera from '../../../assets/become-pro/camera.svg';
import wallet from '../../../assets/become-pro/wallet.svg';
import css from './HowItWorks.module.css';
import classNames from 'classnames';

const HowItWorks = () => {
  return (
    <div className={css.white_bg}>
      <div className={css.root_howitworks}>
        <div className={css.rootContent}>
          <div className={css.content_container}>
            <div className={css.title}>How it Works</div>
            <div className={css.subtitle}>
              We're here to help you easily connect with brands you love.
            </div>
          </div>
          <div className={css.card_list_container}>
            <div className={css.card}>
              <div>
                <img src={globe} alt="globe icon" />
              </div>
              <div className={css.textContent}>
                <div className={css.cardTitle}>Discover</div>
                <div className={css.cardSubtitle}>
                  Explore collaboration opportunities on our campaign marketplace and apply to ones
                  that interest you.
                </div>
              </div>
            </div>
            <div className={css.card}>
              <div>
                <img src={camera} alt="camera icon" />
              </div>
              <div className={css.textContent}>
                <div className={css.cardTitle}>Create</div>
                <div className={css.cardSubtitle}>
                  Depending on the deliverables required, share your experience or review with your
                  audience online.
                </div>
              </div>
            </div>
            <div className={css.card}>
              <div>
                <img src={wallet} alt="wallet icon" />
              </div>
              <div className={css.textContent}>
                <div className={classNames(css.cardTitle, css.orangeTiltle)}>Earn</div>
                <div className={css.cardSubtitle}>
                  Submit your post link through  our platform and get reimbursed  or rewarded.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
