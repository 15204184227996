import React from 'react';
import { compose } from 'redux';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperTopbar,
} from '../../components';
import Toolbar from '../../components/Toolbar/Toolbar';
import StaticPage from '../StaticPage/StaticPage';
import CallToAction from './CallToAction';

import HeroBecomeProPage from './HeroBecomeProPage/Hero';
import HowItWorks from './HowItWorks/HowItWorks';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import css from './BecomeProPage.module.css';
import classNames from 'classnames';
import facebookImage from '../../assets/creator-meta.webp';
import twitterImage from '../../assets/creator-meta.webp';
import config from '../../config';
import CreatorCard from './CreatorCard/CreatorCard';
import OurOfferings from './OurOfferings';
import ActiveOffers from './ActiveOffers';

import { Element } from 'react-scroll';

const BecomeProPage = ({ isAuthenticated }) => {
  const description =
    'Join as a creator and start collaborating with brands for gifted and paid opportunities';
  const title = 'For Creators | Collabreate';
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <StaticPage
      description={description}
      title={title}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Creators',
        description: { description },
        name: { title },
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <Toolbar hideOnAuthenticated={false} isDark={true} />
        </LayoutWrapperTopbar>
        <div className={classNames(css.container, isAuthenticated && css.containerAuthentication)}>
          <div>
            <HeroBecomeProPage />
            <CreatorCard />
            <HowItWorks />
            <Element name="ourOfferings">
              <OurOfferings />
            </Element>
            <ActiveOffers />
            <CallToAction />
          </div>
        </div>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;

  return {
    isAuthenticated,
  };
};

export default compose(withRouter, connect(mapStateToProps))(BecomeProPage);
