import React from 'react';

import css from './Hero.module.css';
import heroImage from '../../../assets/become-pro/hero_mobile.webp';
import arrow_right from '../../../assets/become-pro/arrow-right.svg';
import { useHistory } from 'react-router-dom';
import flagGroup from '../../../assets/become-pro/flagGroup.png';

import { Button } from 'react-scroll';

const HeroBecomeProPage = () => {
  const history = useHistory();

  return (
    <div className={css.base}>
      <div className={css.root}>
        <div className={css.content_container}>
          <div className={css.top_section}>
            <div className={css.text_container}>
              <div className={css.header_title_container}>
                <div className={css.title}>The Platform for Content Creators</div>
              </div>
              <div className={css.subtitle}>Get access to exclusive benefits & opportunities.</div>
              <div className={css.textContent}>
                Whether you're a seasoned marketer, budding influencer or UGC creator - we're here
                to connect you with opportunities that reward your creativity and people that
                understand what content is all about.
              </div>
            </div>
            <div className={css.btn_container}>
              <button className={css.btn_join} onClick={() => history.push('/login')}>
                Join
                <img src={arrow_right} alt="right arrow" />
              </button>
              <Button
                className={css.btn_learnmore}
                to="ourOfferings"
                spy={true}
                smooth={true}
                offset={-70}
              >
                Learn more
              </Button>
            </div>
          </div>
          <div className={css.bottom_section}>
            <div className={css.bottom_text}>Join the Community and Become a Collabreator</div>
            <div className={css.bottom_countries}>
              <img src={flagGroup} />
            </div>
          </div>
        </div>
        <div className={css.image_container}>
          <img src={heroImage} alt="Join communitu" className={css.heroImage} />
        </div>
      </div>
    </div>
  );
};

export default HeroBecomeProPage;
