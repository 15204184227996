import React from 'react';
import css from './Breadcrumb.module.css';
import PropTypes from 'prop-types';
import arrowRight from '../../assets/icons/arrow-right.svg';

const Breadcrumb = ({ items }) => {
  return (
    <div className={css.breadcrumb}>
      {items.map((item, index) => {
        return (
          <div className={css.flex} key={`Breadcrumb-${item.label}-${index}`}>
            <p
              className={`
                    ${css.breadcrumbItem}
                    ${index === items.length - 1 ? css.active : ''}
                `}
              onClick={item.onClick}
            >
              {item.label}
            </p>
            {index < items.length - 1 && <img src={arrowRight} />}
          </div>
        );
      })}
    </div>
  );
};

const { arrayOf, objectOf, shape, string, func } = PropTypes;

Breadcrumb.defaultProps = {
  items: null,
};

Breadcrumb.propTypes = {
  items: arrayOf(
    objectOf(
      shape({
        label: string,
        onClick: func,
      })
    )
  ),
};

export default Breadcrumb;
