import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import routeConfiguration from '../../routing/routeConfiguration';

export const creatorPrivateRoutes = [
  'CreatorListingsPage',
  'CreatorReviewsPage',
  'CreatorAnalyticsPage',
  'CreatorResumePage',
  'ProProfilePage',
];

export const isCreatorPrivateRoute = routeName => {
  return creatorPrivateRoutes.includes(routeName);
};

export const isPlatformConnected = (creator, platform) => {
  return creator?.attributes?.profile?.publicData?.[`${platform}_auth_status`] === 'connected';
};

export default function useCreatorPrivateView({ creator, currentUser }) {
  const location = useLocation();
  const creatorId = creator?.id?.uuid;
  const userId = currentUser?.id?.uuid;

  const currentRouteName = useMemo(() => {
    const routes = routeConfiguration();
    const route = routes.find(r => location.pathname === r.path.replace(':id', creatorId));
    return route ? route.name : null;
  }, [location.pathname, creatorId]);

  return {
    isPrivateView: isCreatorPrivateRoute(currentRouteName) && creatorId === userId,
    connectedTikTok: isPlatformConnected(creator, 'tiktok'),
    connectedInstagram: isPlatformConnected(creator, 'instagram'),
    isPlatformConnected: platform => isPlatformConnected(creator, platform),
  };
}
