import React from 'react';
import PropTypes from 'prop-types';
import IconSpinner from '../IconSpinner/IconSpinner';

import css from './Loading.module.css';

const Loading = ({ width, height }) => {
  return (
    <div className={css.loading} style={{ width: width, height: height }}>
      <IconSpinner />
    </div>
  );
};

Loading.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Loading.defaultProps = {
  width: '100%',
  height: '100%',
};

export default Loading;
