import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaFacebook.module.css';

const IconSocialMediaFacebook = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path d="M12 2.85498C10.0716 2.85498 8.18657 3.42681 6.58319 4.49815C4.97982 5.5695 3.73013 7.09224 2.99218 8.87382C2.25422 10.6554 2.06114 12.6158 2.43735 14.5071C2.81355 16.3984 3.74215 18.1357 5.10571 19.4993C6.46928 20.8628 8.20656 21.7914 10.0979 22.1676C11.9892 22.5438 13.9496 22.3508 15.7312 21.6128C17.5127 20.8749 19.0355 19.6252 20.1068 18.0218C21.1782 16.4184 21.75 14.5333 21.75 12.605C21.7473 10.02 20.7192 7.54159 18.8913 5.7137C17.0634 3.88582 14.585 2.85771 12 2.85498ZM12.75 20.8203V14.855H15C15.1989 14.855 15.3897 14.776 15.5303 14.6353C15.671 14.4947 15.75 14.3039 15.75 14.105C15.75 13.9061 15.671 13.7153 15.5303 13.5746C15.3897 13.434 15.1989 13.355 15 13.355H12.75V11.105C12.75 10.7072 12.908 10.3256 13.1893 10.0443C13.4706 9.76302 13.8522 9.60498 14.25 9.60498H15.75C15.9489 9.60498 16.1397 9.52596 16.2803 9.38531C16.421 9.24466 16.5 9.05389 16.5 8.85498C16.5 8.65607 16.421 8.4653 16.2803 8.32465C16.1397 8.184 15.9489 8.10498 15.75 8.10498H14.25C13.4544 8.10498 12.6913 8.42105 12.1287 8.98366C11.5661 9.54627 11.25 10.3093 11.25 11.105V13.355H9C8.80109 13.355 8.61033 13.434 8.46967 13.5746C8.32902 13.7153 8.25 13.9061 8.25 14.105C8.25 14.3039 8.32902 14.4947 8.46967 14.6353C8.61033 14.776 8.80109 14.855 9 14.855H11.25V20.8203C9.13575 20.6273 7.17728 19.6266 5.78198 18.0265C4.38667 16.4264 3.66195 14.3499 3.75855 12.2291C3.85515 10.1082 4.76564 8.10625 6.30064 6.63958C7.83563 5.17291 9.87696 4.35445 12 4.35445C14.1231 4.35445 16.1644 5.17291 17.6994 6.63958C19.2344 8.10625 20.1449 10.1082 20.2415 12.2291C20.3381 14.3499 19.6133 16.4264 18.218 18.0265C16.8227 19.6266 14.8643 20.6273 12.75 20.8203Z" fill="white"/>
    </svg>
  );
};

IconSocialMediaFacebook.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaFacebook.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaFacebook;
