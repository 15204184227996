// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';

import ModalV2Base from './ModalV2Base';
import closeIcon from '../../assets/icons/close.svg';

import css from './ModalV2Standard.module.css';

import Button, { WhiteButton } from '../Button/Button';

const ModalV2Standard = ({
  isOpen,
  onClose,
  children,
  width,
  icon,
  title,
  description,
  okButtonProps,
  cancelButtonProps,
}) => {
  const renderContent = () => (
    <>
      <div className={css.modalHeader}>
        {icon}

        <button className={css.closeButton} onClick={onClose}>
          <img src={closeIcon} alt="close" />
        </button>
      </div>
      <div className={css.modalContent}>
        <div className={css.title}>{title}</div>
        <div className={css.description}>{description}</div>
        <div className={css.children}>{children}</div>
      </div>
      <div className={(cancelButtonProps || okButtonProps) && css.modalActions}>
        {cancelButtonProps && (
          <WhiteButton className={css.cancelButton} onClick={cancelButtonProps.onClick}>
            {cancelButtonProps.label}
          </WhiteButton>
        )}
        {okButtonProps && (
          <Button className={css.okButton} onClick={okButtonProps.onClick}>
            {okButtonProps.label}
          </Button>
        )}
      </div>
    </>
  );

  return <ModalV2Base isOpen={isOpen} width={width} renderContent={renderContent} />;
};

ModalV2Standard.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  width: PropTypes.number,
  icon: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  okButtonProps: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }).isRequired,
  cancelButtonProps: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
};

export default ModalV2Standard;
