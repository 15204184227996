import _ from 'lodash';
import React from 'react';

export const SERVICES = [
  {
    id: '1',
    title: 'Single Concept Video',
    value: 'Single Concept Video',
    alias: 'default-purchase/release-1',
  },
  {
    id: '2',
    title: 'Voiceover Video',
    value: 'Voiceover Video',
    alias: 'default-purchase/release-1',
  },
  {
    id: '3',
    title: 'Talking Head Video',
    value: 'Talking Head Video',
    alias: 'default-purchase/release-1',
  },
  {
    id: '4',
    title: 'Unboxing Video (Faceless)',
    value: 'Unboxing Video (Faceless)',
    alias: 'default-purchase/release-1',
  },
  {
    id: '5',
    title: 'Trending Audio Video',
    value: 'Trending Audio Video',
    alias: 'default-purchase/release-1',
  },
  {
    id: '6',
    title: 'Direct Response Video',
    value: 'Direct Response Video',
    alias: 'default-purchase/release-1',
  },
  {
    id: '7',
    title: 'A/B Testing Package',
    value: 'A/B Testing Package',
    alias: 'default-purchase/release-1',
  },
  {
    id: '8',
    title: 'Open to gifted collaborations',
    value: 'Open to gifted collaborations',
    alias: 'default-purchase/release-1',
  },
  { id: '11', title: 'Other', value: 'Other', alias: 'default-purchase/release-1' },
];

const ProThirdStepFile = ({ setSelectedWhatSell, _handleNext }) => {
  function handleSelectedButton(val) {
    setSelectedWhatSell(val);
    _handleNext();
  }

  return (
    <div className="proThird_main">
      <div className="listingTypes_options">
        {/* <p>
          Select your first product now, and we'll guide you to create your first listing. You can
          always add more products later!
        </p> */}
        <div className="toggleContainer">
          {_.map(SERVICES, r => (
            <button
              type="button"
              className="togglebutton"
              key={r?.id}
              onClick={() => handleSelectedButton(r?.value)}
            >
              {r?.title}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProThirdStepFile;
