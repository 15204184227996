import React from 'react';
import css from './index.module.css';
import ActiveOfferCard from './components/Card';

import sk2 from '../../../assets/brands/sk2.svg';
import sk2Card from '../../../assets/brands/sk2-card.webp';

import hipvan from '../../../assets/brands/hipvan.svg';
import hipvanCard from '../../../assets/brands/hipvan-card.webp';

import chimichanga from '../../../assets/brands/chimichanga.svg';
import chimichangaCard from '../../../assets/brands/chimichanga-card.webp';

import bakalaki from '../../../assets/brands/bakalaki.svg';
import bakalakiCard from '../../../assets/brands/bakalaki-card.webp';

const ActiveOffers = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.container}>
        <div className={css.title}>Active Offers</div>
        <div className={css.description}>
          We bridge the gap between extraordinary talent and exceptional brands. Here's how you can
          dive into collaborations that make a difference:
        </div>

        <div className={css.content}>
          <ActiveOfferCard
            image={sk2Card}
            location="Singapore"
            title="Lifestyle x SK-II"
            description="Enhance your look with free SK-II cosmetics shipped directly to you in Singapore! We are looking for amazing creators who will join us in celebrating everyday lifestyle. Apply today!"
            brandName="SK-II"
            brandIcon={sk2}
          />
          <ActiveOfferCard
            image={hipvanCard}
            location="Singapore"
            title="Creator Challenge"
            description="HipVan is here to make designer furniture and home furnishings more affordable to everyone, and they are inviting you to participate in a special challenge to decorate your place to show off your unique style! Get over 50% off"
            brandName="HipVan"
            brandIcon={hipvan}
          />
          <ActiveOfferCard
            image={chimichangaCard}
            location="Singapore"
            title="Explore Singapore"
            description="Calling all food lovers and content creators! Chimichanga is on a mission to bring the best Mexican flavors to everyone, and we want you to be a part of it! Join our challenge and showcase your unique style by visiting our locations and enjoying complimentary meals!"
            brandName="Chimichanga"
            brandIcon={chimichanga}
          />
          <ActiveOfferCard
            image={bakalakiCard}
            location="Singapore"
            title="Greek World"
            description="Join our special challenge and experience the essence of Greek cuisine. Showcase your unique style by visiting our restaurant and enjoying complimentary meals as you embark on a delightful culinary adventure!"
            brandName="Bakalaki"
            brandIcon={bakalaki}
          />
        </div>
      </div>
    </div>
  );
};

export default ActiveOffers;
