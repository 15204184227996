import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SocialLoginButton } from '../../components';
import { FacebookLogo, GoogleLogo } from '../../containers/AuthenticationPage/socialLoginLogos';

const GooglePinkSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M15.75 9.00015C15.7501 10.5895 15.1893 12.1279 14.1665 13.3444C13.1437 14.561 11.7244 15.3775 10.1586 15.6503C8.59281 15.9231 6.98098 15.6347 5.60701 14.8357C4.23303 14.0368 3.18504 12.7787 2.64761 11.283C2.11018 9.78721 2.11779 8.14979 2.66909 6.65911C3.2204 5.16842 4.28004 3.92009 5.66139 3.13397C7.04273 2.34786 8.65716 2.07439 10.2203 2.36173C11.7835 2.64907 13.1952 3.4788 14.2066 4.70476C14.2569 4.76126 14.2952 4.8273 14.3193 4.89894C14.3434 4.97058 14.3528 5.04635 14.347 5.12172C14.3412 5.19708 14.3203 5.2705 14.2854 5.33759C14.2506 5.40468 14.2026 5.46407 14.1443 5.51219C14.086 5.56032 14.0186 5.5962 13.9462 5.6177C13.8737 5.6392 13.7976 5.64587 13.7225 5.63731C13.6474 5.62876 13.5748 5.60515 13.509 5.56791C13.4433 5.53066 13.3857 5.48054 13.3397 5.42054C12.5124 4.41741 11.3631 3.73187 10.0873 3.48056C8.81155 3.22925 7.48812 3.42768 6.34216 4.0421C5.1962 4.65652 4.2985 5.64898 3.80177 6.85064C3.30504 8.0523 3.23995 9.38894 3.6176 10.6332C3.99524 11.8774 4.79228 12.9524 5.87312 13.6752C6.95397 14.3981 8.25186 14.7241 9.54601 14.5979C10.8402 14.4718 12.0506 13.9011 12.9715 12.9831C13.8923 12.0651 14.4667 10.8564 14.5969 9.56265H9C8.85082 9.56265 8.70774 9.50339 8.60225 9.3979C8.49676 9.29241 8.4375 9.14934 8.4375 9.00015C8.4375 8.85097 8.49676 8.70789 8.60225 8.6024C8.70774 8.49691 8.85082 8.43765 9 8.43765H15.1875C15.3367 8.43765 15.4798 8.49691 15.5852 8.6024C15.6907 8.70789 15.75 8.85097 15.75 9.00015Z"
      fill="#DD58D6"
    />
  </svg>
);

const SocialMediaSignup = ({ authWithGoogle, authWithFacebook, tab }) => {
  // Social login buttons
  const isLogin = tab === 'login';
  const showFacebookLogin = !!process.env.REACT_APP_FACEBOOK_APP_ID;

  // TODO: uncomment after adding google login
  // const showGoogleLogin = !!process.env.REACT_APP_GOOGLE_CLIENT_ID;
  // const showSocialLogins = showFacebookLogin || showGoogleLogin;
  const showGoogleLogin = false;
  const showSocialLogins = false;

  const googleButtonText = isLogin ? (
    <FormattedMessage id="AuthenticationPage.loginWithGoogle" />
  ) : (
    <FormattedMessage id="AuthenticationPage.signupWithGoogle" />
  );
  const facebookButtonText = isLogin ? (
    <FormattedMessage id="AuthenticationPage.loginWithFacebook" />
  ) : (
    <FormattedMessage id="AuthenticationPage.signupWithFacebook" />
  );

  const socialLoginButtonsMaybe = showSocialLogins ? (
    <div className="facbookSection_class">
      {showFacebookLogin ? (
        <div className="">
          <SocialLoginButton onClick={() => authWithFacebook()}>
            <span className="">{FacebookLogo}</span>
            {facebookButtonText}
          </SocialLoginButton>
        </div>
      ) : null}

      {showGoogleLogin ? (
        <div className="googleSection_class">
          <SocialLoginButton onClick={() => authWithGoogle()}>
            <span className="">{GoogleLogo}</span>
            {googleButtonText}
          </SocialLoginButton>
        </div>
      ) : null}
      <div className="main_orBorder">
        <div className="orDisplaySection">
          <span className="orborder_class">
            <FormattedMessage id="AuthenticationPage.or" />
          </span>
        </div>
      </div>
    </div>
  ) : null;

  return socialLoginButtonsMaybe;
};

export default SocialMediaSignup;
