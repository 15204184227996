import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ExternalLink, NamedLink } from '..';

import css from './CreatorsTermsOfService.module.css';
import { FormattedMessage } from 'react-intl';

const CreatorsTermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>      <h2>Terms of Service for Creators</h2>
    <p>Last modified: March 1, 2024</p>
      <p>This is an agreement between you and Collabreate Co. (“we,” “us,” “Collabreate” or “our”). By clicking on the button accepting these Terms of Service set forth below (as amended from time to time, the “Terms of Service”), by visiting our website, www.collabreate.co (the “Website”), downloading and installing our mobile application(s) (the “App”), or by using the Platform (as defined below), you acknowledge that you are a user of the Platform for commercial purposes (“Creator”), and in either instance you have the authority to enter this agreement and that you have read it, understand it and agree to be bound by its terms and conditions.</p>
      <p>These Terms of Service apply to you if you are a Creator that uses the Platform. If you are a Sponsor (as defined below), these Terms of Service do not apply to you and you should review our Terms of Service for Clients at our website.</p>
      <h2>Platform</h2>
      <h2>Services</h2>
      <p>Collabreate provides a proprietary platform (“Platform”) and campaign management services (“Services”) to help social media celebrities, influencers, and creators (i.e., Creators) connect and collaborate with brands, advertisers, and marketing agencies (“Sponsor” or “Client”) to create content for sponsored marketing campaigns (each, a “Campaign”).</p>
      <h2>Collaboration on Campaigns</h2>
      <p>Creator may use the Platform and Services to submit details regarding the fees that Creator may charge to create content (“Creator Fees”), the services Creator provides with respect to collaborations with Sponsors and details regarding Creator’s social media accounts, location, and demographics, in addition to other details required by Collabreate.</p>
      <p>If a Sponsor is interested in collaborating with Creator on a Campaign, Collabreate may ask Creator to participate in the Campaign via the Platform or email (at the email address provided when creating an account) and may deliver a campaign brief outlining Sponsor’s requirements for the Campaign (“Campaign Brief”).</p>
      <h2>Work Product</h2>
      <p>Collabreate will coordinate the development of the content produced by Creator for a Campaign (“Work Product”).</p>
      <p>Sponsor will have an opportunity to review and approve all Work Product, with up to 3 rounds of revisions included in the Creator Fees and any further rounds of revisions subject to additional fees.</p>
      <p>Creator will only post Work Product approved by Sponsor (“Campaign Content”) on social media.</p>
      <h2>Access</h2>
      <p>Subject to Creator’s compliance with the terms and conditions of these Terms of Service (including signing up for an account and complying with the code of conduct set out below), Collabreate will make the Platform available to Creator on the terms and conditions set out in these Terms of Service.</p>
      <h2>Technical Support</h2>
      <p>Collabreate will provide Creator with technical support for the Platform: (i) via email at team@collabreate.co, and (ii) via Collabreate’s knowledge base and documentation available online; and (iii) via native Platform chat messenger. Collabreate will use its commercially reasonable efforts to respond to all technical support requests from Creator within 24 hours from the time the request is received.</p>
      <h2>Account and Subscription Term</h2>
      <h2>Eligibility</h2>
      <p>If Creator is an individual, Creator must be over the age of majority in Creator’s jurisdiction of residence who can form legally binding contracts to register for an account on the Platform (“Account”) and to be accepted by Collabreate as a member (“Member”) of the Platform. Creator is solely responsible for ensuring that the use of the Platform and Services in accordance with these Terms of Service in Creator’s jurisdiction of residence is permitted by law or regulation. If such use is not so permitted by applicable law, Collabreate prohibits all use and access to the Platform and Services.</p>
      <h2>Account Information</h2>
      <p>Upon registering for an Account, Creator may decide to or be required to integrate Creator’s Account with a third-party social media account. In connection with such integration, Creator authorizes Collabreate to obtain certain information about Creator from Creator’s social media account, including certain personal information, such as Creator’s name, profile information, profile picture, social media account metrics, number of followers, and any information that Creator may make publicly available on or through the social media account (“Social Media Information”). Collabreate may include some or all of this Social Media Information in Creator’s Account, and this information may be visible to other Members or visitors to the Platform.</p>
      <h2>Privacy</h2>
      <p>Creator understands that information about an identifiable individual or information that is subject to applicable privacy or data protection laws (“Personal Information”) will be treated in accordance with Collabreate’s privacy policy located on our website.</p>
      <h2>Governing Law</h2>
      <p>These Terms of Service and any disputes arising out of or related to the services provided by Collabreate shall be governed by and construed in accordance with the laws of Singapore, without giving effect to its conflict of law provisions.</p>
      <p>For any questions or concerns regarding these Terms of Service, please contact us at team@collabreate.co.</p>
    </div>
  );
};

CreatorsTermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

CreatorsTermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default CreatorsTermsOfService;
