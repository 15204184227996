import React, { useEffect, useState } from 'react';
import { array, arrayOf, bool, func, number, object, oneOf, shape, string } from 'prop-types';
import classNames from 'classnames';

// Import configs and util modules
import { useConfiguration } from '../../../context/configurationContext';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../../util/reactIntl';
import {
  displayDeliveryPickup,
  displayDeliveryShipping,
  displayLocation,
  displayPrice,
  requirePayoutDetails,
} from '../../../util/configHelpers';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../../util/urlHelpers';
import { createResourceLocatorString } from '../../../util/routes';
import { withViewport } from '../../../util/uiHelpers';
import {
  SCHEMA_TYPE_ENUM,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_TEXT,
  SCHEMA_TYPE_LONG,
  SCHEMA_TYPE_BOOLEAN,
  propTypes,
} from '../../../util/types';
import { ensureCurrentUser, ensureListing } from '../../../util/data';
import {
  INQUIRY_PROCESS_NAME,
  isBookingProcess,
  isPurchaseProcess,
} from '../../../transactions/transaction';

// Import shared components
import {
  Heading,
  Modal,
  NamedRedirect,
  Tabs,
  StripeConnectAccountStatusBox,
} from '../../../components';
import { StripeConnectAccountForm } from '../../../forms';

// Import modules from this directory
import EditListingWizardTab, {
  DETAILS,
  PRICING,
  PRICING_AND_STOCK,
  DELIVERY,
  LOCATION,
  AVAILABILITY,
  PHOTOS,
} from './EditListingWizardTab';
import css from './EditListingWizard.module.css';

// You can reorder these panels.
// Note 1: You need to change save button translations for new listing flow
// Note 2: Ensure that draft listing is created after the first panel
//         and listing publishing happens after last panel.
// Note 3: The first tab creates a draft listing and title is mandatory attribute for it.
//         Details tab asks for "title" and is therefore the first tab in the wizard flow.
const TABS_DETAILS_ONLY = [DETAILS];
const TABS_PRODUCT = [DETAILS, PRICING_AND_STOCK, DELIVERY, PHOTOS];
const TABS_BOOKING = [DETAILS, LOCATION, PRICING, AVAILABILITY, PHOTOS];
const TABS_INQUIRY = [DETAILS, PRICING, PHOTOS];
const TABS_ALL = [...TABS_PRODUCT, ...TABS_BOOKING, ...TABS_INQUIRY];

// Tabs are horizontal in small screens
const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

const STRIPE_ONBOARDING_RETURN_URL_SUCCESS = 'success';
const STRIPE_ONBOARDING_RETURN_URL_FAILURE = 'failure';

// Pick only allowed tabs from the given list
const getTabs = (processTabs, disallowedTabs) => {
  return disallowedTabs.length > 0
    ? processTabs.filter(tab => !disallowedTabs.includes(tab))
    : processTabs;
};
// Pick only allowed booking tabs (location could be omitted)
const tabsForBookingProcess = (processTabs, listingTypeConfig) => {
  const disallowedTabs = !displayLocation(listingTypeConfig) ? [LOCATION] : [];
  return getTabs(processTabs, disallowedTabs);
};
// Pick only allowed purchase tabs (delivery could be omitted)
const tabsForPurchaseProcess = (processTabs, listingTypeConfig) => {
  const isDeliveryDisabled =
    !displayDeliveryPickup(listingTypeConfig) && !displayDeliveryShipping(listingTypeConfig);
  const disallowedTabs = isDeliveryDisabled ? [DELIVERY] : [];
  return getTabs(processTabs, disallowedTabs);
};
// Pick only allowed inquiry tabs (location and pricing could be omitted)
const tabsForInquiryProcess = (processTabs, listingTypeConfig) => {
  const locationMaybe = !displayLocation(listingTypeConfig) ? [LOCATION] : [];
  const priceMaybe = !displayPrice(listingTypeConfig) ? [PRICING] : [];
  return getTabs(processTabs, [...locationMaybe, ...priceMaybe]);
};

/**
 * Return translations for wizard tab: label and submit button.
 *
 * @param {Object} intl
 * @param {string} tab name of the tab/panel in the wizard
 * @param {boolean} isNewListingFlow
 * @param {string} processName
 */
const tabLabelAndSubmit = (intl, tab, isNewListingFlow, isPriceDisabled, processName) => {
  const processNameString = isNewListingFlow ? `${processName}.` : '';
  const newOrEdit = isNewListingFlow ? 'new' : 'edit';

  let labelKey = null;
  let submitButtonKey = null;
  if (tab === DETAILS) {
    labelKey = 'EditListingWizard.tabLabelDetails';
    submitButtonKey = `EditListingWizard.${processNameString}${newOrEdit}.saveDetails`;
  } else if (tab === PRICING) {
    labelKey = 'EditListingWizard.tabLabelPricing';
    submitButtonKey = `EditListingWizard.${processNameString}${newOrEdit}.savePricing`;
  } else if (tab === PRICING_AND_STOCK) {
    labelKey = 'EditListingWizard.tabLabelPricingAndStock';
    submitButtonKey = `EditListingWizard.${processNameString}${newOrEdit}.savePricingAndStock`;
  } else if (tab === DELIVERY) {
    labelKey = 'EditListingWizard.tabLabelDelivery';
    submitButtonKey = `EditListingWizard.${processNameString}${newOrEdit}.saveDelivery`;
  } else if (tab === LOCATION) {
    labelKey = 'EditListingWizard.tabLabelLocation';
    submitButtonKey =
      isPriceDisabled && isNewListingFlow
        ? `EditListingWizard.${processNameString}${newOrEdit}.saveLocationNoPricingTab`
        : `EditListingWizard.${processNameString}${newOrEdit}.saveLocation`;
  } else if (tab === AVAILABILITY) {
    labelKey = 'EditListingWizard.tabLabelAvailability';
    submitButtonKey = `EditListingWizard.${processNameString}${newOrEdit}.saveAvailability`;
  } else if (tab === PHOTOS) {
    labelKey = 'EditListingWizard.tabLabelPhotos';
    submitButtonKey = `EditListingWizard.${processNameString}${newOrEdit}.savePhotos`;
  }

  return {
    label: intl.formatMessage({ id: labelKey }),
    submitButton: intl.formatMessage({ id: submitButtonKey }),
  };
};

/**
 * Validate listing fields (in extended data) that are included through configListing.js
 * This is used to check if listing creation flow can show the "next" tab as active.
 *
 * @param {Object} publicData
 * @param {Object} privateData
 */
const hasValidListingFieldsInExtendedData = (publicData, privateData, config) => {
  const isValidField = (fieldConfig, fieldData) => {
    const {
      key,
      includeForListingTypes,
      schemaType,
      enumOptions = [],
      saveConfig = {},
    } = fieldConfig;

    const schemaOptionKeys = enumOptions.map(o => `${o.option}`);
    const hasValidEnumValue = optionData => {
      return schemaOptionKeys.includes(optionData);
    };
    const hasValidMultiEnumValues = savedOptions => {
      return savedOptions.every(optionData => schemaOptionKeys.includes(optionData));
    };

    const isRequired =
      !!saveConfig.isRequired &&
      (includeForListingTypes == null || includeForListingTypes.includes(publicData?.listingType));
    if (isRequired) {
      const savedListingField = fieldData[key];
      return schemaType === SCHEMA_TYPE_ENUM
        ? typeof savedListingField === 'string' && hasValidEnumValue(savedListingField)
        : schemaType === SCHEMA_TYPE_MULTI_ENUM
        ? Array.isArray(savedListingField) && hasValidMultiEnumValues(savedListingField)
        : schemaType === SCHEMA_TYPE_TEXT
        ? typeof savedListingField === 'string'
        : schemaType === SCHEMA_TYPE_LONG
        ? typeof savedListingField === 'number' && Number.isInteger(savedListingField)
        : schemaType === SCHEMA_TYPE_BOOLEAN
        ? savedListingField === true || savedListingField === false
        : false;
    }
    return true;
  };
  return config.listing.listingFields.reduce((isValid, fieldConfig) => {
    const data = fieldConfig.scope === 'private' ? privateData : publicData;
    return isValid && isValidField(fieldConfig, data);
  }, true);
};

/**
 * Check if a wizard tab is completed.
 *
 * @param tab wizard's tab
 * @param listing is contains some specific data if tab is completed
 *
 * @return true if tab / step is completed.
 */
const tabCompleted = (tab, listing, config) => {
  const {
    availabilityPlan,
    description,
    geolocation,
    price,
    title,
    publicData,
    privateData,
  } = listing.attributes;
  const images = listing.images;
  const { listingType, transactionProcessAlias, unitType, shippingEnabled, pickupEnabled } =
    publicData || {};
  const deliveryOptionPicked = publicData && (shippingEnabled || pickupEnabled);

  switch (tab) {
    case DETAILS:
      return !!(
        description &&
        title &&
        listingType &&
        transactionProcessAlias &&
        unitType &&
        hasValidListingFieldsInExtendedData(publicData, privateData, config)
      );
    case PRICING:
      return !!price;
    case PRICING_AND_STOCK:
      return !!price;
    case DELIVERY:
      return !!deliveryOptionPicked;
    case LOCATION:
      return !!(geolocation && publicData?.location?.address);
    case AVAILABILITY:
      return !!availabilityPlan;
    case PHOTOS:
      return images && images.length > 0;
    default:
      return false;
  }
};

/**
 * Check which wizard tabs are active and which are not yet available. Tab is active if previous
 * tab is completed. In edit mode all tabs are active.
 *
 * @param isNew flag if a new listing is being created or an old one being edited
 * @param listing data to be checked
 * @param tabs array of tabs used for this listing. These depend on transaction process.
 *
 * @return object containing activity / editability of different tabs of this wizard
 */
const tabsActive = (isNew, listing, tabs, config) => {
  return tabs.reduce((acc, tab) => {
    const previousTabIndex = tabs.findIndex(t => t === tab) - 1;
    const validTab = previousTabIndex >= 0;
    const hasListingType = !!listing?.attributes?.publicData?.listingType;
    const prevTabComletedInNewFlow = tabCompleted(tabs[previousTabIndex], listing, config);
    const isActive =
      validTab && !isNew ? hasListingType : validTab && isNew ? prevTabComletedInNewFlow : true;
    return { ...acc, [tab]: isActive };
  }, {});
};

const scrollToTab = (tabPrefix, tabId) => {
  const el = document.querySelector(`#${tabPrefix}_${tabId}`);
  if (el) {
    el.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  }
};

// Create return URL for the Stripe onboarding form
const createReturnURL = (returnURLType, rootURL, routes, pathParams) => {
  const path = createResourceLocatorString(
    'EditListingStripeOnboardingPage',
    routes,
    { ...pathParams, returnURLType },
    {}
  );
  const root = rootURL.replace(/\/$/, '');
  return `${root}${path}`;
};

// Get attribute: stripeAccountData
export const getStripeAccountData = stripeAccount =>
  stripeAccount.attributes.stripeAccountData || null;

// Get last 4 digits of bank account returned in Stripe account
export const getBankAccountLast4Digits = stripeAccountData =>
  stripeAccountData && stripeAccountData.external_accounts.data.length > 0
    ? stripeAccountData.external_accounts.data[0].last4
    : null;

// Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
export const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  stripeAccountData.requirements[requirementType].length > 0;

// Redirect user to Stripe's hosted Connect account onboarding form
export const handleGetStripeConnectAccountLinkFn = (getLinkFn, commonParams) => type => () => {
  getLinkFn({ type, ...commonParams })
    .then(url => {
      window.location.href = url;
    })
    .catch(err => console.error(err));
};

export const RedirectToStripe = ({ redirectFn }) => {
  useEffect(redirectFn('custom_account_verification'), []);
  return <FormattedMessage id="EditListingWizard.redirectingToStripe" />;
};

const getListingTypeConfig = (listing, selectedListingType, config) => {
  const existingListingType = listing?.attributes?.publicData?.listingType;
  const validListingTypes = config.listing.listingTypes;
  const hasOnlyOneListingType = validListingTypes?.length === 1;

  const listingTypeConfig = existingListingType
    ? validListingTypes.find(conf => conf.listingType === existingListingType)
    : selectedListingType
    ? validListingTypes.find(conf => conf.listingType === selectedListingType.listingType)
    : hasOnlyOneListingType
    ? validListingTypes[0]
    : null;
  return listingTypeConfig;
};

// Create a new or edit listing through EditListingWizard
const EditListingWizard = ({
  id,
  className,
  rootClassName,
  params,
  listing,
  viewport,
  intl,
  errors,
  fetchInProgress,
  payoutDetailsSaveInProgress,
  payoutDetailsSaved,
  onManageDisableScrolling,
  onPayoutDetailsChange,
  onPublishListingDraft,
  onGetStripeConnectAccountLink,
  getAccountLinkInProgress,
  createStripeAccountError,
  updateStripeAccountError,
  fetchStripeAccountError,
  stripeAccountFetched,
  stripeAccount,
  stripeAccountError,
  stripeAccountLinkError,
  stripeOnboardingReturnURL,
  currentUser,
  config,
  routeConfiguration,
  ...rest
}) => {
  // constructor(props) {
  //   super(props);

  //   // Having this info in state would trigger unnecessary rerendering
  //   this.hasScrolledToTab = false;

  let hasScrolledToTab = false;
  const [draftId, setDraftId] = useState(null);
  const [showPayoutDetails, setShowPayoutDetails] = useState(false);
  const [selectedListingType, setSelectedListingType] = useState(null);

  useEffect(() => {
    if (stripeOnboardingReturnURL != null && !showPayoutDetails) {
      setShowPayoutDetails(true);
    }
  }, []);

  const handleCreateFlowTabScrolling = shouldScroll => {
    hasScrolledToTab = shouldScroll;
  };

  const handlePublishListing = id => {
    const processName = listing?.attributes?.publicData?.transactionProcessAlias.split('/')[0];
    const isInquiryProcess = processName === INQUIRY_PROCESS_NAME;

    const listingTypeConfig = getListingTypeConfig(listing, selectedListingType, config);
    // Through hosted configs (listingTypeConfig.defaultListingFields?.payoutDetails),
    // it's possible to publish listing without payout details set by provider.
    // Customers can't purchase these listings - but it gives operator opportunity to discuss with providers who fail to do so.
    const isPayoutDetailsRequired = requirePayoutDetails(listingTypeConfig);

    const stripeConnected = !!currentUser?.stripeAccount?.id;
    const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;
    const stripeRequirementsMissing =
      stripeAccount &&
      (hasRequirements(stripeAccountData, 'past_due') ||
        hasRequirements(stripeAccountData, 'currently_due'));

    if (
      isInquiryProcess ||
      !isPayoutDetailsRequired ||
      (stripeConnected && !stripeRequirementsMissing)
    ) {
      onPublishListingDraft(id);
    } else {
      setDraftId(id);
      setShowPayoutDetails(true);
    }
  };

  const handlePayoutModalClose = () => {
    setShowPayoutDetails(false);
  };

  const selectedTab = params.tab;
  const isNewListingFlow = [LISTING_PAGE_PARAM_TYPE_NEW, LISTING_PAGE_PARAM_TYPE_DRAFT].includes(
    params.type
  );
  const rootClasses = rootClassName || css.root;
  const classes = classNames(rootClasses, className);
  const currentListing = ensureListing(listing);
  const savedProcessAlias = currentListing.attributes?.publicData?.transactionProcessAlias;
  const transactionProcessAlias = savedProcessAlias || selectedListingType?.transactionProcessAlias;

  // NOTE: If the listing has invalid configuration in place,
  // the listing is considered deprecated and we don't allow user to modify the listing anymore.
  // Instead, operator should do that through Console or Integration API.
  const validListingTypes = config.listing.listingTypes;
  const listingTypeConfig = getListingTypeConfig(currentListing, selectedListingType, config);
  const existingListingType = currentListing.attributes?.publicData?.listingType;
  const invalidExistingListingType = existingListingType && !listingTypeConfig;
  // TODO: displayPrice aka config.defaultListingFields?.price with false value is only available with inquiry process
  //       if it's enabled with other processes, translations for "new" flow needs to be updated.
  const isPriceDisabled = !displayPrice(listingTypeConfig);

  // Transaction process alias is used here, because the process defineds whether the listing is supported
  // I.e. old listings might not be supported through listing types, but client app might still support those processes.
  const processName = transactionProcessAlias
    ? transactionProcessAlias.split('/')[0]
    : validListingTypes.length === 1
    ? validListingTypes[0].transactionType.process
    : INQUIRY_PROCESS_NAME;

  const hasListingTypeSelected =
    existingListingType || selectedListingType || validListingTypes.length === 1;

  // For oudated draft listing, we don't show other tabs but the "details"
  const tabs =
    isNewListingFlow && (invalidExistingListingType || !hasListingTypeSelected)
      ? TABS_DETAILS_ONLY
      : isBookingProcess(processName)
      ? tabsForBookingProcess(TABS_BOOKING, listingTypeConfig)
      : isPurchaseProcess(processName)
      ? tabsForPurchaseProcess(TABS_PRODUCT, listingTypeConfig)
      : tabsForInquiryProcess(TABS_INQUIRY, listingTypeConfig);

  // Check if wizard tab is active / linkable.
  // When creating a new listing, we don't allow users to access next tab until the current one is completed.
  const tabsStatus = tabsActive(isNewListingFlow, currentListing, tabs, config);

  // Redirect user to first tab when encoutering outdated draft listings.
  if (invalidExistingListingType && isNewListingFlow && selectedTab !== tabs[0]) {
    return <NamedRedirect name="EditListingPage" params={{ ...params, tab: tabs[0] }} />;
  }

  // If selectedTab is not active for listing with valid listing type,
  // redirect to the beginning of wizard
  if (!invalidExistingListingType && !tabsStatus[selectedTab]) {
    const currentTabIndex = tabs.indexOf(selectedTab);
    const nearestActiveTab = tabs
      .slice(0, currentTabIndex)
      .reverse()
      .find(t => tabsStatus[t]);

    console.log(
      `You tried to access an EditListingWizard tab (${selectedTab}), which was not yet activated.`
    );
    return <NamedRedirect name="EditListingPage" params={{ ...params, tab: nearestActiveTab }} />;
  }

  const { width } = viewport;
  const hasViewport = width > 0;
  const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
  const hasVerticalTabLayout = hasViewport && width > MAX_HORIZONTAL_NAV_SCREEN_WIDTH;

  // Check if scrollToTab call is needed (tab is not visible on mobile)
  if (hasVerticalTabLayout) {
    hasScrolledToTab = true;
  } else if (hasHorizontalTabLayout && !hasScrolledToTab) {
    const tabPrefix = id;
    scrollToTab(tabPrefix, selectedTab);
    hasScrolledToTab = true;
  }

  const tabLink = tab => {
    return { name: 'EditListingPage', params: { ...params, tab } };
  };

  const formDisabled = getAccountLinkInProgress;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!ensuredCurrentUser.id;
  const stripeConnected = currentUserLoaded && !!stripeAccount && !!stripeAccount.id;

  const rootURL = config.marketplaceRootURL;
  const { returnURLType, ...pathParams } = params;
  const successURL = createReturnURL(
    STRIPE_ONBOARDING_RETURN_URL_SUCCESS,
    rootURL,
    routeConfiguration,
    pathParams
  );
  const failureURL = createReturnURL(
    STRIPE_ONBOARDING_RETURN_URL_FAILURE,
    rootURL,
    routeConfiguration,
    pathParams
  );

  const accountId = stripeConnected ? stripeAccount.id : null;
  const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;

  const requirementsMissing =
    stripeAccount &&
    (hasRequirements(stripeAccountData, 'past_due') ||
      hasRequirements(stripeAccountData, 'currently_due'));

  const savedCountry = stripeAccountData ? stripeAccountData.country : null;

  const handleGetStripeConnectAccountLink = handleGetStripeConnectAccountLinkFn(
    onGetStripeConnectAccountLink,
    {
      accountId,
      successURL,
      failureURL,
    }
  );

  const returnedNormallyFromStripe = returnURLType === STRIPE_ONBOARDING_RETURN_URL_SUCCESS;
  const returnedAbnormallyFromStripe = returnURLType === STRIPE_ONBOARDING_RETURN_URL_FAILURE;
  const showVerificationNeeded = stripeConnected && requirementsMissing;

  // Redirect from success URL to basic path for StripePayoutPage
  if (returnedNormallyFromStripe && stripeConnected && !requirementsMissing) {
    return <NamedRedirect name="EditListingPage" params={pathParams} />;
  }

  return (
    <div className={classes}>
      <Tabs rootClassName={css.tabsContainer} navRootClassName={css.nav} tabRootClassName={css.tab}>
        {tabs.map(tab => {
          const tabTranslations = tabLabelAndSubmit(
            intl,
            tab,
            isNewListingFlow,
            isPriceDisabled,
            processName
          );
          return (
            <EditListingWizardTab
              {...rest}
              key={tab}
              tabId={`${id}_${tab}`}
              tabLabel={tabTranslations.label}
              tabSubmitButtonText={tabTranslations.submitButton}
              tabLinkProps={tabLink(tab)}
              selected={selectedTab === tab}
              disabled={isNewListingFlow && !tabsStatus[tab]}
              tab={tab}
              params={params}
              listing={listing}
              marketplaceTabs={tabs}
              errors={errors}
              handleCreateFlowTabScrolling={handleCreateFlowTabScrolling}
              handlePublishListing={handlePublishListing}
              onListingTypeChange={selectedListingType =>
                setSelectedListingType(selectedListingType)
              }
              fetchInProgress={fetchInProgress}
              onManageDisableScrolling={onManageDisableScrolling}
              config={config}
              routeConfiguration={routeConfiguration}
            />
          );
        })}
      </Tabs>
      <Modal
        id="EditListingWizard.payoutModal"
        isOpen={showPayoutDetails}
        onClose={handlePayoutModalClose}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <div className={css.modalPayoutDetailsWrapper}>
          <Heading as="h2" rootClassName={css.modalTitle}>
            <FormattedMessage id="EditListingWizard.payoutModalTitleOneMoreThing" />
            <br />
            <FormattedMessage id="EditListingWizard.payoutModalTitlePayoutPreferences" />
          </Heading>
          {!currentUserLoaded ? (
            <FormattedMessage id="StripePayoutPage.loadingData" />
          ) : returnedAbnormallyFromStripe && !stripeAccountLinkError ? (
            <p className={css.modalMessage}>
              <RedirectToStripe redirectFn={handleGetStripeConnectAccountLink} />
            </p>
          ) : (
            <>
              <p className={css.modalMessage}>
                <FormattedMessage id="EditListingWizard.payoutModalInfo" />
              </p>
              <StripeConnectAccountForm
                disabled={formDisabled}
                inProgress={payoutDetailsSaveInProgress}
                ready={payoutDetailsSaved}
                currentUser={ensuredCurrentUser}
                stripeBankAccountLastDigits={getBankAccountLast4Digits(stripeAccountData)}
                savedCountry={savedCountry}
                submitButtonText={intl.formatMessage({
                  id: 'StripePayoutPage.submitButtonText',
                })}
                stripeAccountError={stripeAccountError}
                stripeAccountFetched={stripeAccountFetched}
                stripeAccountLinkError={stripeAccountLinkError}
                onChange={onPayoutDetailsChange}
                onSubmit={rest.onPayoutDetailsSubmit}
                onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink}
                stripeConnected={stripeConnected}
              >
                {stripeConnected && !returnedAbnormallyFromStripe && showVerificationNeeded ? (
                  <StripeConnectAccountStatusBox
                    type="verificationNeeded"
                    inProgress={getAccountLinkInProgress}
                    onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                      'custom_account_verification'
                    )}
                  />
                ) : stripeConnected && savedCountry && !returnedAbnormallyFromStripe ? (
                  <StripeConnectAccountStatusBox
                    type="verificationSuccess"
                    inProgress={getAccountLinkInProgress}
                    disabled={payoutDetailsSaveInProgress}
                    onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                      'custom_account_update'
                    )}
                  />
                ) : null}
              </StripeConnectAccountForm>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

EditListingWizard.defaultProps = {
  className: null,
  currentUser: null,
  rootClassName: null,
  listing: null,
  stripeAccount: null,
  stripeAccountFetched: null,
  updateInProgress: false,
  createStripeAccountError: null,
  updateStripeAccountError: null,
  fetchStripeAccountError: null,
  stripeAccountError: null,
  stripeAccountLinkError: null,
};

EditListingWizard.propTypes = {
  id: string.isRequired,
  className: string,
  currentUser: propTypes.currentUser,
  rootClassName: string,
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(TABS_ALL).isRequired,
  }).isRequired,
  stripeAccount: object,
  stripeAccountFetched: bool,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      price: object,
      title: string,
    }),
    images: array,
  }),

  errors: shape({
    createListingDraftError: object,
    updateListingError: object,
    publishListingError: object,
    showListingsError: object,
    uploadImageError: object,
  }).isRequired,
  createStripeAccountError: propTypes.error,
  updateStripeAccountError: propTypes.error,
  fetchStripeAccountError: propTypes.error,
  stripeAccountError: propTypes.error,
  stripeAccountLinkError: propTypes.error,

  fetchInProgress: bool.isRequired,
  getAccountLinkInProgress: bool.isRequired,
  payoutDetailsSaveInProgress: bool.isRequired,
  payoutDetailsSaved: bool.isRequired,
  onPayoutDetailsChange: func.isRequired,
  onPayoutDetailsSubmit: func.isRequired,
  onGetStripeConnectAccountLink: func.isRequired,
  onManageDisableScrolling: func.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedEditListingWizard = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  return (
    <EditListingWizard
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      {...props}
    />
  );
};

export default withViewport(EnhancedEditListingWizard);
