import { useEffect, useState } from 'react';
import { LayoutSideNavigation, LayoutWrapperAccountSideNav, LayoutWrapperMain, LayoutWrapperTopbar, Page } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './PublicCampaignClientCheckoutPage.module.css';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import appleyPay from './applePayLogo.svg';
import googlePay from './googlePayLogo.svg';
import linkPay from './linkLogo.png';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import axiosInstance from '../../axios';

const PublicCampaignClientCheckoutPage = ({ currentUser, ...props }) => {
  const { campaignId } = useParams();
  const history = useHistory();

  const [paymentMethod, setPaymentMethod] = useState('Google');

  const [campaign, setCampaign] = useState(null);
  const fetchCampaign = async () => {
    try {
      const res = await axiosInstance.get(`/public-campaigns/detail/${campaignId}`);
      const { error, message, data } = await res.data;
      if (!error) {
        setCampaign(data);
      }
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    fetchCampaign();
  }, [campaignId]);

  if (!campaign) return null;

  return (
    <>
      <Page
        title={campaign.name}
        scrollingDisabled={false}
      >
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage={'CaseStudyCreationPage'} />
          </LayoutWrapperTopbar>
          <LayoutWrapperAccountSideNav
            currentTab={'CaseStudyCreationPage'}
            userProfile={true}
            isAvatar={true}
            currentUser={currentUser}
            isProUser={true}
          />
          <LayoutWrapperMain className={css.wrapperMain}>
            <div className={css.content}>
              <div className={css.left}>
                <Breadcrumb
                  items={[
                    {
                      label: "Campaigns",
                      onClick: () => history.push('/campaigns'),
                    },
                    {
                      label: campaign.name,
                      onClick: () => history.push('/campaign/detail/' + campaignId),
                    },
                    {
                      label: "Checkout",
                    },
                  ]}
                />

                <div className={css.headerContainer}>
                  <div className={css.headerTitle}>Checkout</div>
                  <div className={css.headerSubtitle}>Your payment will be held as a deposit and will begin processing once the content has been delivered and approved.</div>
                </div>
                <div className={css.paymentMethodContainer}>
                  <div className={css.sectionTitle}>Payment Method</div>
                  <div className={css.paymentMethodSelect}>
                    <div className={`${css.paymentMethodSelectItem} ${paymentMethod === 'Apple Pay' && css.selectedItem}`}  onClick={() => setPaymentMethod('Apple Pay')}>
                      <img src={appleyPay} />
                    </div>
                    <div className={`${css.paymentMethodSelectItem} ${paymentMethod === 'Google' && css.selectedItem}`} onClick={() => setPaymentMethod('Google')}>
                      <img src={googlePay} />
                    </div>
                    <div className={`${css.paymentMethodSelectItem} ${paymentMethod === 'Link' && css.selectedItem}`}  onClick={() => setPaymentMethod('Link')}>
                      <img src={linkPay} />
                    </div>
                  </div>
                </div>

                <div className={css.cardPaymentContainer}>
                  <div className={css.sectionTitle}>Or pay with Credit/Debit Card</div>
                  <input type='text' className={`${css.inputText} ${css.w_100}`} placeholder='0000 0000 0000 0000'/>
                  <div className={css.gridTwoColumns}>
                    <input type='text' className={`${css.inputText}`} placeholder='MM/YYYY'/>
                    <input type='text' className={`${css.inputText}`} placeholder='CVC' />
                  </div>
                  <div className={css.saveCardContainer}>
                    <div className={css.checkbox}>
                      <input type='checkbox'/>
                    </div>
                    <div className={css.saveCardTextContainer}>
                      <div className={css.saveCardTitle}>Save card for future payments</div>
                      <div className={css.saveCardSubtitle}>I authorize Collabreate.co to send instructions to the financial institution that issued my card to take payments from my card account in accordance with the terms of my agreement with you.</div>
                    </div>
                  </div>
                </div>

                <div className={css.cardPaymentContainer}>
                  <div className={css.sectionTitle}>Billing Details</div>
                  <input type='text' className={`${css.inputText} ${css.w_100}`} placeholder="Cardholder's name"/>
                  <div className={css.gridTwoColumns}>
                    <input type='text' className={`${css.inputText}`} placeholder='Street address'/>
                    <input type='text' className={`${css.inputText}`} placeholder='Postal code'/>
                    <input type='text' className={`${css.inputText}`} placeholder='State (optional)'/>
                    <input type='text' className={`${css.inputText}`} placeholder='Apt# (optional)'/>
                    <input type='text' className={`${css.inputText}`} placeholder='City'/>
                    <input type='text' className={`${css.inputText}`} placeholder='Country'/>
                  </div>
                </div>

              </div>

              <div className={css.right}>
                  <div className={css.summaryContainer}>
                    <div className={css.sectionTitle}>Order Summary</div>
                    <div className={css.summaryTextContainer}>
                      <div>Item</div>
                      <div className={css.textRight}>Campaign Name</div>
                      <div>Order Date</div>
                      <div className={css.textRight}>Jan 9, 12:00</div>
                      <div>Delivery Date</div>
                      <div className={css.textRight}>Jan 10, 12:00</div>
                    </div>

                    <hr />

                    <div className={css.sectionTitle}>Order Details</div>
                    <div className={css.summaryTextContainer}>
                      <div>Price per item</div>
                      <div className={`${css.textSGD} ${css.textRight}`}>SGD 80</div>
                      <div>Add on name</div>
                      <div className={`${css.textSGD} ${css.textRight}`}>SGD 50</div>
                      <div>Platform fee</div>
                      <div className={`${css.textSGD} ${css.textRight}`}>SGD 15</div>
                    </div>

                    <hr />

                    <div className={css.summaryTextContainer}>
                      <div className={css.textSGD}>Total Price</div>
                      <div className={`${css.textSGD} ${css.textRight}`}>SGD 145</div>
                    </div>

                  </div>
              </div>
            </div>
            <div className={css.footer}>
              <button className={css.footerButton}>Purchase</button>
            </div>
          </LayoutWrapperMain>
        </LayoutSideNavigation>
      </Page>
    </>
  )
}

const mapStateToProps = state => {
  const { currentUser, currentUserHasListings } = state.user;
  const { isAuthenticated } = state.Auth;

  return {
    currentUser,
    isAuthenticated,
  };
};

export default compose(withRouter, connect(mapStateToProps))(PublicCampaignClientCheckoutPage);
