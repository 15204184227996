import _ from 'lodash';
import { Form, Formik } from 'formik';
import LearnerFirstStep from './LearnerFirstStep';
import useStepperHook from '../../Hooks/Stepper.hook';
import ProStepperCss from '../../styles/ProStepper.style';
import { withRouter } from 'react-router-dom';

const LernerStepper = props => {
  const data = useStepperHook({ section: 'learner', ...props });

  const STEPS_TITLE_ACCORDING = [
    {
      step: 1,
      component: other => <LearnerFirstStep {...{ ...data, ...other }} />,
      title: 'Tell us about your company',
      image: require('../../assest/Woman-with-phone.webp'),
      para: '',
    },
    // {
    //   step: 2,
    //   component: other => <LearnerSecondStep {...{ ...data, ...other }} />,
    //   title: "Tell us more what you're interested in",
    //   image: require('../../assest/listingType.png'),
    //   para: "We'll help you find services and products that suit you best",
    // },
  ];

  const {
    step,
    _submitLearnerForm,
    _handleNext,
    isLastStep,
    STEPS,
    learnerValidationSchema,
  } = data;
  const CURRENT_TITLE_OBJECT = _.find(STEPS_TITLE_ACCORDING, { step: step + 1 });

  return (
    <ProStepperCss>
      <div className="steps_main_container">
        <div className="secondContainer">
          <div className="mainProFirst_container commonWidth">
            <section className="first_container">
              <div className="title_section_css">
                <h1>{CURRENT_TITLE_OBJECT?.title}</h1>
                <p>{CURRENT_TITLE_OBJECT?.para}</p>
              </div>

              {/* <div className="stepper">
            <Stepper
              className="stepper-container"
              steps={STEPS.map(({ title }) => ({ label: title }))}
              activeStep={step}
              stepClassName="steps"
              connectorStateColors={true}
              connectorStyleConfig={{ completedColor: '#0F69E0', activeColor: '#0F69E0' }}
            />{' '}
          </div> */}
            </section>
            <Formik
              enableReinitialize
              initialValues={{}}
              validationSchema={step === 0 ? learnerValidationSchema : null}
              onSubmit={(values, action) => _submitLearnerForm(values, action)}
            >
              {({ isSubmitting, ...otherfielsProps }) => {
                // console.log(otherfielsProps);
                return (
                  <Form id={'formId' + (step + 1)}>
                    {CURRENT_TITLE_OBJECT?.component(otherfielsProps)}
                    <div className={`actionButtons`}>
                      {/* <button
                      type="button"
                      style={{ margin: '0 auto' }}
                      onClick={() => _handleNext('learner')}
                      className="backbutton"
                    >
                      {isLastStep ? 'Skip' : 'Skip Now'}
                    </button> */}
                      <button
                        // disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ margin: '0 auto' }}
                        className=""
                      >
                        Join
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div className="imageSection">
            <img src={CURRENT_TITLE_OBJECT?.image} alt="stepperimage" />
            {step === 0 && (
              <div className="imageContent">
                <div>
                  <h3>Collaborate with multiple vetted creators at once</h3>
                  <span>Increase your ad performances with quality content </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ProStepperCss>
  );
};

export default withRouter(LernerStepper);
