import { useEffect, useState } from 'react';
import { Menu, MenuContent, MenuItem, MenuLabel, RangeSlider } from '../../../components';
import { GrayButton, PinkButton } from '../../../components/Button/Button';
import { formatPrice, getPriceQueryParamName, reverseFormatPrice } from '../Filter.helpers';
import ArrowIcon from './ArrowIcon';
import css from './Filter.module.css';
import { filters } from '../../../marketplace-custom-config';
import classNames from 'classnames';

const PriceFilter = ({ urlQueryParams, onSubmit, history }) => {
  const [open, setOpen] = useState(false);
  const [handles, setHandles] = useState([0, 2000]);
  const filter = filters.find(({ id }) => id === 'price');
  const queryParamName = getPriceQueryParamName(filter.queryParamNames);

  const handleSubmit = () => {
    const formatted = formatPrice({ minPrice: handles[0], maxPrice: handles[1] }, queryParamName);
    onSubmit(formatted);
    setOpen(false);
  };

  useEffect(() => {
    if (queryParamName.price) {
      const { maxPrice, minPrice } = reverseFormatPrice(urlQueryParams, queryParamName);
      setHandles([minPrice, maxPrice]);
    }
  }, [urlQueryParams, queryParamName]);

  const isSelected = history?.location?.search?.includes('price');

  return (
    <Menu
      className={css.filterContainer}
      useArrow={false}
      onToggleActive={values => setOpen(values)}
      isOpen={open}
    >
      <MenuLabel className={classNames(css.filterLabel, isSelected && css.active)}>
        <span>Price</span>
        <ArrowIcon />
      </MenuLabel>
      <MenuContent key="activities" className={css.filterContent}>
        <MenuItem key="slider" className={css.filterItemContainer}>
          <RangeSlider
            min={0}
            max={2000}
            step={1}
            handles={handles}
            onChange={values => {
              setHandles([values[0], values[1]]);
            }}
          />
        </MenuItem>
        <MenuItem key="values" className={css.filterSliderValueContainer}>
          <span>{`${handles[0]} SGD`}</span>
          <span>{`${handles[1]} SGD`}</span>
        </MenuItem>
        <MenuItem key="actions" className={css.filterAction}>
          <button
            className={css.filterBtnApply}
            onClick={() => {
              setHandles([0, 2000]);
            }}
          >
            Clear All
          </button>
          <PinkButton className={css.filterBtnGray} onClick={handleSubmit}>
            Apply
          </PinkButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

export default PriceFilter;
