import styled from 'styled-components';

export const LearnerAndProOptionStyle = styled.div`
  .mainLernerPro_class {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .chooseSection_class {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .contentButton_section {
        .content {
          h2 {
            /* CSG 24 Heading */
            font-family: Unbounded;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 38px; /* 158.333% */
            color: #1c1c1c;
            margin-top: 0;
            text-align: left;
            @media (max-width: 768px) {
              font-size: 24px;
              line-height: 32px;
              margin-bottom: 5px;
            }
          }
          p {
            color: #959595;
            font-size: 16px;
            line-height: 24px;
            font-weight: var(--fontWeightRegular);
            @media (max-width: 767px) {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }

        .learnerPro_Buttons {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 20px;
          margin-top: 30px;
          @media (max-width: 1199px) {
            margin-bottom: 30px;
          }
          @media (max-width: 767px) {
            flex-wrap: wrap;
            gap: 16px;
            margin-bottom: 38px;
            margin-top: 5px;
          }
          .borderClass {
            width: 100%;
            border: 1px solid #dddddd;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            padding: 24px;
            border-radius: 16px;
            cursor: pointer;

            @media (max-width: 767px) {
              max-width: 100%;
            }
            .icon {
              display: flex;
              justify-content: space-between;
              align-items: center;
              img {
                width: 32px;
              }
              input[type='checkbox'] {
                width: 20px;
                height: 20px;
                border-color: #dddddd;
                border-radius: 5px;
              }
            }

            .text {
              font-weight: var(--fontWeightRegular);
              p {
                /* CSG 20 Semibold */
                font-family: Afacad;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px; /* 120% */
                color: #313131;
                @media (max-width: 767px) {
                  font-size: 16px;
                }
              }

              span {
                font-family: Afacad;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 137.5% */
                color: #656565;

                @media (max-width: 767px) {
                  line-height: normal;
                  display: block;
                }
              }
            }
          }
        }
      }

      .actionSection {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 16px;
        p {
          font-family: Afacad;
          font-size: 16px;
          color: #1c1c1c;
          line-height: normal;
          a {
            color: #dd58d6;
          }
        }
      }
    }
  }
`;
