/**
 * This is a wrapper component for different Layouts.
 * Navigational 'aside' content should be added to this wrapper.
 */
import React from 'react';
import { node, number, string, shape, bool, object } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { LayoutWrapperSideNav } from '../../components';
import { useSelector } from 'react-redux';
const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;
const scrollToTab = currentTab => {
  const el = document.querySelector(`#${currentTab}Tab`);
  // if (el) {
  //   el.scrollIntoView({
  //     block: 'end',
  //     inline: 'end',
  //     behavior: 'smooth',
  //   });
  // }
};
const LayoutWrapperAccountSettingsSideNavComponent = props => {
  //add additional prop "userProfile" to reuse this component for user profile
  //isAvatar defines if there is avatar in above the side navigation
  // isProUser defines if our side navigation for user or for creator profile
  const { currentTab, viewport, userProfile, isAvatar, currentUser, isProUser } = props;
  let hasScrolledToTab = false;
  const { width } = viewport;
  const hasViewport = width > 0;
  const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
  const hasVerticalTabLayout = hasViewport && width > MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
  const hasFontsLoaded = hasViewport && document.documentElement.classList.contains('fontsLoaded');
  // Check if scrollToTab call is needed (tab is not visible on mobile)
  if (hasVerticalTabLayout) {
    hasScrolledToTab = true;
  } else if (hasHorizontalTabLayout && !hasScrolledToTab && hasFontsLoaded) {
    scrollToTab(currentTab);
    hasScrolledToTab = true;
  }

  const id = useSelector(state => state?.user?.currentUserListing?.id?.uuid);
  const currentUserListing = useSelector(state => state?.user?.currentUserListing);
  const stripeAccountId = useSelector(
    state => state.stripeConnectAccount?.stripeAccount?.attributes?.stripeAccountId
  );
  //const stripeAccountFetched = state => state?.stripeConnectAccount?.stripeAccountFetched;

  const userId = useSelector(state => state?.user);
  const usualUserAccountSettingTabs = [
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.contactDetailsTabTitle" />,
      selected: currentTab === 'ContactDetailsPage',
      id: 'ContactDetailsPageTab',
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.passwordTabTitle" />,
      selected: currentTab === 'PasswordChangePage',
      id: 'PasswordChangePageTab',
      linkProps: {
        name: 'PasswordChangePage',
      },
    },
  ];
  const proUserAccountSettingTabs = [
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentsTabTitle" />,
      selected: currentTab === 'StripePayoutPage',
      id: 'StripePayoutPageTab',
      linkProps: {
        name: 'StripePayoutPage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentMethodsTabTitle" />,
      selected: currentTab === 'PaymentMethodsPage',
      id: 'PaymentMethodsPageTab',
      linkProps: {
        name: 'PaymentMethodsPage',
      },
    },
  ];
  //We need to show payment options only for pro users
  const accountSettingsTabs = currentUserListing
    ? usualUserAccountSettingTabs.concat(proUserAccountSettingTabs)
    : usualUserAccountSettingTabs;

  // const userProfileTabs = isProUser ? proProfileTabs : usualUserProfileTabs;
  return (
    <LayoutWrapperSideNav
      tabs={accountSettingsTabs}
      isAvatar={isAvatar}
      currentUser={currentUser}
      {...props}
    />
  );
};
LayoutWrapperAccountSettingsSideNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  currentTab: null,
  userProfile: false,
  isAvatar: false,
  isProUser: false,
};
LayoutWrapperAccountSettingsSideNavComponent.propTypes = {
  children: node,
  className: string,
  rootClassName: string,
  currentTab: string,
  userProfile: bool,
  isAvatar: bool,
  currentUser: object,
  isProUser: bool,
  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};
const LayoutWrapperAccountSettingsSideNav = compose(withViewport)(
  LayoutWrapperAccountSettingsSideNavComponent
);
export default LayoutWrapperAccountSettingsSideNav;
