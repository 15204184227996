import React, { useEffect, useState } from 'react';
import { bool, func, number, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

// Import configs and util modules
import appSettings from '../../../../config/settings';
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { STOCK_INFINITE_ITEMS, STOCK_MULTIPLE_ITEMS, propTypes } from '../../../../util/types';
import { isOldTotalMismatchStockError } from '../../../../util/errors';
import * as validators from '../../../../util/validators';
import { formatMoney } from '../../../../util/currency';
import { types as sdkTypes } from '../../../../util/sdkLoader';

// Import shared components
import {
  Form,
  FieldCurrencyInput,
  FieldCheckboxGroup,
  FieldTextInput,
  NamedLink,
  PrimaryButton,
} from '../../../../components';
import config from '../../../../config';

// Import modules from this directory
import css from './EditListingPricingAndStockForm.module.css';

const { Money } = sdkTypes;
const MILLION = 1000000;

const getPriceValidators = (listingMinimumPriceSubUnits, marketplaceCurrency, intl) => {
  const priceRequiredMsgId = { id: 'EditListingPricingAndStockForm.priceRequired' };
  const priceRequiredMsg = intl.formatMessage(priceRequiredMsgId);
  const priceRequired = validators.required(priceRequiredMsg);

  const minPriceRaw = new Money(listingMinimumPriceSubUnits, marketplaceCurrency);
  const minPrice = formatMoney(intl, minPriceRaw);
  const priceTooLowMsgId = { id: 'EditListingPricingAndStockForm.priceTooLow' };
  const priceTooLowMsg = intl.formatMessage(priceTooLowMsgId, { minPrice });
  const minPriceRequired = validators.moneySubUnitAmountAtLeast(
    priceTooLowMsg,
    listingMinimumPriceSubUnits
  );

  return listingMinimumPriceSubUnits
    ? validators.composeValidators(priceRequired, minPriceRequired)
    : priceRequired;
};

/**
 * If stock type is changed to infinity (on the fly),
 * we show checkbox for providers to update their current stock to infinity.
 * This is created to avoid overselling problem, if operator changes stock type
 * from finite to infinite. I.e. the provider notices, if stock management configuration has changed.
 *
 * Note 1: infinity is faked using billiard aka 10^15
 * Note 2: If stock is less than a million (10^6) items, we show this checkbox component.
 *
 * @param {Object} props contains { hasInfiniteStock, currentStock, formId, intl }
 * @returns a component containing checkbox group (stockTypeInfinity) with one key: infinity
 */
const UpdateStockToInfinityCheckboxMaybe = ({ hasInfiniteStock, currentStock, formId, intl }) => {
  return hasInfiniteStock && currentStock != null && currentStock < MILLION ? (
    <div className={css.input}>
      <p>
        <FormattedMessage
          id="EditListingPricingAndStockForm.updateToInfiniteInfo"
          values={{
            currentStock,
            b: msgFragment => <b>{msgFragment}</b>,
          }}
        />
      </p>
      <FieldCheckboxGroup
        id={`${formId}.stockTypeInfinity`}
        name="stockTypeInfinity"
        options={[
          {
            key: 'infinity',
            label: intl.formatMessage({
              id: 'EditListingPricingAndStockForm.updateToInfinite',
            }),
          },
        ]}
        validate={validators.requiredFieldArrayCheckbox(
          intl.formatMessage({
            id: 'EditListingPricingAndStockForm.updateToInfiniteRequired',
          })
        )}
      />
    </div>
  ) : null;
};

const TimesIcon = props => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.03999 4.03999C4.29383 3.78615 4.70539 3.78615 4.95923 4.03999L8.99963 8.08039L13.04 4.04003C13.2938 3.78619 13.7054 3.78619 13.9592 4.04003C14.2131 4.29387 14.2131 4.70542 13.9592 4.95927L9.91887 8.99963L13.9592 13.04C14.213 13.2938 14.213 13.7054 13.9592 13.9592C13.7054 14.213 13.2938 14.213 13.04 13.9592L8.99963 9.91887L4.95927 13.9592C4.70543 14.2131 4.29387 14.2131 4.04003 13.9592C3.78619 13.7054 3.78619 13.2938 4.04003 13.04L8.08039 8.99963L4.03999 4.95923C3.78615 4.70539 3.78615 4.29383 4.03999 4.03999Z"
      fill="#959595"
    />
  </svg>
);

const getInitialAddOns = props => {
  if (props.initialValues.addOns) {
    return Object.entries(props.initialValues.addOns).map(([id, { amount, currency }]) => ({
      label: id,
      amount,
      currency,
      default: false,
      id,
    }));
  } else {
    return config.addOns.map(v => ({
      label: v,
      amount: 0,
      currency: 'SGD',
      default: true,
      id: v,
    }));
  }
};

export const EditListingPricingAndStockFormComponent = props => {
  const [addOns, setAddOns] = useState(getInitialAddOns(props));
  const [addOnsError, setAddOnsError] = useState();

  const handleAddOnsAmount = (addOn, event) => {
    // Get the new amount from the event
    const newAmount = parseInt(event.target.value);

    // Create a new array with the updated add-on
    const newAddOns = addOns.map(item => {
      if (item.id === addOn.id) {
        return { ...item, amount: newAmount };
      } else {
        return item;
      }
    });

    // Update the state
    setAddOns(newAddOns);
  };

  const handleNewAddons = () => {
    const newAddOns = {
      label: '',
      amount: 0,
      currency: 'SGD',
      default: false,
      id: new Date().getTime(),
    };
    setAddOns([...addOns, newAddOns]);
  };

  const handleAddonsLabel = (addOn, event) => {
    setAddOnsError(undefined);
    const newLabel = event.target.value;

    // Create a new array with the updated add-on
    const newAddOns = addOns.map(item => {
      if (item.id === addOn.id) {
        return { ...item, label: newLabel };
      } else {
        return item;
      }
    });

    // Update the state
    setAddOns(newAddOns);
  };

  const handleAddonsRemove = addOn => {
    // Create a new array without the add-on to be removed
    const newAddOns = addOns.filter(item => item.id !== addOn.id);

    // Update the state
    setAddOns(newAddOns);
  };

  useEffect(() => {
    const labels = addOns.map(item => item.label).filter(v => v !== '');
    const duplicates = labels.filter((item, index) => labels.indexOf(item) != index);
    if (duplicates.length > 0) {
      setAddOnsError(`Duplicate labels found: ${duplicates.join(', ')}`);
    }
  }, [addOns]);

  return (
    <FinalForm
      {...props}
      onSubmit={value => {
        const transformedAddOns = addOns.reduce((acc, cur) => {
          if (cur.amount > 0 && cur.label !== '') {
            acc[cur.label] = {
              amount: cur.amount,
              currency: cur.currency,
            };
          }
          return acc;
        }, {});

        props.onSubmit({ ...value, addOns: transformedAddOns });
      }}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          formId,
          autoFocus,
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          marketplaceCurrency,
          unitType,
          listingMinimumPriceSubUnits,
          listingType,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          values,
        } = formRenderProps;

        const priceValidators = getPriceValidators(
          listingMinimumPriceSubUnits,
          marketplaceCurrency,
          intl
        );
        // Note: outdated listings don't have listingType!
        // I.e. listings that are created with previous listing type setup.
        const hasStockManagement = listingType?.stockType === STOCK_MULTIPLE_ITEMS;
        const stockValidator = validators.numberAtLeast(
          intl.formatMessage({ id: 'EditListingPricingAndStockForm.stockIsRequired' }),
          0
        );
        const hasInfiniteStock = STOCK_INFINITE_ITEMS.includes(listingType?.stockType);
        const currentStock = values.stock;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress || addOnsError;
        const { updateListingError, showListingsError, setStockError } = fetchErrors || {};

        const stockErrorMessage = isOldTotalMismatchStockError(setStockError)
          ? intl.formatMessage({ id: 'EditListingPricingAndStockForm.oldStockTotalWasOutOfSync' })
          : intl.formatMessage({ id: 'EditListingPricingAndStockForm.stockUpdateFailed' });

        return (
          <Form onSubmit={handleSubmit} className={classes}>
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingAndStockForm.updateFailed" />
              </p>
            ) : null}
            {showListingsError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingAndStockForm.showListingFailed" />
              </p>
            ) : null}
            <FieldCurrencyInput
              id={`${formId}.price`}
              name="price"
              className={css.input}
              autoFocus={autoFocus}
              label={intl.formatMessage(
                { id: 'EditListingPricingAndStockForm.pricePerProduct' },
                { unitType }
              )}
              placeholder={intl.formatMessage({
                id: 'EditListingPricingAndStockForm.priceInputPlaceholder',
              })}
              currencyConfig={appSettings.getCurrencyFormatting(marketplaceCurrency)}
              validate={priceValidators}
            />

            <UpdateStockToInfinityCheckboxMaybe
              formId={formId}
              hasInfiniteStock={hasInfiniteStock}
              currentStock={currentStock}
              intl={intl}
            />

            {hasStockManagement ? (
              <FieldTextInput
                className={css.input}
                id={`${formId}.stock`}
                name="stock"
                label={intl.formatMessage({ id: 'EditListingPricingAndStockForm.stockLabel' })}
                placeholder={intl.formatMessage({
                  id: 'EditListingPricingAndStockForm.stockPlaceholder',
                })}
                type="number"
                min={0}
                validate={stockValidator}
              />
            ) : (
              <Field id="stock" name="stock" type="hidden" className={css.unitTypeHidden}>
                {fieldRenderProps => <input {...fieldRenderProps?.input} />}
              </Field>
            )}
            {setStockError ? <p className={css.error}>{stockErrorMessage}</p> : null}

            <div>
              <label htmlFor="" className={css.addOnsLabel}>
                Add ons
              </label>
              <p className={css.addOnsDesc}>
                List as many add ons for buyers to purchase with this listing. You can use our
                suggestions or add your custom add ons.
              </p>
              {addOns.map(value => (
                <div className={css.addOnsItem} key={value.id}>
                  {value.default ? (
                    <label htmlFor="">{value.label}</label>
                  ) : (
                    <input
                      type="text"
                      placeholder="Add ons name"
                      defaultValue={value.label}
                      onChange={e => handleAddonsLabel(value, e)}
                    />
                  )}

                  <div className={css.addOnsInput}>
                    <span className={css.addOnsCurrency}>SGD</span>
                    <input
                      defaultValue={value.amount}
                      type="number"
                      placeholder="0.00"
                      onChange={e => handleAddOnsAmount(value, e)}
                    />
                    <TimesIcon onClick={() => handleAddonsRemove(value)} />
                  </div>
                </div>
              ))}
              <button type="button" className={css.addOnsButton} onClick={handleNewAddons}>
                Add custom field
              </button>
              {addOnsError && <div className={css.addOnsError}>{addOnsError}</div>}
            </div>
            <div className={css.buttonContainer}>
              <PrimaryButton
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </PrimaryButton>
              <NamedLink name="ManageListingsPage">Return to Profile</NamedLink>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingPricingAndStockFormComponent.defaultProps = {
  fetchErrors: null,
  listingMinimumPriceSubUnits: 0,
  formId: 'EditListingPricingAndStockForm',
};

EditListingPricingAndStockFormComponent.propTypes = {
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  marketplaceCurrency: string.isRequired,
  listingMinimumPriceSubUnits: number,
  unitType: string.isRequired,
  listingType: shape({ stockType: string }).isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingAndStockFormComponent);
