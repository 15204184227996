import { useEffect, useState } from 'react';
import { Menu, MenuContent, MenuItem, MenuLabel } from '../../../components';
import { GrayButton, PinkButton } from '../../../components/Button/Button';
import { filters } from '../../../marketplace-custom-config';
import { format, getQueryParamName, reverseFormat } from '../Filter.helpers';
import ArrowIcon from './ArrowIcon';
import css from './Filter.module.css';
import classNames from 'classnames';

const options = [
  { label: 'Gaming', key: 'gaming' },
  { label: 'Health & Fitness', key: 'fitness' },
  { label: 'Beauty and Fashion', key: 'beauty' },
  { label: 'Tech Reviews and Gadgets', key: 'tech' },
  { label: 'Cooking and Food', key: 'food' },
  { label: 'DIY and Crafts', key: 'diy' },
  { label: 'Educational Content', key: 'education' },
  { label: 'Personal Finance and Investing', key: 'finance' },
  { label: 'Comedy and Entertainment', key: 'entertainment' },
  { label: 'Music and Dance', key: 'music' },
  { label: 'Lifestyle and Vlogging', key: 'lifestyle' },
  { label: 'Pet and Animal Care', key: 'animal' },
  { label: 'Sustainability and Minimalism', key: 'sustainability' },
  { label: 'Photography and Videography', key: 'photography' },
  { label: 'Health and Wellness', key: 'wellness' },
  { label: 'Business and Entrepreneurship', key: 'business' },
  { label: 'Arts and Painting', key: 'arts' },
  { label: 'Self-Improvement and Motivation', key: 'self-improvement' },
  { label: 'Other', key: 'other' },
];

const NicheFilter = ({ urlQueryParams, onSubmit, history }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState([]);
  const filter = filters.find(({ id }) => id === 'niche');
  const searchMode = filter.config.searchMode;
  const queryParamName = getQueryParamName(filter.queryParamNames);

  const handleSelect = key => {
    if (!values.includes(key)) {
      setValues([...values, key]);
    } else {
      setValues(values.filter(k => k !== key));
    }
  };

  const handleSubmit = () => {
    const usedValue = values;
    const formatted = format(usedValue, queryParamName, searchMode);
    onSubmit(formatted);
    setOpen(false);
  };

  useEffect(() => {
    const { selectedOptions } = reverseFormat(urlQueryParams, queryParamName);
    setValues(selectedOptions);
  }, [urlQueryParams, queryParamName]);

  const isSelected = history?.location?.search?.includes('pub_niche');

  return (
    <Menu
      className={css.filterContainer}
      useArrow={false}
      onToggleActive={values => setOpen(values)}
      isOpen={open}
    >
      <MenuLabel className={classNames(css.filterLabel, isSelected && css.active)}>
        <span>Niche</span>
        <ArrowIcon />
      </MenuLabel>
      <MenuContent key="activities" className={css.filterContent}>
        <MenuItem key="items" className={css.filterItemContainer}>
          {options.map(option => (
            <span
              key={option.key}
              className={classNames(
                css.filterItem,
                values.includes(option.key) && css.filterItemActive
              )}
              onClick={() => handleSelect(option.key)}
            >
              {option.label}
            </span>
          ))}
        </MenuItem>
        <MenuItem key="actions" className={css.filterAction}>
          <button
            className={css.filterBtnApply}
            onClick={() => {
              setValues([]);
            }}
          >
            Clear All
          </button>
          <PinkButton className={css.filterBtnGray} onClick={handleSubmit}>
            Apply
          </PinkButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

export default NicheFilter;
