import { storableError } from '../util/errors';
import { fetchCurrentUser } from './user.duck';

export const FETCH_REQUEST = 'app/Favorite/FETCH_REQUEST';
export const FETCH_SUCCESS = 'app/Favorite/FETCH_SUCCESS';
export const FETCH_ERROR = 'app/Favorite/FETCH_ERROR';

export const UPDATE_REQUEST = 'app/Favorite/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'app/Favorite/UPDATE_SUCCESS';
export const UPDATE_ERROR = 'app/Favorite/UPDATE_ERROR';

const initialState = {
  fetchInProgres: false,
  fetchData: [],
  fetchError: null,
  updateInProgres: false,
  updateData: [],
  updateError: null,
};

export default function favoriteReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REQUEST:
      return { ...state, fetchInProgres: true };
    case FETCH_SUCCESS:
      return { ...state, fetchInProgres: false, fetchData: action.payload.data };
    case FETCH_ERROR:
      return { ...state, fetchInProgres: false, fetchError: payload };
    case UPDATE_REQUEST:
      return { ...state, updateInProgres: true, error: null };
    case UPDATE_SUCCESS:
      return { ...state, updateInProgres: false };
    case UPDATE_ERROR:
      return { ...state, updateInProgres: false, updateError: action.payload };
    default:
      return state;
  }
}

export const updateFavorite = payload => (dispatch, getState, sdk) => {
  dispatch({
    type: UPDATE_REQUEST,
  });

  return sdk.currentUser
    .updateProfile(payload)
    .then(response => {
      dispatch({ type: UPDATE_SUCCESS });

      dispatch(fetchCurrentUser());
    })
    .catch(e => dispatch({ type: UPDATE_ERROR, payload: storableError(e) }));
};
