import styled from 'styled-components';
import '../../styles/marketplace.css';

const ChipContainer = styled.div`
  display: inline-flex;
  padding: ${props => props.padding || '4px 12px'};
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid ${props => props.borderColor || '#FFF'};
  background-color: ${props => props.backgroundColor || 'transparent'};
  border-radius: 100px;
  color: ${props => props.textColor || '#FFF'};
  font-weight: 400;
  width: ${props => props.width};
  font-size: 14px;

  @media (min-width: 768px) {
    font-size: 16px;
    padding: ${props => props.mobilePadding || '4px 8px'};
  }
`;

export default function Chip({
  children,
  borderColor = '#FFF',
  textColor = '#FFF',
  backgroundColor = 'transparent',
  width = 'auto',
  padding,
  mobilePadding,
  className,
}) {
  return (
    <ChipContainer
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      textColor={textColor}
      width={width}
      padding={padding}
      mobilePadding={mobilePadding}
      className={className}
    >
      {children}
    </ChipContainer>
  );
}
