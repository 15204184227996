import styled from 'styled-components';

const CreatSingupStyle = styled.div`
  .MainCreateAccount_Class {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media (max-width: 991px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 30px;
    }
    .createFirstSection {
      h1 {
        letter-spacing: normal;
        font-family: Unbounded;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
        text-align: left;
        margin-top: 0;
        margin-bottom: 16px;
        color: #1c1c1c;
        @media (max-width: 991px) {
          font-size: 24px;
        }
      }

      .desc {
        color: var(--typo-secondary, #656565);
        font-family: Afacad;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */

        span {
          color: var(--color-ContentSG-purple-500, #dd58d6);
          cursor: pointer;
        }
      }

      .actionsButton {
        margin-top: 36px;
      }

      .signupForm {
        display: flex;
        flex-direction: column;
        height: 445px;
        label {
          font-weight: var(--fontWeightRegular);
        }
        @media (max-width: 991px) {
          height: inherit;
          gap: 64px;
        }
        .allInput_section {
          display: flex;
          flex-direction: column;
          gap: 18px;
          @media (max-width: 991px) {
            gap: 16px;
          }
          .inputField {
            width: 100%;
            input {
              color: #1c1c1c;
              width: 100%;
              height: 48px;
              border: 1px solid #ddd !important;
              border-radius: 6px;
              padding: 10px;
              font-size: 16px;
            }
          }
          .firstLast_name {
            display: flex;
            justify-content: space-between;
            gap: 10px;

            input {
              max-width: 100%;
            }
          }

          input[type='checkbox'] {
            font-size: 16px;
            display: none;
            & + label {
              font-family: Afacad;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 137.5% */
              padding-left: 30px;
              position: relative;
              font-weight: var(--fontWeightRegular);

              a {
                color: #dd58d6;
              }
            }

            & + label::before {
              display: block;
              border: 1px solid #999;
              border-radius: 4px;
              left: 3px;
              top: 9px;
              height: 18px;
              width: 18px;
              content: '';
              position: absolute;
              line-height: 14px;

              @media (max-width: 767px) {
                top: 3px;
              }
            }

            &:invalid + label {
              color: red;
            }

            &:invalid + label::before {
              border-color: red;
            }

            &:checked + label::before {
              content: '✓';
              text-indent: 2px;
            }
          }
        }

        .formsubmit {
          text-align: center;
          margin-top: 40px;

          p {
            margin-top: 16px;
            font-family: Afacad;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            color: #1c1c1c;
            line-height: normal;
            a {
              color: #dd58d6;
            }
          }
        }
      }
    }
  }
`;

export default CreatSingupStyle;
