import { _ } from 'core-js';
import useStepperHook from '../../Hooks/Stepper.hook';
import ProFirstStepFile from './ProFirstStepFile';
import ProSecondStepFile from './ProSecondStepFile';
import ProThirdStepFile from './ProThirdStepFile';
import ProFourthStepFile from './ProFourthStepFile';
import { Stepper } from 'react-form-stepper';
import { Form, Formik } from 'formik';
import ProStepperCss from '../../styles/ProStepper.style';
import { FormattedMessage } from 'react-intl';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import arrow_left from '../../../assets/signup/arrow-left.svg';
import arrow_right_light from '../../../assets/signup/arrow-right.svg';

const ProStepperPage = props => {
  const { uploadInProgress } = useSelector(state => state.ProfileSettingsPage);
  const data = useStepperHook({ section: 'pro', ...props });
  const [productType, setProductType] = useState('services');
  const {
    createListingDraftError = null,
    updateListingError = null,
    showListingsError = null,

    publishListingError = null,
    uploadImageError = null,
    fetchExceptionsError = null,
    addExceptionError = null,
    deleteExceptionError = null,
  } = props?.page;

  function _commonError(condition, key) {
    return condition ? (
      <p className="error">
        {' '}
        <FormattedMessage id={`EditListingDescriptionForm.${key}`} />
      </p>
    ) : null;
  }
  const errorMessageUpdateListing = _commonError(updateListingError, 'updateFailed');
  const errorMessageCreateListingDraft = _commonError(
    createListingDraftError,
    'createListingDraftError'
  );
  const errorMessageShowListing = _commonError(showListingsError, 'showListingFailed');

  const STEPS_TITLE_ACCORDING = useMemo(
    () => [
      {
        step: 1,
        component: otherProps => <ProFirstStepFile {...otherProps} />,
        title: 'Personal information',
        image: require('../../../assets/woman.jpg'),
        para: "We need this information to approve your profile. This won't be public",
      },
      {
        step: 2,
        component: otherProps => <ProSecondStepFile {...otherProps} />,
        title: 'Create your profile',
        image: require('../../assest/profile.webp'),
        para:
          'Share information about yourself that will excite brands! You can edit this information via settings at any time.',
      },
      {
        step: 3,
        component: otherProps => <ProThirdStepFile {...otherProps} />,
        title: 'What do you want to sell?',
        image: require('../../assest/Woman-with-phone.webp'),
        para:
          "Let us guide you in creating your first listing! Select your first service offering. Don't worry, you will be able to fully customise this section, add or delete listings once your profile is approved.",
      },
      {
        step: 4,
        component: otherProps => (
          <ProFourthStepFile {...otherProps} onProductType={setProductType} />
        ),
        title: 'Almost done! Setup your first listing',
        image: productType === 'services' ? require('../../assest/services.webp') : null,
        // : productType === 'digital'
        // ? require('../../assest/digital-product.webp')
        // : productType === 'product'
        // ? require('../../assest/product.webp')
        // : require('../../assest/services.webp'),
        para:
          'Create your first listing to complete your profile. You can add additional services to your profile later.',
      },
    ],
    [productType]
  );

  const {
    step,
    _submitProForm,
    _handleBack,
    _handleNext,
    isLastStep,
    STEPS,
    proValidationSchema,
    INITIAL_VALUES,
  } = data;
  const CURRENT_TITLE_OBJECT = _.find(STEPS_TITLE_ACCORDING, { step: step + 1 });

  return (
    <ProStepperCss>
      <div className="steps_main_container">
        <section className="first_container">
          <div className="title_section_css">
            <h1>{CURRENT_TITLE_OBJECT?.title}</h1>
            <p>{CURRENT_TITLE_OBJECT?.para}</p>
          </div>

          <div className="stepper">
            <Stepper
              className="stepper-container"
              steps={STEPS.map(({ title }) => ({ label: title }))}
              activeStep={step}
              stepClassName="steps"
              connectorStateColors={true}
              connectorStyleConfig={{ completedColor: '#dd58d6', activeColor: '#dd58d6' }}
            />
          </div>
        </section>
        <div className="secondContainer">
          <div className="mainProFirst_container">
            <Formik
              initialValues={INITIAL_VALUES[step]}
              validationSchema={proValidationSchema[step] ? proValidationSchema[step] : {}}
              onSubmit={(values, action) => _submitProForm(values, action)}
            >
              {({ isSubmitting, ...otherFieldValue }) => (
                <>
                  <Form id={'formId' + (step + 1)}>
                    {CURRENT_TITLE_OBJECT?.component({ ...otherFieldValue, ...data })}
                    <div className={`actionButtons `}>
                      {step !== 0 && (
                        <button
                          type="button"
                          style={{ margin: '0 auto' }}
                          onClick={_handleBack}
                          className="backbutton"
                        >
                          <img src={arrow_left} alt="back" />
                          <span>Back</span>
                        </button>
                      )}

                      {step !== 2 ? (
                        <button
                          disabled={isSubmitting || uploadInProgress}
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{ margin: '0 auto' }}
                          className="nextbutton"
                        >
                          <span>{isLastStep ? 'Start earning money' : 'Next'}</span>
                          <img src={arrow_right_light} alt="next" />
                        </button>
                      ) : (
                        <button
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{ opacity: 0 }}
                          disabled
                          className=""
                        >
                          Cannot next
                        </button>
                      )}
                    </div>
                  </Form>
                  {errorMessageCreateListingDraft}
                  {errorMessageUpdateListing}
                  {errorMessageShowListing}
                </>
              )}
            </Formik>
          </div>
          <div className="imageSection">
            <img src={CURRENT_TITLE_OBJECT?.image} alt="stepperimage" />
            {step === 0 && (
              <div className="imageContent">
                <div>
                  <h3>Collaborate with multiple brands at once</h3>
                  <span>Manage all your clients, campaigns and payments - all in one place</span>
                </div>
              </div>
            )}
            {step === 2 && (
              <div className="imageContent">
                <div>
                  <h3>Collaborate with multiple brands at once</h3>
                  <span>Manage all your campaigns, clients and payments - all in one place!</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ProStepperCss>
  );
};

export default ProStepperPage;
