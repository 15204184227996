import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import css from './ModalV2Base.module.css';

const ModalV2Base = ({ isOpen, width, renderContent, padding }) => {
  const modalRoot = document.getElementById('portal-root');

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className={css.modalOverlay}>
      <div className={css.modal} style={{ width, padding }}>
        {renderContent()}
      </div>
    </div>,
    modalRoot
  );
};

ModalV2Base.defaultProps = {
  padding: 24,
};

ModalV2Base.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  width: PropTypes.number,
  renderContent: PropTypes.func.isRequired,
  padding: PropTypes.number,
};

export default ModalV2Base;
