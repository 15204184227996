import React from 'react';

import PropTypes from 'prop-types';

import css from './index.module.css';

import Chip from '../../../../../components/Chip/Chip';
import SimpleAvatar from '../../../../../components/SimpleAvatar/SimpleAvatar';
import { useHistory } from 'react-router-dom';

const ActiveOfferCard = ({ image, location, title, description, brandIcon, brandName }) => {
  const history = useHistory();

  return (
    <div className={css.card}>
      <div className={css.imageContainer}>
        <img className={css.image} src={image} />
        <div className={css.location}>{location}</div>
      </div>
      <div className={css.contentWrapper}>
        <div className={css.content}>
          <div className={css.title}>{title}</div>
          <div className={css.description}>{description}</div>
        </div>
        <div className={css.footer}>
          <div className={css.brand}>
            <SimpleAvatar imageUrl={brandIcon} />
            {brandName}
          </div>
          <button className={css.applyButton} onClick={() => history.push('/login')}>
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

ActiveOfferCard.propTypes = {
  image: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  brandIcon: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
};

export default ActiveOfferCard;
