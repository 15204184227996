import React, { useState } from 'react';
import {
  Footer,
  LayoutSideNavigation,
  LayoutWrapperAccountSideNav,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Modal,
  Page,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from '../../util/reactIntl';

import message from './icon-messages.svg';
import calendar from './icon-calendar.svg';
import lightningIcon from './lightningIcon.svg';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import axios from 'axios';
import ServiceCard from './ServiceCardComponent/ServiceCardComponent';
import LoaderComponent from '../../components/LoaderComponent/LoaderComponent';
import ModalV2Standard from '../../components/ModalV2/ModalV2Standard';
import css from './CreatorServicesPage.module.css';

export const CreatorServicesPageComponent = ({ intl }) => {
  const title = 'Creator services | Collabreate';

  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user?.currentUser) || {};
  const email = currentUser.attributes?.email;
  const { firstName, lastName } = currentUser?.attributes?.profile || {};

  const [isModalOpen, setIsmodalOpen] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [isError, setIsError] = useState(false);

  const onManageDisableScrolling = (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling));

  const handleCloseModal = () => {
    setIsmodalOpen(false);
  };

  const errorMessage = intl.formatMessage({
    id: 'CreatorServicesPage.sendRequestError',
  });

  const handleSendRequest = serviceTitle => {
    setIsloading(true);
    setIsError(false);
    axios
      .post('https://api.pembukuan.nanaspos.masuk.id/slack/notify/text', {
        channel: 'creator-services-request',
        message: 'New service request from creator',
        blocks: [
          {
            type: 'header',
            text: {
              type: 'plain_text',
              text: `New request from creator (*${serviceTitle}*)`,
              emoji: true,
            },
          },
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: `Creator make *${serviceTitle}* request.`,
            },
          },
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: `First name: *${firstName}* \n Last name: *${lastName}* \n Email: *${email}*`,
            },
          },
        ],
      })
      .then(() => {
        setIsmodalOpen(true);
        setIsloading(false);
      })
      .catch(e => {
        setIsloading(false);
        setIsError(true);
      });
  };

  const services = [
    {
      id: 1,
      title: "Review of Social Media",
      subtitle: "I need help with growing my social media",
      info: "The Collabreate Team will review and strategize your social media media for high growth and monetization for both Instagram and TikTok",
      icon: message,
    },
    {
      id: 2,
      title: intl.formatMessage({
        id: 'CreatorServicesPage.coachingTitle',
      }),
      subtitle: "I need help with my blog content creation and business",
      info: "Our expert will assist you in your custom needs ranging from brand negotiations to providing ongoing strategy",
      icon: calendar,
    },
  ];

  return (
    <Page title={title} scrollingDisabled={false}>
      <LayoutSideNavigation containerClassName={css.sidebarContainer}>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="CreatorServicesPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSideNav
          currentTab="CreatorServicesPage"
          userProfile={true}
          isAvatar={true}
          isProUser={true}
        />
        <LayoutWrapperMain className={css.wrapper}>
          <h1 className={css.header}>
            {intl.formatMessage({
              id: 'CreatorServicesPage.title',
            })}
          </h1>
          <div className={css.subtitle}>
            {intl.formatMessage({
              id: 'CreatorServicesPage.subtitle',
            })}
          </div>
          <div className={css.serviceCardsContainer}>
            {services.map(({ id, title, subtitle, info, icon }) => (
              <ServiceCard
                key={id}
                title={title}
                subtitle={subtitle}
                info={info}
                icon={icon}
                isLoading={isLoading}
                handleSendRequest={handleSendRequest}
              />
            ))}
          </div>
          {isLoading && <LoaderComponent />}
          {isError && <p className={css.error}>{errorMessage}</p>}
          <ModalV2Standard
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            width={600}
            icon={<img src={lightningIcon} />}
          >
            <div>
              <p className={css.modalTitle}>
                {intl.formatMessage({
                  id: 'CreatorServicesPage.modalTitle',
                })}
              </p>
              <p className={css.modalSubTitle}>
                {intl.formatMessage({
                  id: 'CreatorServicesPage.modalSubTitle',
                })}
              </p>
            </div>
          </ModalV2Standard>
        </LayoutWrapperMain>
        {/* <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter> */}
      </LayoutSideNavigation>
    </Page>
  );
};

const CreatorServicesPage = injectIntl(CreatorServicesPageComponent);

export default CreatorServicesPage;
