import React from 'react';
import { compose } from 'redux';
import { injectIntl } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, FieldTextInput, FieldSelect, H1 } from '../../components';
import * as validators from '../../util/validators';

import css from './BookDemoForm.module.css';
import { PinkButton } from '../../components/Button/Button';

const BookDemoFormComponent = props => {
  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const { rootClassName, className, handleSubmit, inProgress, role } = fieldRenderProps;

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;

        const required = validators.required('This field is required');

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <H1 className={css.header}>Book a Demo</H1>
            <p className={css.subtitle}>
              Please fill out the form, and we'll have a chat with you as soon as possible!
            </p>
            <div className={css.inputs}>
              <FieldTextInput
                type="text"
                id="Name"
                name="Name"
                className={css.input}
                label="Full name"
                placeholder="Name"
                validate={required}
              />
              <FieldTextInput
                type="tel"
                id="Phone"
                name="Phone"
                className={css.input}
                label="Phone number"
                validate={required}
                placeholder="Number"
              />
              <FieldTextInput
                type="email"
                id="Email"
                name="Email"
                className={css.input}
                label="Business email"
                placeholder="Email"
                validate={required}
              />
              {role === 'client' ? (
                <>
                  <FieldTextInput
                    type="text"
                    id="Website"
                    name="Website"
                    className={css.input}
                    label="Company website"
                    placeholder="Website"
                    validate={required}
                  />
                  <FieldSelect
                    id="Type"
                    className={css.input}
                    name="Type"
                    label="Are you an Agency or brand?"
                    validate={required}
                  >
                    <option value="">Select</option>
                    <option value="agency">Agency</option>
                    <option value="brand">Brand</option>
                  </FieldSelect>
                </>
              ) : (
                <>
                  <FieldTextInput
                    type="text"
                    className={css.input}
                    id="Social-media-link"
                    name="Social-media-link"
                    label="Social media link"
                    placeholder="Link"
                    validate={required}
                  />
                  <FieldSelect
                    id="Style"
                    className={css.input}
                    name="Style"
                    label="What's your style?"
                    validate={required}
                  >
                    <option value="">Select</option>
                    <option value="UGC">UGC</option>
                    <option value="nano">Nano</option>
                  </FieldSelect>
                </>
              )}

              <FieldTextInput
                type="text"
                className={css.input}
                id="Hear_about"
                name="Hear_about"
                label="How did you hear about us?"
                placeholder="Type here"
              />
            </div>
            <div className={css.bottomWrapper}>
              <PinkButton type="submit" inProgress={submitInProgress} className={css.submitButton}>
                Submit
              </PinkButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

const BookDemoForm = compose(injectIntl)(BookDemoFormComponent);
BookDemoForm.displayName = 'BookDemoForm';

export default BookDemoForm;
