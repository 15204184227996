import { useEffect, useState } from 'react';
import { LayoutSideNavigation, LayoutWrapperAccountSideNav, LayoutWrapperMain, LayoutWrapperTopbar, Page } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './CaseStudyCreationPage.module.css';
import axiosInstance from '../../axios';
import Loading from '../../components/Loading/Loading';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function CaseStudyCreationPage({ currentUser }) {

  const history = useHistory();

  const [rawData, setRawData] = useState([]);
  const [caseStudyList, setCaseStudyList] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const filteredCaseStudy = rawData.filter((caseStudy) => caseStudy.title.toLowerCase().includes(searchKey.toLowerCase()));
    setCaseStudyList(filteredCaseStudy);
  }, [searchKey, rawData]);

  useEffect(() => {
    const fetchCaseStudies = async () => {
      try {
        setLoading(true);

        const request = await axiosInstance.get('/case-studies');
        if (request.data.error) {

        } else {
          setRawData(request.data.data);
        }
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false);
      }
    }

    fetchCaseStudies();
  }, [])

  return (
    <>
      <Page
        title={'Case Study Creation'}
        scrollingDisabled={false}
      >
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage={'CaseStudyCreationPage'} />
          </LayoutWrapperTopbar>
          <LayoutWrapperAccountSideNav
            currentTab={'CaseStudyCreationPage'}
            userProfile={true}
            isAvatar={true}
            currentUser={currentUser}
            isProUser={true}
          />
          <LayoutWrapperMain className={css.wrapperMain}>
            <h1>Case Study List</h1>
            <div>
              <div>Search Case Study by Title</div>
              <input type="text" value={searchKey} onChange={(e) => setSearchKey(e.target.value)} placeholder='search by title' />
            </div>
            <br />
            <div className={css.flex}>
              <div>{caseStudyList.length} case studies found!</div>
              <a href='/case-study/creation/new'><button className={css.btnCreate}>create new</button></a>
            </div>
            {loading ? (
              <div>
                <Loading />
              </div>
            ) : (
              <div>
                {
                  caseStudyList.map((caseStudy, index) => {
                    return (
                      <div key={index} className={css.caseCard} onClick={() => history.push('/case-study/creation/edit/' + caseStudy.id)}>
                        <div className={css.caseTitle}>{caseStudy.title}</div>
                        <p>{caseStudy.subtitle}</p>
                        <div className={`${css.statusPill} ${caseStudy.status === "published" ? css.statusPublished : css.statusDraft}`}>{caseStudy.status}</div>
                      </div>
                    );
                  })
                }
              </div>
            )
            }
          </LayoutWrapperMain>
        </LayoutSideNavigation>
      </Page>
    </>
  );
}