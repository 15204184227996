import { useState } from 'react';
import { LayoutSideNavigation, LayoutWrapperAccountSideNav, LayoutWrapperMain, LayoutWrapperTopbar, Page } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './CaseStudyCreationNewPage.module.css';
import axiosInstance from '../../axios';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function CaseStudyCreationNewPage ({ currentUser }) {

  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const [input, setInput] = useState({
    title: "",
    subtitle: "",
    hero_video_1: "",
    hero_video_2: "",
    clientName: "",
    clientImage: "",
    aboutText: "",
    about_image_1: "",
    about_image_2: "",
    clientCategory: "",
    clientLocation: "",
    clientWebsite: "",
    workObjective: "",
    workSolution: "",
    workOutcome: "",
    creatorsCount: 0,
    viewsCount: 0,
    likesCount: 0,
    sharesCount: 0,
    savedCount: 0,
    resultVideoLink1: "",
    resultVideoCreator1: "",
    resultVideoCreatorImage1: "",
    resultVideoLink2: "",
    resultVideoCreator2: "",
    resultVideoCreatorImage2: "",
    resultVideoLink3: "",
    resultVideoCreator3: "",
    resultVideoCreatorImage3: "",
    resultVideoLink4: "",
    resultVideoCreator4: "",
    resultVideoCreatorImage4: "",
  })

  const handleSubmit = async (status) => {
    try {
      setIsLoading(true);

      const response = await axiosInstance.post('/case-study/create', {
        status: status, // 'draft' or 'published
        title: input.title,
        subtitle: input.subtitle,
        client_name: input.clientName,
        client_image: input.clientImage,
        hero_videos: [input.hero_video_1, input.hero_video_2],
        about_text: input.aboutText,
        about_images: [input.about_image_1, input.about_image_2],
        about_chips: [
          { category: input.clientCategory },
          { location: input.clientLocation },
          { website: input.clientWebsite },
        ],
        work_texts: [
          { objective: input.workObjective },
          { solution: input.workSolution },
          { outcome: input.workOutcome },
        ],
        work_stats: [
          { creators: input.creatorsCount },
          { views: input.viewsCount },
          { likes: input.likesCount },
          { shares: input.sharesCount },
          { saved: input.savedCount },
        ],
        results: [
          {
            video_link: input.resultVideoLink1,
            creator: input.resultVideoCreator1,
            creator_image: input.resultVideoCreatorImage1,
          },
          {
            video_link: input.resultVideoLink2,
            creator: input.resultVideoCreator2,
            creator_image: input.resultVideoCreatorImage2,
          },
          {
            video_link: input.resultVideoLink3,
            creator: input.resultVideoCreator3,
            creator_image: input.resultVideoCreatorImage3,
          },
          {
            video_link: input.resultVideoLink4,
            creator: input.resultVideoCreator4,
            creator_image: input.resultVideoCreatorImage4,
          },
        ]
      });

      if (!response.data.error) {
        toast.success("New Case Study created successfully");
        history.push('/case-study/creation');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Page
        title={'Case Study Creation'}
        scrollingDisabled={false}
      >
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage={'CaseStudyCreationPage'} />
          </LayoutWrapperTopbar>
          <LayoutWrapperAccountSideNav
            currentTab={'CaseStudyCreationPage'}
            userProfile={true}
            isAvatar={true}
            currentUser={currentUser}
            isProUser={true}
          />
          <LayoutWrapperMain className={css.wrapperMain}>
            <div className={css.bg_dark}>
              <a href={'/case-study/creation'}>Go Back</a>
              <h1>Create New Case Study</h1>
              
              <div className={css.grouping}>
                <div className="">
                  <div>Title</div>
                  <input type="text" value={input.title} onChange={(e) => setInput({ ...input, title: e.target.value })} />
                </div>

                <div className="">
                  <div>Subtitle</div>
                  <textarea value={input.subtitle} onChange={(e) => setInput({ ...input, subtitle: e.target.value })}></textarea>
                </div>

                <div>
                  <div>Video Link 1</div>
                  <input type="text" value={input.hero_video_1} onChange={(e) => setInput({ ...input, hero_video_1: e.target.value })}/>
                </div>

                <div>
                  <div>Video Link 2</div>
                  <input type="text" value={input.hero_video_2} onChange={(e) => setInput({ ...input, hero_video_2: e.target.value })}/>
                </div>
              </div>

              <div className={css.grouping}>
                <div> 
                  <div>Client Name</div>
                  <input type="text" value={input.clientName} onChange={(e) => setInput({ ...input, clientName: e.target.value })} />
                </div>
                
                <div>
                  <div>Client Image</div>
                  <input type="text" value={input.clientImage} onChange={(e) => setInput({ ...input, clientImage: e.target.value })} />
                </div>

                <div className="">
                  <div>About Text</div>
                  <textarea value={input.aboutText} onChange={(e) => setInput({ ...input, aboutText: e.target.value })}></textarea>
                </div>

                <div>
                  <div>About Image Link 1</div>
                  <input type="text" value={input.about_image_1} onChange={(e) => setInput({ ...input, about_image_1: e.target.value })}/>
                </div>

                <div>
                  <div>About Image Link 2</div>
                  <input type="text" value={input.about_image_2} onChange={(e) => setInput({ ...input, about_image_2: e.target.value })}/>
                </div>

                <div>
                  <div>Client Category</div>
                  <input type='text' value={input.clientCategory} onChange={(e) => setInput({ ...input, clientCategory: e.target.value })} />
                </div>

                <div>
                  <div>Client Location</div>
                  <input type='text' value={input.clientLocation} onChange={(e) => setInput({ ...input, clientLocation: e.target.value })}/>
                </div>

                <div>
                  <div>Client Website</div>
                  <input type='text' value={input.clientWebsite} onChange={(e) => setInput({ ...input, clientWebsite: e.target.value })}/>
                </div>

              </div>

              <div className={css.grouping}>
                <h2>Work Section</h2>
                <div>
                  <div>Objective</div>
                  <textarea type='text' value={input.workObjective} onChange={(e) => setInput({ ...input, workObjective: e.target.value })}/>
                </div>

                <div>
                  <div>Solution</div>
                  <textarea type='text' value={input.workSolution} onChange={(e) => setInput({ ...input, workSolution: e.target.value })}/>
                </div>

                <div>
                  <div>Outcome</div>
                  <textarea type='text' value={input.workOutcome} onChange={(e) => setInput({ ...input, workOutcome: e.target.value })}/>
                </div>

                <h2>Stats Section</h2>
                <div>
                  <div>Number of Creators</div>
                  <input type='number' value={input.creatorsCount} onChange={(e) => setInput({ ...input, creatorsCount: e.target.value })}/>
                </div>

                <div>
                  <div>Number of Views</div>
                  <input type='number' value={input.viewsCount} onChange={(e) => setInput({ ...input, viewsCount: e.target.value })}/>
                </div>

                <div>
                  <div>Number of Likes</div>
                  <input type='number' value={input.likesCount} onChange={(e) => setInput({ ...input, likesCount: e.target.value })}/>
                </div>

                <div>
                  <div>Number of Shares</div>
                  <input type='number' value={input.sharesCount} onChange={(e) => setInput({ ...input, sharesCount: e.target.value })}/>
                </div>

                <div>
                  <div>Number of Saved</div>
                  <input type='number' value={input.savedCount} onChange={(e) => setInput({ ...input, savedCount: e.target.value })}/>
                </div>
              </div>

              <div className={css.grouping}>
                <h2>Video Result Section</h2>

                <div className={css.grouping}>
                  <div>
                    <div>Video Result Link 1</div>
                    <input type="text" value={input.resultVideoLink1} onChange={(e) => setInput({ ...input, resultVideoLink1: e.target.value})} />
                  </div>
                  <div>
                    <div>Creator 1 Name</div>
                    <input type="text" value={input.resultVideoCreator1} onChange={(e) => setInput({ ...input, resultVideoCreator1: e.target.value})} />
                  </div>
                  <div>
                    <div>Creator 1 Profile Image</div>
                    <input type="text" value={input.resultVideoCreatorImage1} onChange={(e) => setInput({ ...input, resultVideoCreatorImage1: e.target.value})} />
                  </div>
                </div>

                <div className={css.grouping}>
                  <div>
                    <div>Video Result Link 2</div>
                    <input type="text" value={input.resultVideoLink2} onChange={(e) => setInput({ ...input, resultVideoLink2: e.target.value})} />
                  </div>
                  <div>
                    <div>Creator 2 Name</div>
                    <input type="text" value={input.resultVideoCreator2} onChange={(e) => setInput({ ...input, resultVideoCreator2: e.target.value})} />
                  </div>
                  <div>
                    <div>Creator 2 Profile Image</div>
                    <input type="text" value={input.resultVideoCreatorImage2} onChange={(e) => setInput({ ...input, resultVideoCreatorImage2: e.target.value})} />
                  </div>
                </div>

                <div className={css.grouping}>
                  <div>
                    <div>Video Result Link 1</div>
                    <input type="text" value={input.resultVideoLink3} onChange={(e) => setInput({ ...input, resultVideoLink3: e.target.value})} />
                  </div>
                  <div>
                    <div>Creator 3 Name</div>
                    <input type="text" value={input.resultVideoCreator3} onChange={(e) => setInput({ ...input, resultVideoCreator3: e.target.value})} />
                  </div>
                  <div>
                    <div>Creator 3 Profile Image</div>
                    <input type="text" value={input.resultVideoCreatorImage3} onChange={(e) => setInput({ ...input, resultVideoCreatorImage3: e.target.value})} />
                  </div>
                </div>

                <div className={css.grouping}>
                  <div>
                    <div>Video Result Link 1</div>
                    <input type="text" value={input.resultVideoLink4} onChange={(e) => setInput({ ...input, resultVideoLink4: e.target.value})} />
                  </div>
                  <div>
                    <div>Creator 4 Name</div>
                    <input type="text" value={input.resultVideoCreator4} onChange={(e) => setInput({ ...input, resultVideoCreator4: e.target.value})} />
                  </div>
                  <div>
                    <div>Creator 4 Profile Image</div>
                    <input type="text" value={input.resultVideoCreatorImage4} onChange={(e) => setInput({ ...input, resultVideoCreatorImage4: e.target.value})} />
                  </div>
                </div>

              </div>

              <button 
                className={css.btnSubmitDraft}
                onClick={() => handleSubmit('draft')}
              >
                Save as Draft
              </button>
              <button 
                className={css.btnSubmitPublish}
                onClick={() => handleSubmit('published')}
              >
                Publish
              </button>

            </div>
          </LayoutWrapperMain>
        </LayoutSideNavigation>
      </Page>
    </>
  );
}