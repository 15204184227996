import React from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import IconInfo from '../IconInfo/IconInfo';
import ConnectButtons from './ConnectButtons';
import css from './CreatorAnalyticsNotice.module.css';
import InstagramAccountsModal from './InstagramAccountsModal';
import useCreatorPrivateView from './useCreatorPrivateView';

const ConnectNotice = ({ creator, currentUser }) => {
  const { connectedInstagram, connectedTikTok } = useCreatorPrivateView({ creator, currentUser });

  if (connectedInstagram && connectedTikTok) {
    return null;
  }

  return (
    <div className={css.notice}>
      <div className={css.leading}>
        <IconInfo className={css.infoIcon} />
        <div>
          Connect your social profiles for better campaign opportunities, allowing clients to view
          your analytics.
        </div>
      </div>
      <div className={css.trailing}>
        <div className={css.divider} />
        <ConnectButtons creator={creator} currentUser={currentUser} />
      </div>
    </div>
  );
};

const CreatorAnalyticsNoticeComponent = ({ creator, currentUser, onManageDisableScrolling }) => {
  const { isPrivateView } = useCreatorPrivateView({ creator, currentUser });
  if (!isPrivateView) {
    return null;
  }

  return (
    <>
      <ConnectNotice creator={creator} currentUser={currentUser} />
      <InstagramAccountsModal
        creator={creator}
        currentUser={currentUser}
        onManageDisableScrolling={onManageDisableScrolling}
      />
    </>
  );
};

CreatorAnalyticsNoticeComponent.defaultProps = {
  creator: null,
  currentUser: null,
};

CreatorAnalyticsNoticeComponent.propTypes = {
  creator: propTypes.user,
  currentUser: propTypes.currentUser,
  onManageDisableScrolling: propTypes.func,
  // from injectIntl
  intl: intlShape.isRequired,
};

const CreatorAnalyticsNotice = injectIntl(CreatorAnalyticsNoticeComponent);

export default CreatorAnalyticsNotice;
