import React from 'react';
import { FormattedMessage } from 'react-intl';
import WelcomeProLedStyle from '../styles/WelcomeProLed.style';
import parse from 'html-react-parser';

const WelcomProLed = props => {
  const { _handleGetStarted, section } = props;

  const PRO_LISTING_DATA = [
    {
      image: require('../assest/WelcomeLedImage1.webp'),
      heading: 'Create your Profile',
      para: 'Tell us about yourself, link your socials and showcase your creative portfolio.',
    },
    {
      image: require('../assest/WelcomeLedImage2.webp'),
      heading: 'List your Services',
      para:
        "Whether its a single video, curated package or creator service - list as many offerings as you like, the sky's the limit!",
    },
    {
      image: require('../assest/WelcomeLedImage3.webp'),
      heading: 'Deliver and Get Paid!',
      para:
        "You will be notified when a brand reaches out to you with a brief. Get discovered or apply to campaigns. You will be notified if you're a good fit! Upon successful delivery of content, payments will be released into your account.",
    },
  ];

  const LISTING_DATA = [
    {
      image: require('../assest/WelcomeLedImage1.webp'),
      heading: 'Create Your Profile',
      para: 'Tell us about yourself and your role in the company.',
    },
    {
      image: require('../assest/WelcomeLedImage2.webp'),
      heading: 'Match with Creators',
      para:
        'Browse our Creator Marketplace and connect with creators who fit your brand and campaign requirements.',
    },
    {
      image: require('../assest/WelcomeLedImage3.webp'),
      heading: 'Receive Your Content',
      para:
        'Get notified once the creator has completed the deliverable, and receive quality content. Upon approval of content, payments will be sent to the creator.',
    },
  ];

  return (
    <WelcomeProLedStyle className="MainWelcomeLed_Class">
      <section className="LedFirstSection commonWidth">
        <h1 className="LedFirstSection_header">Welcome to Collabreate!</h1>
        {section === 'learner' ? (
          <p>Create your personal profile and start connecting with creators!</p>
        ) : (
          <p>
            Get started by creating your profile, telling us more about yourself and listing your
            first offering!
          </p>
        )}
      </section>
      <section className="LedSecondSection">
        <div className="listing_section">
          {_.map(section === 'learner' ? LISTING_DATA : PRO_LISTING_DATA, (r, index) => (
            <div className="listing_container" key={'listing' + index}>
              <div className="listing_image">
                <img src={r?.image} alt="listingImage" />
              </div>
              <div className="text_Container">
                <h3>{r?.heading}</h3>
                {parse(`<p>${r?.para}</p>`)}
              </div>
            </div>
          ))}
        </div>
        <div className="buttonSection">
          <button className="pinkButton" type="button" onClick={() => _handleGetStarted()}>
            Get started
          </button>
          <p>It will take you {section === 'learner' ? 5 : 5} minutes</p>
        </div>
      </section>
    </WelcomeProLedStyle>
  );
};

export default WelcomProLed;
