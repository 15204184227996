import axios from 'axios';

import Cookies from 'js-cookie';

const CLIENT_ID = process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID;

const stToken = Cookies.get(`st-${CLIENT_ID}-token`);

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api`,
  headers: {
    st: stToken,
  },
});

export default axiosInstance;
