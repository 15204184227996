import Cookies from 'js-cookie';
import { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';

const parse = message => {
  if (
    !message ||
    !message.content ||
    !message.type ||
    ['info', 'error', 'success', 'warning'].indexOf(message.type) === -1
  ) {
    return null;
  }

  return {
    type: message.type,
    content: message.content,
  };
};

const pullFlashMessages = () => {
  try {
    const flash = Cookies.get('flash') || '[]';
    const parsed = JSON.parse(flash);
    if (Array.isArray(parsed)) {
      return parsed.map(parse).filter(m => m);
    }
  } finally {
    Cookies.remove('flash');
  }

  return [];
};

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const FlashContainer = () => {
  useEffect(() => {
    const process = async () => {
      const messages = pullFlashMessages();
      if (messages.length === 0) {
        return;
      }

      await sleep(1000); // wait for page to finish loading

      for (const message of messages) {
        toast[message.type](message.content, { duration: 5000 });
        await sleep(400);
      }
    };
    process();
  });

  return (
    <div>
      <Toaster position="top-right" containerStyle={{ zIndex: 999999 }} />
    </div>
  );
};

export default FlashContainer;
