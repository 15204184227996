import React from 'react';

import css from './index.module.css';

import Chip from '../../../../../components/Chip/Chip';

import collabreateCard from '../../../../../assets/become-pro/collabreate-card.webp';
import fireIcon from '../../../../../assets/icons/fire.svg';

const CollabreateCard = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.content}>
        <Chip
          borderColor="#F3F4E4"
          backgroundColor="#F3F4E4"
          textColor="#292929"
          padding="4px 12px 4px 10px"
          mobilePadding="4px 10px 4px 8px"
        >
          <img src={fireIcon} />
          Coming soon
        </Chip>
        <div className={css.title}>Collabreate Card</div>
        <div className={css.description}>
          Discover over 100+ exclusive deals curated for content creators across various Shops, F&B
          and Entertainment outlets. Build and nurture relationships with your favourite brands and
          get invited to exclusive events and collaboration opportunities.
        </div>
      </div>
      <div className={css.imageContainer}>
        <img className={css.image} src={collabreateCard} alt="collabreate-card" />
      </div>
    </div>
  );
};

export default CollabreateCard;
