import axios from 'axios';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { IconArrowHead } from '../../components';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import CreatorAnalyticsEmptyView from './CreatorAnalyticsEmptyView';
import css from './CreatorAnalyticsSection.module.css';
import { getCountryName } from './countries';
import useCreatorPrivateView from './useCreatorPrivateView';
import { getPlatformName } from './utils';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const labelsMarginPlugin = {
  beforeInit: function(chart) {
    const originalFit = chart.legend.fit;
    chart.legend.fit = function fit() {
      originalFit.bind(chart.legend)();
      this.height += 16;
    };
  },
};

const options = {
  responsive: true,
  type: 'bar',
  plugins: {
    legend: {
      position: 'top',
      align: 'start',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
        boxWidth: 5,
        boxHeight: 5,
      },
    },
    title: { display: false },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      min: 0,
    },
  },
};

const formatNumber = number => {
  return number ? number.toLocaleString() : '-';
};

const formatRate = rate => {
  if (rate === undefined || rate === null) {
    return '';
  }

  return `${Math.floor(rate * 100)}%`;
};

const TrendingDownIcon = () => (
  <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em">
    <path d="M14 9.586l-4 4-6.293-6.293-1.414 1.414L10 16.414l4-4 4.293 4.293L16 19h6v-6l-2.293 2.293z" />
  </svg>
);

const TrendingUpIcon = () => (
  <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em">
    <path d="M10 10.414l4 4 5.707-5.707L22 11V5h-6l2.293 2.293L14 11.586l-4-4-7.707 7.707 1.414 1.414z" />
  </svg>
);

const BaseCard = ({ title, cssClass, children }) => {
  return (
    <div className={`${css.card} ${cssClass || ''}`}>
      <div className={css.cardTitle}>{title}</div>
      {children}
    </div>
  );
};

const REGIONS_PER_PAGE = 6;
const RegionsCard = ({ countries }) => {
  const [page, setPage] = useState(1);
  const data = useMemo(() => {
    return countries.slice((page - 1) * REGIONS_PER_PAGE, page * REGIONS_PER_PAGE);
  }, [countries, page]);

  return (
    <BaseCard
      title={
        <div className={css.regionCardTitle}>
          <div>Region Followers</div>
          <div className={css.pageSelector}>
            <button
              onClick={() => setPage(page - 1)}
              disabled={page === 1}
              className={css.pageButton}
            >
              <IconArrowHead direction="left" size="small" className={css.pageArrow} />
            </button>
            <button
              onClick={() => setPage(page + 1)}
              disabled={page * REGIONS_PER_PAGE >= countries.length}
              className={css.pageButton}
            >
              <IconArrowHead direction="right" size="small" className={css.pageArrow} />
            </button>
          </div>
        </div>
      }
      cssClass={css.regionCard}
    >
      {data.map(({ country, percentage }) => (
        <div className={css.regionRow} key={country}>
          <div className={css.countryRow}>
            <div>{getCountryName(country)}</div>
            <div>{Math.floor(percentage * 100) < 1 ? '<1%' : formatRate(percentage)}</div>
          </div>
          <div className={css.progressBar}>
            <div className={css.progressBarFilled} style={{ width: formatRate(percentage) }}>
              &nbsp;
            </div>
          </div>
        </div>
      ))}
    </BaseCard>
  );
};

const CreatorAnalyticsSectionComponent = ({ creator, currentUser, platform }) => {
  const [days, setDays] = useState(7);
  const [data, setData] = useState(null);
  const { isPlatformConnected } = useCreatorPrivateView({ creator, currentUser });

  const creatorId = creator?.id?.uuid;
  useEffect(() => {
    const controller = new AbortController();

    const fetchData = async () => {
      const response = await axios.get(
        `/api/creator/${creatorId}/analytics/${platform}?days=${days}`,
        {
          signal: controller.signal,
        }
      );
      setData(response.data.data);
    };

    if (creatorId) {
      fetchData();
    }

    return () => {
      controller.abort();
    };
  }, [creatorId, platform, days]);

  const countries = useMemo(() => {
    const countries = data?.overview?.audienceCountries || [];
    return countries.sort((a, b) => b.percentage - a.percentage);
  }, [data]);

  const graphData = useMemo(() => {
    const ret = (data?.daily || []).sort((a, b) => a.day.localeCompare(b.day));

    if (ret.length === 0) {
      return {
        labels: [],
        datasets: [],
      };
    }

    const dataByDay = ret.reduce((acc, d) => {
      acc[d.day] = d;
      return acc;
    }, {});

    const graphKeys = ['likes', 'comments', 'shares'];

    const firstDay = moment(ret[0].day);
    const lastDay = moment(ret[ret.length - 1].day);

    const labels = [];
    const datasets = {};
    for (let day = firstDay.clone(); day.isSameOrBefore(lastDay); day.add(1, 'day')) {
      labels.push(day.format('MMM D'));
      graphKeys.forEach(key => {
        if (!datasets[key]) {
          datasets[key] = [];
        }

        const d = dataByDay[day.format('YYYY-MM-DD')];
        datasets[key].push(d ? d[key] : 0);
      });
    }

    const tension = 0.5;
    const pointRadius = 5;
    const pointStyle = false;

    return {
      labels,
      datasets: [
        {
          label: 'Likes',
          data: datasets.likes,
          borderColor: '#DD58D6',
          backgroundColor: '#DD58D6',
          tension,
          pointRadius,
          pointStyle,
        },
        {
          label: 'Shares',
          data: datasets.shares,
          borderColor: '#FF810A',
          backgroundColor: '#FF810A',
          tension,
          pointRadius,
          pointStyle,
        },
        {
          label: 'Comments',
          data: datasets.comments,
          borderColor: '#664E72',
          backgroundColor: '#664E72',
          tension,
          pointRadius,
          pointStyle,
        },
      ],
    };
  }, [data]);

  const ValueCard = ({ title, id, cssClass }) => {
    const changeRate = data?.changeRate?.[id];

    return (
      <BaseCard title={title} cssClass={cssClass}>
        <div className={css.cardValue}>
          <div className={css.absoluteValue}>{formatNumber(data?.aggregates?.[id])}</div>
          <div className={css.changeRate}>
            {formatRate(changeRate)}
            {changeRate > 0 && <TrendingUpIcon />}
            {changeRate < 0 && <TrendingDownIcon />}
          </div>
        </div>
      </BaseCard>
    );
  };

  if (!isPlatformConnected(platform)) {
    return (
      <section className={css.wrapper}>
        <div className={css.header}>
          <div className={css.title}>{getPlatformName(platform)}</div>
        </div>
        <div className={css.emptyRow}>
          <CreatorAnalyticsEmptyView
            creator={creator}
            currentUser={currentUser}
            platform={platform}
          />
        </div>
      </section>
    );
  }

  return (
    <section className={css.wrapper}>
      <div className={css.header}>
        <div className={css.title}>{getPlatformName(platform)}</div>
        <div className={css.filters}>
          <div className={css.filterTitle}>Time Period</div>
          <div className={css.divider}></div>
          <select
            value={days}
            onChange={e => setDays(e.target.value)}
            className={css.periodSelector}
          >
            <option value="7">7 days</option>
            <option value="30">30 days</option>
          </select>
        </div>
      </div>

      <div className={css.row}>
        <ValueCard title="Net Followers Gained" id="followersGained" cssClass={css.followersCard} />
        <ValueCard title="Likes" id="likes" />
        <ValueCard title="Comments" id="comments" />
        <ValueCard title="Shares" id="shares" />
      </div>

      <div className={css.row}>
        <RegionsCard countries={countries} />
        <BaseCard title="Engagement" cssClass={css.channelCard}>
          <Line options={options} data={graphData} plugins={[labelsMarginPlugin]} />
        </BaseCard>
      </div>
    </section>
  );
};

CreatorAnalyticsSectionComponent.defaultProps = {
  creator: null,
  currentUser: null,
  platform: null,
};

CreatorAnalyticsSectionComponent.propTypes = {
  platform: propTypes.platform,
  creator: propTypes.user,
  currentUser: propTypes.currentUser,

  // from injectIntl
  intl: intlShape.isRequired,
};

const CreatorAnalyticsSection = injectIntl(CreatorAnalyticsSectionComponent);

export default CreatorAnalyticsSection;
