import { fetchCurrentUser } from '../../ducks/user.duck';

// ================ Action types ================ //
export const SET_INITIAL_STATE = 'app/PartnerDealsPage/SET_INITIAL_STATE';
export const GET_USER_REQUEST = 'app/PartnerDealsPage/GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'app/PartnerDealsPage/GET_USER_SUCCESS';
export const GET_USER_ERROR = 'app/PartnerDealsPage/GET_USER_ERROR';

// ================ Reducer ================ //
const initialState = {};

export default function PartnerDealsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

// ================ Thunks ================ //
export const loadData = () => (dispatch, getState, sdk) => {
  dispatch(setInitialState());

  return Promise.all([dispatch(fetchCurrentUser())]);
};
