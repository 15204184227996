import { useEffect, useState } from 'react';
import { Menu, MenuContent, MenuItem, MenuLabel } from '../../../components';
import { GrayButton, PinkButton } from '../../../components/Button/Button';
import { filters } from '../../../marketplace-custom-config';
import { format, getQueryParamName, reverseFormat } from '../Filter.helpers';
import ArrowIcon from './ArrowIcon';
import css from './Filter.module.css';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const options = [
  {
    label: 'Singapore',
    key: 'SG',
  },
  {
    label: 'Malaysia',
    key: 'MY',
  },
  {
    label: 'Indonesia',
    key: 'ID',
  },
  {
    label: 'Philippines',
    key: 'PH',
  },
  {
    label: 'Thailand',
    key: 'TH',
  },
  {
    label: 'Hong Kong',
    key: 'HK',
  },
  {
    label: 'Taiwan',
    key: 'TW',
  },
  {
    label: 'Vietnam',
    key: 'VN',
  },
  {
    label: 'Australia',
    key: 'AU',
  },
  {
    label: 'Korea',
    key: 'KR',
  },
  {
    label: 'Japan',
    key: 'JP',
  },
  {
    label: 'Myanmar',
    key: 'MM',
  },
  {
    label: 'Brunei',
    key: 'BN',
  },
];

const CountryFilter = ({ urlQueryParams, onSubmit, history }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState([]);
  const filter = filters.find(({ id }) => id === 'country');
  const searchMode = filter.config.searchMode;
  const queryParamName = getQueryParamName(filter.queryParamNames);

  const handleSelect = key => {
    if (!values.includes(key)) {
      setValues([...values, key]);
    } else {
      setValues(values.filter(k => k !== key));
    }
  };

  const handleSubmit = () => {
    const usedValue = values;
    const formatted = format(usedValue, queryParamName, searchMode);
    onSubmit(formatted);
    setOpen(false);
  };

  useEffect(() => {
    const { selectedOptions } = reverseFormat(urlQueryParams, queryParamName);
    setValues(selectedOptions);
  }, [urlQueryParams, queryParamName]);

  const isSelected = history?.location?.search?.includes('pub_nationality');

  return (
    <Menu
      className={css.filterContainer}
      useArrow={false}
      onToggleActive={values => setOpen(values)}
      isOpen={open}
    >
      <MenuLabel className={classNames(css.filterLabel, isSelected && css.active)}>
        <span>Country</span>
        <ArrowIcon />
      </MenuLabel>
      <MenuContent key="activities" className={css.filterContent}>
        <MenuItem key="items" className={css.filterItemContainer}>
          {options.map(option => (
            <span
              key={option.key}
              className={classNames(
                css.filterItem,
                values.includes(option.key) && css.filterItemActive
              )}
              onClick={() => handleSelect(option.key)}
            >
              {option.label}
            </span>
          ))}
        </MenuItem>
        <MenuItem key="actions" className={css.filterAction}>
          <button
            className={css.filterBtnApply}
            onClick={() => {
              setValues([]);
            }}
          >
            Clear All
          </button>
          <PinkButton className={css.filterBtnGray} onClick={handleSubmit}>
            Apply
          </PinkButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

export default CountryFilter;
