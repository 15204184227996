import React from 'react';
import css from './SwitcherOutlined.module.css';
import PropTypes from 'prop-types';

const SwitcherOutlined = ({ activeKey, setActiveKey, size, items }) => {
  const handleItemClick = key => {
    setActiveKey(key);
  };

  return (
    <div className={css.switcherContainer}>
      <div className={`${css.switcher} ${css[size]} `}>
        {items.map(item => (
          <div
            className={`
            ${css.switcherButton} 
            ${css[size]} 
            ${activeKey === item.key ? css.active : ''}
          `}
            key={item.label}
            onClick={() => handleItemClick(item.key)}
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
};

const { oneOf, arrayOf, object, bool, string } = PropTypes;

SwitcherOutlined.defaultProps = {
  activeKey: null,
  size: 'md',
  items: null,
};

SwitcherOutlined.propTypes = {
  activeKey: string,
  size: oneOf(['xs', 'sm', 'md', 'lg']),
  items: arrayOf(object),
};

export default SwitcherOutlined;
