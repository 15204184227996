import React from 'react';
import empty from '../../assets/empty.png';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import ConnectButtons from './ConnectButtons';
import css from './CreatorAnalyticsEmptyView.module.css';
import useCreatorPrivateView from './useCreatorPrivateView';
import { getPlatformName } from './utils';

const CreatorAnalyticsEmptyViewComponent = ({ creator, currentUser, platform }) => {
  const { isPrivateView } = useCreatorPrivateView({ creator, currentUser });

  const placeholder = platform ? `${getPlatformName(platform)} account` : 'social profiles';

  return (
    <div className={css.emptyView}>
      <img src={empty} className={css.emptyImage} alt="No data" />
      <div className={css.emptyViewHeader}>
        {platform ? `${getPlatformName(platform)} ` : ''} Analytics is not available.
      </div>
      <div className={css.emptyViewBody}>
        {isPrivateView
          ? `Connect your ${placeholder} to display analytics.`
          : `This creator has not connected their ${placeholder}.`}
      </div>
      <div className={css.emptyViewButtons}>
        <ConnectButtons creator={creator} currentUser={currentUser} />
      </div>
    </div>
  );
};

CreatorAnalyticsEmptyViewComponent.defaultProps = {
  creator: null,
  currentUser: null,
  platform: null,
};

CreatorAnalyticsEmptyViewComponent.propTypes = {

  creator: propTypes.user,
  currentUser: propTypes.currentUser,
  intl: intlShape.isRequired,
};

const CreatorAnalyticsEmptyView = injectIntl(CreatorAnalyticsEmptyViewComponent);

export default CreatorAnalyticsEmptyView;
