import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
  LayoutWrapperAccountSideNav,
  NamedLink,
} from '../../components';
import { PasswordChangeForm } from '../../forms';
import { TopbarContainer } from '../../containers';

import { changePassword, changePasswordClear } from './PasswordChangePage.duck';
import css from './PasswordChangePage.module.css';
import BackButton from '../../components/BackButton/BackButton';
import classNames from 'classnames';

export const PasswordChangePageComponent = props => {
  const {
    changePasswordError,
    changePasswordInProgress,
    currentUser,
    currentUserListing,
    onChange,
    onSubmitChangePassword,
    passwordChanged,
    scrollingDisabled,
    intl,
  } = props;
  const { isCreator } = currentUser?.attributes?.profile?.protectedData || {};

  const changePasswordForm =
    currentUser && currentUser.id ? (
      <PasswordChangeForm
        className={css.form}
        changePasswordError={changePasswordError}
        currentUser={currentUser}
        onSubmit={onSubmitChangePassword}
        onChange={onChange}
        inProgress={changePasswordInProgress}
        ready={passwordChanged}
      />
    ) : null;

  const title = intl.formatMessage({ id: 'PasswordChangePage.title' });

  return (
    <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation containerClassName={css.sidebarContainer}>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="PasswordChangePage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          {/*<UserNav selectedPageName="PasswordChangePage" />*/}
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSideNav
          currentTab="PasswordChangePage"
          userProfile={true}
          currentUser={currentUser}
          isProUser={false}
        />
        <LayoutWrapperMain className={css.wrapperMain}>
          <div className={css.content}>
            <h1 className={css.header}>Settings</h1>
            <div className={css.switcherContainer}>
              <div name="ContactDetailsPage" className={css.switcher}>
                <NamedLink name="ProfileSettingsPage" className={css.switcherButton}>
                  Edit profile
                </NamedLink>
                <NamedLink name="ContactDetailsPage" className={css.switcherButton}>
                  Contact details
                </NamedLink>
                <NamedLink
                  name="PasswordChangePage"
                  className={classNames(css.switcherButton, css.active)}
                >
                  Password
                </NamedLink>
                {currentUserListing && (
                  <NamedLink name="StripePayoutPage" className={css.switcherButton}>
                    Payout details
                  </NamedLink>
                )}
                {!isCreator && (
                  <NamedLink name="PaymentMethodsPage" className={css.switcherButton}>
                    Payment method
                  </NamedLink>
                )}
              </div>
            </div>
            <div className={css.subHeaderContainer}>
              <h2 className={css.subheader}>Password</h2>
              <div className={css.horizontalRule} />
            </div>
            {changePasswordForm}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

PasswordChangePageComponent.defaultProps = {
  changePasswordError: null,
  currentUser: null,
};

const { bool, func } = PropTypes;

PasswordChangePageComponent.propTypes = {
  changePasswordError: propTypes.error,
  changePasswordInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  onChange: func.isRequired,
  onSubmitChangePassword: func.isRequired,
  passwordChanged: bool.isRequired,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const {
    changePasswordError,
    changePasswordInProgress,
    passwordChanged,
  } = state.PasswordChangePage;
  const { currentUser, currentUserListing } = state.user;
  return {
    changePasswordError,
    changePasswordInProgress,
    currentUser,
    currentUserListing,
    passwordChanged,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: () => dispatch(changePasswordClear()),
  onSubmitChangePassword: values => dispatch(changePassword(values)),
});

const PasswordChangePage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(PasswordChangePageComponent);

export default PasswordChangePage;
