import React from 'react';

import css from './index.module.css';

import arrow_right from '../../../assets/become-pro/arrow-right.svg';
import { useHistory } from 'react-router-dom';

const CallToAction = () => {
  const history = useHistory();

  return (
    <div className={css.container}>
      <div className={css.cta}>
        <img className={css.image} alt="cta-background" />
        <div className={css.content}>
          <div className={css.title}>Join the Collabreate Community</div>
          <button className={css.btn_join} onClick={() => history.push('/login')}>
            Join
            <img src={arrow_right} alt="right arrow" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
