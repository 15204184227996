import React from 'react';
import { FormattedMessage, FormattedDate } from '../../util/reactIntl';
import moment from 'moment';
import { LINE_ITEM_NIGHT, DATE_TYPE_DATE, LINE_ITEM_HOUR, propTypes } from '../../util/types';
import { useHistory } from 'react-router-dom';

import css from './BookingBreakdown.module.css';
import { getDayOfWeek } from '../../util/dates';

const BookingPeriod = props => {
  const { startDate, endDate, dateType, timeZone } = props;

  const timeFormatOptions =
    dateType === DATE_TYPE_DATE
      ? {
          weekday: 'long',
        }
      : {
          weekday: 'short',
          hour: 'numeric',
          minute: 'numeric',
        };

  const dateFormatOptions = {
    month: 'short',
    day: 'numeric',
  };

  const timeZoneMaybe = timeZone ? { timeZone } : null;

  return (
    <>
      <div className={css.bookingPeriod}>
        <div className={css.bookingPeriodSection}>
          <div className={css.dayLabel}>
            <FormattedMessage id="BookingBreakdown.bookingStart" />
          </div>
          <div className={css.dayInfo}>
            <FormattedDate value={startDate} {...timeFormatOptions} {...timeZoneMaybe} />
          </div>
          <div className={css.itemLabel}>
            <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />
          </div>
        </div>

        <div className={css.bookingPeriodSectionRigth}>
          <div className={css.dayLabel}>
            <FormattedMessage id="BookingBreakdown.bookingEnd" />
          </div>
          <div className={css.dayInfo}>
            <FormattedDate value={endDate} {...timeFormatOptions} {...timeZoneMaybe} />
          </div>
          <div className={css.itemLabel}>
            <FormattedDate value={endDate} {...dateFormatOptions} {...timeZoneMaybe} />
          </div>
        </div>
      </div>
    </>
  );
};

const LineItemBookingPeriod = props => {
  const { booking, unitType, dateType, timeZone, bookingData } = props;

  const history = useHistory();

  // Attributes: displayStart and displayEnd can be used to differentiate shown time range
  // from actual start and end times used for availability reservation. It can help in situations
  // where there are preparation time needed between bookings.
  // Read more: https://www.sharetribe.com/api-reference/marketplace.html#bookings
  const { start, end, displayStart, displayEnd } = booking.attributes;
  const localStartDate = displayStart || start;
  const localEndDateRaw = displayEnd || end;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isUnit = unitType === LINE_ITEM_HOUR;
  const endDay =
    isUnit || isNightly ? localEndDateRaw : moment(localEndDateRaw).subtract(1, 'days');

  const dateFormatOptions = {
    month: 'short',
    day: 'numeric',
  };

  const timeZoneMaybe = timeZone ? { timeZone } : null;

  const timeFormatOptions = {
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric',
  };
  return (
    <>
      {(history?.location?.pathname.includes('checkout') ||
        history?.location?.pathname.includes('details')) && (
        <>
          <div className={css.oderDetailsTitle}>Order details</div>
          <div className={css.lineItem}>
            {/* <BookingPeriod
          startDate={localStartDate}
          endDate={endDay}
          dateType={dateType}
          timeZone={timeZone}
        /> */}
            <div className={css.bookingPeriod}>
              <div className={css.dayInfo}>Date</div>
              <div className={css.day}>
                <FormattedDate value={localStartDate} {...dateFormatOptions} {...timeZoneMaybe} />
                {localStartDate &&
                  endDay &&
                  getDayOfWeek(localStartDate, timeZone) !== getDayOfWeek(endDay, timeZone) && (
                    <>
                      -{' '}
                      <FormattedDate
                        value={localEndDateRaw}
                        {...dateFormatOptions}
                        {...timeZoneMaybe}
                      />
                    </>
                  )}
                {', '}
                <FormattedDate
                  value={localStartDate}
                  {...timeFormatOptions}
                  {...timeZoneMaybe}
                /> - <FormattedDate value={endDay} {...timeFormatOptions} {...timeZoneMaybe} />
              </div>
            </div>
          </div>

          {/* <div className={css.locationRow}>
            <div className={css.dayInfo}>Location</div>
            <div className={css.day}>
              {!bookingData.location
                ? "Pro's location"
                : bookingData.location === 'request_location'
                ? 'Requested'
                : 'Online'}
            </div>
          </div> */}
          {(bookingData?.location === 'Online' || bookingData?.location === 'request_location') && (
            <div className={css.locationRow}>
              <div className={css.dayInfo}>Requested location</div>
              <div>{bookingData.location_address}</div>
            </div>
          )}
        </>
      )}
      <hr className={css.totalDivider} />
    </>
  );
};
LineItemBookingPeriod.defaultProps = { dateType: null };

LineItemBookingPeriod.propTypes = {
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,
};

export default LineItemBookingPeriod;
