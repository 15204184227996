import React from 'react';
import css from './LoaderComponent.module.css'; // Import the CSS file for styling
import { IconSpinner } from '..';

const LoaderComponent = () => {
  return (
    <div className={css.loaderOverlay}>
      <div className="loader-content">
        <IconSpinner />
      </div>
    </div>
  );
};

export default LoaderComponent;
