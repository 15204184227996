import React from 'react';
import { injectIntl } from '../../../util/reactIntl';
import { PinkButton } from '../../../components/Button/Button';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ServiceCardComponent.module.css';

export const ServiceCardComponent = ({
  title,
  subtitle,
  info,
  icon,
  handleSendRequest,
  isLoading,
  intl,
}) => {
  return (
    <div className={css.serviceCard}>
      <img src={icon} alt="service icon" />
      <h2 className={css.cardHeader}>{title}</h2>
      <p className={css.cardSubHeader}>{subtitle}</p>
      <p className={classNames(css.cardText, css.firstCardText)}>{info}</p>
      <PinkButton
        className={css.requestButton}
        disabled={isLoading}
        onClick={() => {
          handleSendRequest(title);
        }}
      >
        {intl.formatMessage({
          id: 'CreatorServicesPage.requestButton',
        })}
      </PinkButton>
    </div>
  );
};

const { bool, func, string } = PropTypes;

ServiceCardComponent.propTypes = {
  title: string.isRequired,
  subtitle: string.isRequired,
  info: string.isRequired,
  icon: string.isRequired,
  handleSendRequest: func.isRequired,
  isLoading: bool,
};

const ServiceCard = injectIntl(ServiceCardComponent);

export default ServiceCard;
