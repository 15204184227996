import { FormattedMessage } from 'react-intl';
import { InputFieldComp } from './components/CommonComp/FieldComponent';
import CreatSingupStyle from './styles/CreatSingup.style';
import { Form, Formik } from 'formik';
import SocialMediaSignup from './components/SocialMediaSignup';
import * as Yup from 'yup';
import { NamedLink } from '../components';
import { useEffect } from 'react';
import MainSignupFlowStyle from './styles/MainSignupFlow.style';
import { useHistory } from 'react-router-dom';
import { PinkButton } from '../components/Button/Button';
import createSignupImage from './assest/createSignupImage.webp';

const MainLoginFlow = props => {
  const {
    intl,
    setLoginPage,
    tab,
    loginPage,
    authWithGoogle,
    authWithFacebook,
    submitLogin,
    loginError,
  } = props;
  const history = useHistory();

  function _intelMessage(message) {
    return intl.formatMessage({ id: message });
  }

  const passwordRecoveryLink = (
    <NamedLink name="PasswordRecoveryPage" className="">
      <FormattedMessage id="LoginForm.forgotPassword" />
    </NamedLink>
  );
  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  }, [loginPage]);

  function _intelMessage(message) {
    return intl.formatMessage({ id: message });
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .required(_intelMessage('LoginForm.emailRequired'))
      .email(_intelMessage('LoginForm.emailInvalid')),
    password: Yup.string()
      .trim()
      .required(_intelMessage('LoginForm.passwordRequired')),
  });

  return (
    <MainSignupFlowStyle>
      <CreatSingupStyle>
        <div className="MainCreateAccount_Class">
          <section className="createFirstSection commonWidth">
            <h1>Log In</h1>
            <div className="actionsButton">
              <SocialMediaSignup
                {...{
                  tab,
                  authWithGoogle,
                  authWithFacebook,
                }}
              />
            </div>
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{ email: '', password: '' }}
              onSubmit={(values, action) =>
                submitLogin(values, data =>
                  data && data.attributes.profile.protectedData.isCreator
                    ? props?.history.push(`/creator/${data.id.uuid}/listings`)
                    : props?.history.push('/campaigns')
                )
              }
            >
              {props => (
                <Form className="loginForm signupForm">
                  <div className="allInput_section fieldCommonCss">
                    <InputFieldComp
                      name="email"
                      label={_intelMessage('LoginForm.emailLabel')}
                      placeholder={_intelMessage('LoginForm.emailPlaceholder')}
                    />
                    <InputFieldComp
                      type="password"
                      name="password"
                      label={_intelMessage('LoginForm.passwordLabel')}
                      placeholder={_intelMessage('LoginForm.passwordPlaceholder')}
                    />
                    <div className="checkboxInput">
                      <label htmlFor="term">
                        <FormattedMessage
                          id="LoginForm.forgotPasswordInfo"
                          values={{ passwordRecoveryLink }}
                        />
                      </label>
                    </div>
                  </div>
                  {loginError?.data && (
                    <div className="error">
                      Oops! The username or password doesn't match our records. Please double-check
                      and try again. Forgot your password?{' '}
                      <NamedLink name="PasswordRecoveryPage" className="">
                        <FormattedMessage id="LoginForm.forgotPassword" />
                      </NamedLink>
                    </div>
                  )}

                  <div className="formsubmit">
                    <PinkButton type="submit">
                      <FormattedMessage id="LoginForm.logIn" />
                    </PinkButton>
                    <p>
                      Don’t have an account?{' '}
                      <a
                        onClick={() => {
                          setLoginPage(false);
                          history.push('/signup');
                        }}
                      >
                        {' '}
                        Sign Up here
                      </a>
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </section>
          <section className="createSecondSection imageSection">
            <img src={createSignupImage} alt="login image" />
            <div className="imageContent">
              <div>
                <h3>Teach and sell with ease</h3>
                <span>For freelancers, teachers, influencers. For everyone.</span>
              </div>
            </div>
          </section>
        </div>
      </CreatSingupStyle>
    </MainSignupFlowStyle>
  );
};

export default MainLoginFlow;
