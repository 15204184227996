import React, { useState } from 'react';
import { uploadFile } from '../../util/api';
import { BlackButton, PinkButton, SoftBlackButton } from '../Button/Button';
import IconSpinner from '../IconSpinner/IconSpinner';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { useDispatch } from 'react-redux';
import css from './FileUploader.module.css';

const FileUpload = ({ setShowResumeInput }) => {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const dispatch = useDispatch();

  const onUpdateProfile = data => dispatch(updateProfile(data));

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    setError(false);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', `${Date.now()}`);

    uploadFile(formData)
      .then(data => {
        setShowResumeInput(false);
        setIsLoading(false);
        onUpdateProfile({
          publicData: {
            resumeUrl: data.url,
          },
        });
      })
      .catch(err => {
        setIsLoading(false);
        setError(err);
      });
  }

  return (
    <div className={css.uploadModal}>
      <form onSubmit={handleSubmit} className={css.uploadForm}>
        <h2 className={css.header}>Upload your portfolio</h2>
        <input type="file" onChange={handleChange} />
        <div className={css.buttonContainer}>
          <PinkButton className={css.submitButton} type="submit" disabled={isLoading}>
            {isLoading ? <IconSpinner className={css.spinner} /> : 'Upload'}
          </PinkButton>
          <div>{error}</div>
        </div>
      </form>
    </div>
  );
};

export default FileUpload;
