import React, { Component, useMemo } from 'react';
import { InputFieldComp } from '../../components/CommonComp/FieldComponent';
import _ from 'lodash';
import { compose, withProps } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { isUploadImageOverLimitError } from '../../../util/errors';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import pink_profile from '../../../assets/pink-profile-pic.png'
import fire_logo from '../../../assets/signup/profile/fire.svg';
import clock_logo from '../../../assets/signup/profile/clock.svg';
import film_logo from '../../../assets/signup/profile/film.svg';
import star_logo from '../../../assets/signup/profile/star.svg';

const MyMapComponent = compose(
  withProps({
    /**
     * Note: create and replace your own key in the Google console.
     * https://console.developers.google.com/apis/dashboard
     * The key "AIzaSyBkNaAGLEVq0YLQMi-PYEMabFeREadYe1Q" can be ONLY used in this sandbox (no forked).
     */
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=pk.eyJ1IjoicHJvbGVhZGRldiIsImEiOiJjbGl3aXBzN3AwM24wM3Rwb2xtbjVmbG96In0.-vxQlWgstAtLEm-3VC_LqA&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap defaultZoom={8} defaultCenter={{ lat: -34.397, lng: 150.644 }}>
    {props.isMarkerShown && <Marker position={{ lat: -34.397, lng: 150.644 }} />}
  </GoogleMap>
));

const TimesIcon = props => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.03999 4.03999C4.29383 3.78615 4.70539 3.78615 4.95923 4.03999L8.99963 8.08039L13.04 4.04003C13.2938 3.78619 13.7054 3.78619 13.9592 4.04003C14.2131 4.29387 14.2131 4.70542 13.9592 4.95927L9.91887 8.99963L13.9592 13.04C14.213 13.2938 14.213 13.7054 13.9592 13.9592C13.7054 14.213 13.2938 14.213 13.04 13.9592L8.99963 9.91887L4.95927 13.9592C4.70543 14.2131 4.29387 14.2131 4.04003 13.9592C3.78619 13.7054 3.78619 13.2938 4.04003 13.04L8.08039 8.99963L4.03999 4.95923C3.78615 4.70539 3.78615 4.29383 4.03999 4.03999Z"
      fill="#959595"
    />
  </svg>
);

const RemoveButton = props => {
  const { onClick } = props;
  return (
    <button type="button" className="removeButton" onClick={onClick}>
      <svg
        width="10px"
        height="10px"
        viewBox="0 0 10 10"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g strokeWidth="1" fillRule="evenodd">
          <g transform="translate(-821.000000, -311.000000)">
            <g transform="translate(809.000000, 299.000000)">
              <path
                d="M21.5833333,16.5833333 L17.4166667,16.5833333 L17.4166667,12.4170833 C17.4166667,12.1866667 17.2391667,12 17.00875,12 C16.77875,12 16.5920833,12.18625 16.5920833,12.41625 L16.5883333,16.5833333 L12.4166667,16.5833333 C12.18625,16.5833333 12,16.7695833 12,17 C12,17.23 12.18625,17.4166667 12.4166667,17.4166667 L16.5875,17.4166667 L16.5833333,21.5829167 C16.5829167,21.8129167 16.7691667,21.9995833 16.9991667,22 L16.9995833,22 C17.2295833,22 17.41625,21.81375 17.4166667,21.58375 L17.4166667,17.4166667 L21.5833333,17.4166667 C21.8133333,17.4166667 22,17.23 22,17 C22,16.7695833 21.8133333,16.5833333 21.5833333,16.5833333"
                transform="translate(17.000000, 17.000000) rotate(-45.000000) translate(-17.000000, -17.000000) "
              />
            </g>
          </g>
        </g>
      </svg>
    </button>
  );
};

const ProFourthStepFile = props => {
  const {
    intl,
    errors,
    priceValues,
    setPriceValues,
    values,
    setCalenderAvablility,
    setFieldValue,
    setExceptionData,
    listingInputRef,
    _handleListingImageChange,
    handleClickListing,
    handleChange,
    errorsApis,
    imagesIdsTOUpload,
    locationArray,
    setLocationArray,
    selectedWhatSell,
    onProductType,
    selectedButton,
    listingImageUpload,
    _handleRemoveMedia,
    addOns,
    setAddOns,
    handleAddOnsAmout,
    handleNewAddons,
    handleAddonsLabel,
    handleAddonsRemove,
    addOnsError,
  } = props;
  const { uploadImageError } = errorsApis;
  const { uploadInProgress, uploadProgress } = useSelector(state => state.ProfileSettingsPage);
  const { images, videos } = useMemo(() => {
    const images = imagesIdsTOUpload.filter(({ id }) => typeof id === 'object');
    const videos = imagesIdsTOUpload.filter(({ id }) => typeof id === 'string');
    return { images, videos };
  }, [imagesIdsTOUpload]);

  // const productType = useMemo(() => {
  //   const services = SERVICES.find(({ value }) => value === selectedWhatSell);
  //   const digital = DigitalProduct.find(({ value }) => value === selectedWhatSell);
  //   const product = PRODUCT.find(({ value }) => value === selectedWhatSell);

  //   return services ? 'services' : digital ? 'digital' : product ? 'product' : '';
  // }, [selectedWhatSell]);

  const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

  let uploadImageFailed = null;

  if (uploadOverLimit) {
    uploadImageFailed = (
      <p className="" style={{ color: 'red', fontSize: '16px' }}>
        <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
      </p>
    );
  } else if (uploadImageError) {
    uploadImageFailed = (
      <p className="" style={{ color: 'red', fontSize: '16px' }}>
        <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
      </p>
    );
  }

  function _labelGetting(key) {
    return intl.formatMessage({ id: key });
  }

  function _sixPrecentage(price) {
    if (price) {
      const MATH = +price * (6 / 100);
      setFieldValue('pricing_receive', 'SGD ' + (+price - MATH));
    } else {
      setFieldValue('pricing_receive', '');
    }
  }
  const identity = v => v;
  const count = 1;

  return (
    <div className="proFourth_main">
      {/* <Accordion title={'Discription'} active={productType === 'services' ? false : true}> */}

      <div className="fieldWithLabel">
        <label htmlFor="">Listing Name</label>
        {selectedWhatSell !== 'Other' ? (
          <p>{selectedWhatSell}</p>
        ) : (
          <InputFieldComp name="discriptionTitle" placeholder="Listing name" />
        )}
      </div>
      {/* <div className="fieldWithLabel">
        <label htmlFor="">Category</label>
        <SelectFieldComp
          name="category"
          options={[
            { label: 'Fitness', value: 'fitness' },
            { label: 'Education', value: 'education' },
            { label: 'Creative', value: 'creative' },
            { label: 'Esports', value: 'esports' },
          ]}
          error={errors?.category}
        />
      </div> */}
      {/* {productType === 'services' && (
        <div className="fieldWithLabel">
          <label htmlFor="">What age groups are you specialise in</label>
          <SelectFieldComp
            name="ageCategory"
            options={[
              { label: 'Senior (55+ years)', value: 'senior' },
              { label: 'Adult (19 - 54 years)', value: 'adult' },
              { label: 'Teen (13 - 18 years)', value: 'teen' },
              { label: 'Kids (6-12 years)', value: 'kids' },
            ]}
            error={errors?.ageCategory}
          />
        </div>
      )} */}
      <div className="fieldWithLabel">
        <label htmlFor="">Description</label>
        <div className="normal_font">
          Generally we find that brands love to see your skills for each listing. Simply follow the below guidelines to be booked fast: 
        </div>
        <div className="logo_section">
          <div className="">
            <div className="logo_item_flex">
              <img src={fire_logo} alt="" />
              <div className="normal_font">Tell us what you are offering</div>
            </div>
            <div className="logo_item_flex">
              <img src={clock_logo} alt="" />
              <div className="normal_font">Experience Duration</div>
            </div>
          </div>
          <div className="">
            <div className="logo_item_flex">
              <img src={star_logo} alt="" />
              <div className="normal_font">Previous Impact metrics</div>
            </div>
            <div className="logo_item_flex">
              <img src={film_logo} alt="" />
              <div className="normal_font">Type of content</div>
            </div>
          </div>
        </div>
        <textarea
          name="description"
          onChange={handleChange}
          placeholder="Include any additional details helpful to buyers"
        />
        {errors?.description && <div className="error">{errors?.description}</div>}
      </div>
      {selectedWhatSell === 'Open to gifted collaborations' && (
        <div className="fieldWithLabel">
          <label htmlFor="">Shipping address</label>
          <InputFieldComp name="shippingAddress" placeholder="Your shipping address" />
        </div>
      )}
      {selectedWhatSell !== 'Open to gifted collaborations' && (
        <>
          <div className="fieldWithLabel pricingSection">
            <label htmlFor="">Price</label>
            <div className="para"></div>
            <div className="price_container">
              <div className="addOnsInput">
                <span className="addOnsCurrency">SGD</span>
                <InputFieldComp type="number" name="pricing_hourly" />
              </div>
              {/* <input
            type="text"
            name="pricing_hourly"
            placeholder="SGD 0.00"
            value={values?.pricing_hourly}
            autoComplete="off"
            onFocus={() => {
              if (priceValues?.new) {
                setFieldValue('pricing_hourly', _.replace(priceValues?.old, 'SGD', ''));
                setPriceValues(prv => ({ ...prv, new: _.replace(priceValues?.old, 'SGD', '') }));
                _sixPrecentage(null);
              }
            }}
            onChange={e => {
              setFieldValue('pricing_hourly', _.replace(e.target.value, 'SGD', ''));
              setPriceValues({
                old: _.replace(e.target.value, 'SGD', ''),
                new: _.replace(e.target.value, 'SGD', ''),
              });
              _sixPrecentage(e.target.value);
            }}
            onBlur={e => {
              if (!priceValues?.new) {
                setFieldValue('pricing_hourly', 'SGD ' + _.replace(priceValues?.old, 'SGD', ''));
                setPriceValues(prv => ({ ...prv, new: prv?.old }));
                _sixPrecentage(priceValues?.old);
              } else {
                setFieldValue('pricing_hourly', 'SGD ' + _.replace(priceValues?.old, 'SGD', ''));
              }
            }}
          /> */}
              {/* <p>{productType === 'services' ? 'Hourly rate' : 'Item price'}</p> */}
            </div>
            {/* <div className="price_container">
          <input
            type="text"
            value={values?.pricing_receive}
            readOnly
            name="pricing_receive"
            placeholder="SGD 0.00"
          />
          <p>You'll receive</p>
        </div> */}
          </div>

          <div className="fieldWithLabel">
            <label htmlFor="">Addons</label>
            <p className="addOnsDesc">
              List as many add ons for buyers to purchase with this listing. You can use our
              suggestions or add your custom add ons.{' '}
            </p>
            {addOns.map(value => (
              <div className="addOnsItem" key={value.id}>
                {value.default ? (
                  <label htmlFor="">{value.label}</label>
                ) : (
                  <input
                    type="text"
                    placeholder="Add ons name"
                    onChange={e => handleAddonsLabel(value, e)}
                  />
                )}

                <div className="addOnsInput">
                  <span className="currency">SGD</span>
                  <input
                    type="number"
                    placeholder="0.00"
                    onChange={e => handleAddOnsAmout(value, e)}
                  />
                  <TimesIcon onClick={() => handleAddonsRemove(value)} />
                </div>
              </div>
            ))}
            <button className="addOnsButton" onClick={handleNewAddons}>
              Add custom field
            </button>
            {addOnsError && <div className="error">{addOnsError}</div>}
          </div>
        </>
      )}
      <div className="fieldWithLabel photos_data">
        <label htmlFor="">Upload samples of your work</label>
        <p className="uploadPhotosLabel">
          Add up to 10 videos and/or photos for your listing showcasing your previous work
        </p>
        <div className="file_selection">
          {imagesIdsTOUpload?.length > 0 ? <p> {imagesIdsTOUpload?.length} file uploaded</p> : ''}
          <input
            type="file"
            ref={listingInputRef}
            id="upload-button"
            style={{ display: 'none' }}
            onChange={_handleListingImageChange}
          />
          <button type="button" onClick={handleClickListing} className="uploadPhotosButton">
            Upload
          </button>
        </div>
        {uploadProgress && (
          <span>{uploadProgress === 100 ? 'Finalizing...' : `${uploadProgress}%`}</span>
        )}

        {uploadImageFailed}
        {listingImageUpload?.errMsg && <div className="error">{listingImageUpload?.errMsg}</div>}
      </div>
      {videos.length > 0 && (
        <div className="fieldWithLabel">
          <label htmlFor="">Videos</label>
          <div className="mediaList">
            {videos.map((video, index) => (
              <div className="video" key={video.id}>
                <RemoveButton onClick={() => _handleRemoveMedia(video)} />
                <video height={150} controls>
                  <source src={video.urls[0]} />
                </video>
              </div>
            ))}
          </div>
        </div>
      )}
      {images.length > 0 && (
        <div className="fieldWithLabel">
          <label htmlFor="">Photos</label>
          <div className="mediaList">
            {images.map((image, index) => (
              <div className="image" key={image.id.uuid}>
                <RemoveButton onClick={() => _handleRemoveMedia(image)} />
                <img src={image.attributes.variants['square-small'].url} />
              </div>
            ))}
          </div>
        </div>
      )}

      {/* </Accordion> */}
      {/* {productType === 'services' && (
        <>
          <Accordion title={_labelGetting('EditListingWizard.tabLabelLocation')} active={false}>
            {_.map(
              [
                {
                  label: 'Allow users request location',
                  name: 'requestLocation',
                  para: 'You need to approve or dismiss the request after booking',
                },
                {
                  label: 'Online',
                  name: 'online',
                  para: 'The client will receive your session online',
                },
                { label: 'My location', name: 'myLocation' },
              ],
              r => (
                <div className="fieldWithLabel checkbox_forth" key={r?.name}>
                  <label>
                    <Field type="checkbox" name={r?.name} />
                    <p>{r?.label}</p>
                  </label>
                  <p>{r?.para}</p>
                </div>
              )
            )}
            {values?.myLocation && (
              <div className="fieldWithLabel location_section">
                <div style={{ marginBottom: '12px' }}>
                  {_.map(locationArray, (v, i) => (
                    <div key={v} className="autocompleteField">
                      <div className="" id="location">
                        <LocationAutocompleteInputImpl
                          {...{
                            section: 'signup',
                            placeholder: 'placeholder',
                            useDefaultPredictions: false,
                            input: {
                              onChange: data => setFieldValue(v, data),
                              value: values?.[v],
                              onBlur: data => setFieldValue(v, data),
                              onFocus: e => onFocus(e),
                            },
                          }}
                        />
                        <ErrorMessage name={v} className="error" style={{ fontSize: '16px' }} />
                      </div>
                      {i !== 0 && (
                        <div
                          className="deleteIcon"
                          onClick={() => {
                            const FilterData = _.filter(locationArray, val => val !== v);
                            // console.log('FilterData', FilterData);
                            setLocationArray(FilterData);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  ))}
                  {!(locationArray?.length === 3) && (
                    <p
                      onClick={() => {
                        setLocationArray([
                          ...locationArray,
                          `location${locationArray?.length + 1}`,
                        ]);
                        document.getElementById('location')?.scrollIntoView();
                      }}
                    >
                      Add one more address
                    </p>
                  )}
                </div>
              </div>
            )}
          </Accordion>

          <Accordion title={_labelGetting('EditListingWizard.tabLabelAvailability')} active={false}>
            <div className="calender_main">
              <lable>Set availability manually</lable>
              <MyCalendarPage
                {...{ section: 'proSignup', setCalenderAvablility, setExceptionData }}
              />
            </div>
          </Accordion>
        </>
      )} */}
      {/* {selectedWhatSell === 'Item Pick Up' && (
        <Accordion title={_labelGetting('EditListingWizard.tabLabelLocation')} active={false}>
          <div className="fieldWithLabel location_section">
            <div style={{ marginBottom: '12px' }}>
              {_.map(locationArray, (v, i) => (
                <div key={v} className="autocompleteField">
                  <div className="" id="location">
                    <LocationAutocompleteInputImpl
                      {...{
                        section: 'signup',
                        placeholder: 'placeholder',
                        useDefaultPredictions: false,
                        input: {
                          onChange: data => setFieldValue(v, data),
                          value: values?.[v],
                          onBlur: data => setFieldValue(v, data),
                          onFocus: e => onFocus(e),
                        },
                      }}
                    />
                    <ErrorMessage name={v} className="error" style={{ fontSize: '16px' }} />
                  </div>
                  {i !== 0 && (
                    <div
                      className="deleteIcon"
                      onClick={() => {
                        const FilterData = _.filter(locationArray, val => val !== v);
                        // console.log('FilterData', FilterData);
                        setLocationArray(FilterData);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              ))}
              {!(locationArray?.length === 3) && (
                <p
                  onClick={() => {
                    setLocationArray([...locationArray, `location${locationArray?.length + 1}`]);
                    document.getElementById('location')?.scrollIntoView();
                  }}
                >
                  Add one more address
                </p>
              )}
            </div>
          </div>
        </Accordion>
      )} */}
    </div>
  );
};

export default ProFourthStepFile;
