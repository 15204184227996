import React from 'react';

import css from './index.module.css';

import Chip from '../../../../../components/Chip/Chip';

import mentorship from '../../../../../assets/become-pro/mentorship.webp';
import fireIcon from '../../../../../assets/icons/fire.svg';

const Mentorship = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.content}>
        <Chip
          borderColor="#F3F4E4"
          backgroundColor="#F3F4E4"
          textColor="#292929"
          padding="4px 12px 4px 10px"
          mobilePadding="4px 10px 4px 8px"
        >
          <img src={fireIcon} />
          Coming soon
        </Chip>
        <div className={css.title}>Mentorship</div>
        <div className={css.description}>
          From social audits to 1:1 sessions, our network of professionals are here to help you
          enhance your profile and social media presence.
        </div>
      </div>
      <div className={css.imageContainer}>
        <img className={css.image} src={mentorship} alt="mentorship" />
      </div>
    </div>
  );
};

export default Mentorship;
