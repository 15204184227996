import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

// Import util modules
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { EXTENDED_DATA_SCHEMA_TYPES, propTypes } from '../../../../util/types';
import { maxLength, required, composeValidators } from '../../../../util/validators';

// Import shared components
import {
  Form,
  Button,
  FieldSelect,
  FieldTextInput,
  Heading,
  PrimaryButton,
  NamedLink,
} from '../../../../components';
// Import modules from this directory
import CustomExtendedDataField from '../CustomExtendedDataField';
import css from './EditListingDetailsForm.module.css';
import config from '../../../../config';

const TITLE_MAX_LENGTH = 60;

const identity = v => v;
export const activities = {
  fitness: [
    'Personal training',
    'Strength Training',
    'Functional Training',
    'Cardio',
    'Stretching',
    'Sports Recovery',
    'Other',
  ],
  sports: [
    'Combat sports',
    'Football',
    'Badminton',
    'Basketball',
    'Tennis',
    'Swimming',
    'Volleyball',
    'Table tennis',
    'Gymnastics',
    'Track & Field',
    'Other',
  ],

  creative: ['Musical instruments', 'Visual arts', 'Dance', 'Production', 'Music Theory', 'Other'],
  education: [
    'Academics',
    'Leadership',
    'Management',
    'Mentorship',
    'Languages',
    'Mathematics',
    'Sciences',
    'Humanities',
    'Leadership Coaching',
    'Professional Skills',
    'Other',
  ],
  esports: ['Valorant', 'Other'],
};

// Show various error messages
const ErrorMessage = props => {
  const { fetchErrors } = props;
  const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
  const errorMessage = updateListingError ? (
    <FormattedMessage id="EditListingDetailsForm.updateFailed" />
  ) : createListingDraftError ? (
    <FormattedMessage id="EditListingDetailsForm.createListingDraftError" />
  ) : showListingsError ? (
    <FormattedMessage id="EditListingDetailsForm.showListingFailed" />
  ) : null;

  if (errorMessage) {
    return <p className={css.error}>{errorMessage}</p>;
  }
  return null;
};

// Hidden input field
export const FieldHidden = props => {
  const { name } = props;
  return (
    <Field id={name} name={name} type="hidden" className={css.unitTypeHidden}>
      {fieldRenderProps => <input {...fieldRenderProps?.input} />}
    </Field>
  );
};

const listingTypes = [
  {
    label: 'test',
    listingType: 'test',
    transactionProcessAlias: 'default-purchase/release-1',
    unitType: 'item',
    listingTitile: 'Single Concept Video',
  },
  {
    label: 'test',
    listingType: 'test',
    transactionProcessAlias: 'default-purchase/release-1',
    unitType: 'item',
    listingTitile: 'Voiceover Video',
  },
  {
    label: 'test',
    listingType: 'test',
    transactionProcessAlias: 'default-purchase/release-1',
    unitType: 'item',
    listingTitile: 'Talking Head Video',
  },
  {
    label: 'test',
    listingType: 'test',
    transactionProcessAlias: 'default-purchase/release-1',
    unitType: 'item',
    listingTitile: 'Unboxing Video (Faceless)',
  },
  {
    label: 'test',
    listingType: 'test',
    transactionProcessAlias: 'default-purchase/release-1',
    unitType: 'item',
    listingTitile: 'Trending Audio Video',
  },
  {
    label: 'test',
    listingType: 'test',
    transactionProcessAlias: 'default-purchase/release-1',
    unitType: 'item',
    listingTitile: 'Direct Response Video',
  },
  {
    label: 'test',
    listingType: 'test',
    transactionProcessAlias: 'default-purchase/release-1',
    unitType: 'item',
    listingTitile: 'A/B Testing Package',
  },
  {
    label: 'Product Seeding',
    listingType: 'seeding',
    transactionProcessAlias: 'default-inquiry/release-1',
    unitType: 'inquiry',
    listingTitile: 'Open to gifted collaborations',
  },
  {
    label: 'test',
    listingType: 'test',
    transactionProcessAlias: 'default-purchase/release-1',
    unitType: 'item',
    listingTitile: 'Other',
  },
];

// Field component that either allows selecting listing type (if multiple types are available)
// or just renders hidden fields:
// - listingType              Set of predefined configurations for each listing type
// - transactionProcessAlias  Initiate correct transaction against Marketplace API
// - unitType                 Main use case: pricing unit
const FieldSelectListingType = props => {
  const { name, hasExistingListingType, onListingTypeChange, formApi, intl } = props;
  const hasMultipleListingTypes = listingTypes?.length > 1;

  const handleOnChange = value => {
    const selectedListingType = listingTypes.find(config => config.listingTitile === value);

    formApi.change('transactionProcessAlias', selectedListingType.transactionProcessAlias);
    formApi.change('unitType', selectedListingType.unitType);
    formApi.change('listingTypeLabel', selectedListingType.label);
    formApi.change('listingType', selectedListingType.listingType);

    if (onListingTypeChange) {
      onListingTypeChange(selectedListingType);
    }
  };

  return hasMultipleListingTypes && !hasExistingListingType ? (
    <>
      <FieldSelect
        id={name}
        name={name}
        className={css.listingTypeSelect}
        label={'Listing title'}
        validate={required('You need to select listing title.')}
        onChange={handleOnChange}
      >
        <option disabled value="">
          Select listing title
        </option>
        {listingTypes.map(config => {
          const type = config.listingTitile;
          return (
            <option key={type} value={config.listingTitile}>
              {config.listingTitile}
            </option>
          );
        })}
        {/* Type list */}
      </FieldSelect>
      <FieldHidden name="listingType" />
      <FieldHidden name="transactionProcessAlias" />
      <FieldHidden name="unitType" />
      <FieldHidden name="listingTypeLabel" />
      {/*  */}
    </>
  ) : hasMultipleListingTypes && hasExistingListingType ? (
    <div className={css.listingTypeSelect}>
      <Heading as="h5" rootClassName={css.selectedLabel}>
        {intl.formatMessage({ id: 'EditListingDetailsForm.listingTypeLabel' })}
      </Heading>
      <p className={css.selectedValue}>{formApi.getFieldState(name)?.value}</p>
      <FieldHidden name={name} />
      <FieldHidden name="listingType" />
      <FieldHidden name="transactionProcessAlias" />
      <FieldHidden name="unitType" />
      <FieldHidden name="listingTypeLabel" />
    </div>
  ) : (
    <>
      <FieldHidden name={name} />
      <FieldHidden name="listingType" />
      <FieldHidden name="transactionProcessAlias" />
      <FieldHidden name="unitType" />
      <FieldHidden name="listingTypeLabel" />
    </>
  );
};

// Add collect data for listing fields (both publicData and privateData) based on configuration
const AddListingFields = props => {
  const { listingType, listingFieldsConfig, intl } = props;
  const fields = listingFieldsConfig.reduce((pickedFields, fieldConfig) => {
    const { key, includeForListingTypes, schemaType, scope } = fieldConfig || {};
    const namespacedKey = scope === 'public' ? `pub_${key}` : `priv_${key}`;

    const isKnownSchemaType = EXTENDED_DATA_SCHEMA_TYPES.includes(schemaType);
    const isTargetProcessAlias =
      includeForListingTypes == null || includeForListingTypes.includes(listingType);
    const isProviderScope = ['public', 'private'].includes(scope);

    return isKnownSchemaType && isTargetProcessAlias && isProviderScope
      ? [
          ...pickedFields,
          <CustomExtendedDataField
            key={namespacedKey}
            name={namespacedKey}
            fieldConfig={fieldConfig}
            defaultRequiredMessage={intl.formatMessage({
              id: 'EditListingDetailsForm.defaultRequiredMessage',
            })}
          />,
        ]
      : pickedFields;
  }, []);

  return <>{fields}</>;
};

// Form that asks title, description, transaction process and unit type for pricing
// In addition, it asks about custom fields according to marketplace-custom-config.js
const EditListingDetailsFormComponent = props => {
  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          autoFocus,
          className,
          disabled,
          ready,
          formId,
          form: formApi,
          handleSubmit,
          onListingTypeChange,
          intl,
          invalid,
          pristine,
          selectableListingTypes,
          hasExistingListingType,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          listingFieldsConfig,
          values,
        } = formRenderProps;

        const { listingType } = values;

        const titleRequiredMessage = intl.formatMessage({
          id: 'EditListingDetailsForm.titleRequired',
        });
        const maxLengthMessage = intl.formatMessage(
          { id: 'EditListingDetailsForm.maxLength' },
          {
            maxLength: TITLE_MAX_LENGTH,
          }
        );

        const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

        // Show title and description only after listing type is selected
        const showTitle = listingType;
        let showDescription = values.title;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        // const titles = [
        //   'Single concept video',
        //   'Voiceover Video',
        //   'Talking Head Video',
        //   'Unboxing Video (Faceless)',
        //   'Trending Audio Video',
        //   'Direct Response Video',
        //   'A/B Testing Package',
        //   'Open to gifted collaborations',
        // ];

        const showListingDetail = values.title === 'Other';

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <ErrorMessage fetchErrors={fetchErrors} />

            <FieldSelectListingType
              name="title"
              listingTypes={selectableListingTypes}
              hasExistingListingType={hasExistingListingType}
              onListingTypeChange={onListingTypeChange}
              formApi={formApi}
              intl={intl}
            />

            {showListingDetail ? (
              <FieldTextInput
                id={`${formId}alternative`}
                name="alternative"
                className={css.title}
                type="text"
                label="Listing Title"
                placeholder="Listing Title"
                maxLength={TITLE_MAX_LENGTH}
                validate={composeValidators(
                  required('You need add listing title'),
                  maxLength60Message
                )}
                autoFocus={autoFocus}
              />
            ) : null}

            {showDescription ? (
              <>
                <FieldTextInput
                  id={`${formId}description`}
                  name="description"
                  className={css.description}
                  rows="4"
                  type="textarea"
                  label={intl.formatMessage({ id: 'EditListingDetailsForm.description' })}
                  placeholder={intl.formatMessage({
                    id: 'EditListingDetailsForm.descriptionPlaceholder',
                  })}
                  validate={required(
                    intl.formatMessage({
                      id: 'EditListingDetailsForm.descriptionRequired',
                    })
                  )}
                />
              </>
            ) : null}

            {values.title === 'Open to gifted collaborations' && (
              <FieldTextInput
                id="shippingAddress"
                name="shippingAddress"
                className={css.title}
                type="text"
                label="Shipping address"
                placeholder="Your shipping address"
                validate={composeValidators(required('You need add your address'))}
                // autoFocus={autoFocus}
              />
            )}

            <AddListingFields
              listingType={listingType}
              listingFieldsConfig={listingFieldsConfig}
              intl={intl}
            />
            <div className={css.buttonContainer}>
              <PrimaryButton
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </PrimaryButton>{' '}
              <NamedLink name="ManageListingsPage">Return to Profile</NamedLink>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingDetailsFormComponent.defaultProps = {
  className: null,
  formId: 'EditListingDetailsForm',
  fetchErrors: null,
  hasExistingListingType: false,
  listingFieldsConfig: [],
};

EditListingDetailsFormComponent.propTypes = {
  className: string,
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  onListingTypeChange: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  selectableListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionProcessAlias: string.isRequired,
      unitType: string.isRequired,
    })
  ).isRequired,
  hasExistingListingType: bool,
  listingFieldsConfig: propTypes.listingFieldsConfig,
};

export default compose(injectIntl)(EditListingDetailsFormComponent);

/* <div className={css.area}>
            <FieldTextInput
              id="convenientArea"
              name="convenientArea"
              className={css.convenientArea}
              type="text"
              label={convenientAreaMessage}
              placeholder={convenientAreaPlaceholderMessage}
              validate={composeValidators(required(convenientAreaRequiredMessage))}
            />
          </div> */

/* <div className={css.field}>
                    {distanceMessage}
                    <div>
                      <FieldRadioButton
                        id="travelYes"
                        name="travelWilling"
                        className={css.virtualTraining}
                        label="Yes"
                        value="yes"
                      />
                      <FieldRadioButton
                        id="travelNo"
                        name="travelWilling"
                        className={css.virtualTraining}
                        label="No"
                        value="no"
                      />
                    </div>
                  </div> */

/* <div className={css.distanceBlock}>
            <FieldTextInput
              id="distance"
              name="distance"
              className={css.distance}
              S
              type="number"
              min={0}
              label={distanceMessage}
              placeholder={distancePlaceholderMessage}
              validate={composeValidators(required(distanceRequiredMessage))}
            />
          </div> */

/* <div className={css.field}>
                    {addressTitle}
                    <h4 className={css.addressSubtitle}>{addressSubTitle}</h4>
                    <p>
                      <FormattedMessage id="EditListingLocationForm.primaryLocation" />
                    </p>
                    <FieldLocationAutocompleteInput
                      className={css.locationAddress}
                      inputClassName={css.locationAutocompleteInput}
                      iconClassName={css.locationAutocompleteInputIcon}
                      predictionsClassName={css.predictionsRoot}
                      validClassName={css.validLocation}
                      autoFocus
                      name="location"
                      label={locationRequiredMessage}
                      placeholder={addressPlaceholderMessage}
                      useDefaultPredictions={false}
                      format={identity}
                      valueFromForm={values.location}
                      validate={composeValidators(
                        autocompleteSearchRequired(addressRequiredMessage),
                        autocompletePlaceSelected(addressNotRecognizedMessage)
                      )}
                    />
                    <FieldTextInput
                      className={css.building}
                      type="text"
                      name="building"
                      id="building"
                      label={buildingMessage}
                      placeholder={buildingPlaceholderMessage}
                    />
                  </div>
                  <div className={css.field}>
                    <p>
                      <FormattedMessage id="EditListingLocationForm.secondaryLocation" />
                    </p>
                    <FieldLocationAutocompleteInput
                      className={css.locationAddress}
                      inputClassName={css.locationAutocompleteInput}
                      iconClassName={css.locationAutocompleteInputIcon}
                      predictionsClassName={css.predictionsRoot}
                      validClassName={css.validLocation}
                      name="location2"
                      label={address2Label}
                      placeholder={addressPlaceholderMessage}
                      useDefaultPredictions={false}
                      format={identity}
                      valueFromForm={values.address2}
                      // validate={composeValidators(
                      //   autocompletePlaceOptionalSelected(addressNotRecognizedMessage)
                      // )}
                    />

                    <FieldTextInput
                      className={css.building}
                      type="text"
                      name="building2"
                      id="building2"
                      label={buildingMessage}
                      placeholder={buildingPlaceholderMessage}
                    />
                  </div>
                  <div className={css.field}>
                    <p>
                      <FormattedMessage id="EditListingLocationForm.tertiaryLocation" />
                    </p>
                    <FieldLocationAutocompleteInput
                      className={css.locationAddress}
                      inputClassName={css.locationAutocompleteInput}
                      iconClassName={css.locationAutocompleteInputIcon}
                      predictionsClassName={css.predictionsRoot}
                      validClassName={css.validLocation}
                      name="location3"
                      label={address3Label}
                      placeholder={addressPlaceholderMessage}
                      useDefaultPredictions={false}
                      format={identity}
                      valueFromForm={values.address3}
                      // validate={composeValidators(
                      //   autocompletePlaceOptionalSelected(addressNotRecognizedMessage)
                      // )}
                    />
                    <FieldTextInput
                      className={css.building}
                      type="text"
                      name="building3"
                      id="building3"
                      label={buildingMessage}
                      placeholder={buildingPlaceholderMessage}
                    />
                  </div> */
