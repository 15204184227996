import React from 'react';
import PropTypes from 'prop-types';
import css from './SimpleAvatar.module.css';

const SimpleAvatar = ({ imageUrl }) => {
  return (
    <div className={css.avatar}>
      <img src={imageUrl} alt="Avatar" className={css.avatarImage} />
    </div>
  );
};

SimpleAvatar.propTypes = {
  imageUrl: PropTypes.string.isRequired,
};

export default SimpleAvatar;
