import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import css from './HelpGeneralQs.module.css';
import { ExternalLink } from '../index';

const HelpGeneralQs = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.faqContent}>
        <div className={css.faqQ}>
          <input id="q1" type="checkbox" className={css.panel} />
          <div className={css.plus}>+</div>
          <label htmlFor="q1" className={css.panelTitle}>
            What is Collabreate?
          </label>
          <div className={css.panelContent}>
            Collabreate is an innovative platform designed to empower brands with custom creative
            assets and facilitate large-scale social marketing campaigns.
            <br />
            <br />
            With Collabreate, businesses can easily find creators that align with their brand values
            and messages, whether they are looking for broad appeal or niche engagement. Our
            platform guarantees the authenticity and safety of working with creators through a
            thorough verification process by our moderation team.
            <br />
            <br />
            Collabreate simplifies the journey of connecting with these creators, from initiating
            briefs to tracking the results of campaigns, including payment processing.
            <br />
            <br />
            Additionally, Collabreate offers seamless integration with Facebook Ads Manager and
            Snapchat Ads, enabling brands to launch targeted ad campaigns using creator content,
            complete with comprehensive reporting and analytics.
          </div>
        </div>
        <div className={css.faqQ}>
          <input id="q2" type="checkbox" className={css.panel} />
          <div className={css.plus}>+</div>
          <label htmlFor="q2" className={css.panelTitle}>
            What expenses are involved?
          </label>
          <div className={css.panelContent}>
            Utilizing Collabreate involves two types of expenses:
            <br />
            <br />
            <ol>
              <li>
                A month-to-month subscription fee provides full access to our platform, with the
                flexibility to cancel anytime.
              </li>
              <li>
                Adding funds to your balance for payments towards creator collaborations and content
                production. Content fees apply. Discover more about our pricing structure on our
                website.
              </li>
            </ol>
          </div>
        </div>
        <div className={css.faqQ}>
          <input id="q3" type="checkbox" className={css.panel} />
          <div className={css.plus}>+</div>
          <label htmlFor="q3" className={css.panelTitle}>
            How can I cancel my subscription?
          </label>
          <div className={css.panelContent}>
            To cancel your subscription, simply contact us via email at: hello@collabreate.com or
            through our chat service. Your subscription access will conclude at the end of your
            current billing period.
          </div>
        </div>
        <div className={css.faqQ}>
          <input id="q4" type="checkbox" className={css.panel} />
          <div className={css.plus}>+</div>
          <label htmlFor="q4" className={css.panelTitle}>
            What payment methods are accepted?
          </label>
          <div className={css.panelContent}>
            We accept:
            <ol>
              <li>Credit card payments</li>
              <li>Payments by invoice Both options are available</li>
            </ol>
            across all subscription plans. Invoice payments require a minimum 3-month contract,
            unlike credit card payments. For invoice setup, please reach out to us via email or
            chat.
          </div>
        </div>
        <div className={css.faqQ}>
          <input id="q5" type="checkbox" className={css.panel} />
          <div className={css.plus}>+</div>
          <label htmlFor="q5" className={css.panelTitle}>
            What if my spending is less than my deposited balance?
          </label>
          <div className={css.panelContent}>
            When funding your balance, plan for your total campaign budget. Should you have funds
            remaining after engaging sufficient creators, these can be allocated to future
            campaigns. Should you choose not to run more campaigns, we offer refunds for the
            remaining balance as per our Refund Policy, upon request to hello@collabreate.com.
          </div>
        </div>
        <div className={css.faqTitle}>
          <h2>Creator</h2>
        </div>
        <div className={css.faqQ}>
          <input id="q6" type="checkbox" className={css.panel} />
          <div className={css.plus}>+</div>
          <label htmlFor="q6" className={css.panelTitle}>
            How can I evaluate a creator's audience?
          </label>
          <div className={css.panelContent}>
            Upon a creator's registration on Collabreate, we automatically gather data such as
            follower count, average likes, and video views to calculate engagement rates (ER) and
            cost per engagement (CPE).
            <br />
            <br />
            Our platform also analyzes the creator's audience demographics, including location,
            gender, age, and interests, available in the advertiser interface for those with
            "Premium" or "Premium Plus" plans.
          </div>
        </div>
        <div className={css.faqQ}>
          <input id="q7" type="checkbox" className={css.panel} />
          <div className={css.plus}>+</div>
          <label htmlFor="q7" className={css.panelTitle}>
            How are creator prices determined?
          </label>
          <div className={css.panelContent}>
            Creators indicate an "average price" during account setup, serving as a pricing
            guideline.
            <br />
            For specific campaigns, creators will propose a tailored price upon receiving your
            brief. We encourage negotiation between advertisers and creators to agree on fair
            compensation.
          </div>
        </div>
        <div className={css.faqTitle}>
          <h2>Payments to creators</h2>
        </div>
        <div className={css.faqQ}>
          <input id="q8" type="checkbox" className={css.panel} />
          <div className={css.plus}>+</div>
          <label htmlFor="q8" className={css.panelTitle}>
            What constitutes a deal?
          </label>
          <div className={css.panelContent}>
            A deal represents a collaborative agreement between an advertiser and a creator on
            Collabreate, resulting in branded content tailored for social media use or other
            advertiser needs.
          </div>
        </div>
        <div className={css.faqQ}>
          <input id="q9" type="checkbox" className={css.panel} />
          <div className={css.plus}>+</div>
          <label htmlFor="q9" className={css.panelTitle}>
            How are creators compensated?
          </label>
          <div className={css.panelContent}>
            Collabreate manages all aspects of creator payments for ease. Add funds to your account
            to cover campaign content costs, ensuring budget adherence. A service fee of 15%/20% on
            content costs covers administrative and transaction fees.
            <br />
            Upon content approval and concluding a deal, payment is released to the creator from
            your pre-loaded balance.
          </div>
        </div>
        <div className={css.faqQ}>
          <input id="q10" type="checkbox" className={css.panel} />
          <div className={css.plus}>+</div>
          <label htmlFor="q10" className={css.panelTitle}>
            Can I compensate creators with my products?
          </label>
          <div className={css.panelContent}>
            Yes, product or service compensation is an option, eliminating the need for additional
            payment for product-based campaigns. Specify this in your brief and discuss logistics
            directly with each creator to ensure product delivery.
          </div>
        </div>
        <div className={css.faqTitle}>
          <h2>Brief and applications from creators</h2>
        </div>
        <div className={css.faqQ}>
          <input id="q11" type="checkbox" className={css.panel} />
          <div className={css.plus}>+</div>
          <label htmlFor="q11" className={css.panelTitle}>
            How do I receive applications from creators?
          </label>
          <div className={css.panelContent}>
            Activate a subscription plan, submit your campaign brief for review, and upon approval,
            it will be accessible to creators through the Collabreate app. You can then review
            responses and select suitable creators for your campaign.
          </div>
        </div>
        <div className={css.faqQ}>
          <input id="q12" type="checkbox" className={css.panel} />
          <div className={css.plus}>+</div>
          <label htmlFor="q12" className={css.panelTitle}>
            My brief is under review - what does this mean?
          </label>
          <div className={css.panelContent}>
            Each brief undergoes a review by the Collabreate team to ensure it contains all
            necessary details for creators. We'll contact you with any suggestions or to confirm
            when your brief is active.
          </div>
        </div>
        <div className={css.faqQ}>
          <input id="q12" type="checkbox" className={css.panel} />
          <div className={css.plus}>+</div>
          <label htmlFor="q12" className={css.panelTitle}>
            What happens after my brief is approved?
          </label>
          <div className={css.panelContent}>
            Post-approval, your brief is sent to matching creators or those you've selected via our
            "Browse creators" feature, available on "Premium" and "Premium Plus" plans. You'll start
            receiving applications within hours, typically gathering a sufficient number within the
            first three days.
          </div>
        </div>
      </div>
    </div>
  );
};

HelpGeneralQs.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

HelpGeneralQs.propTypes = {
  rootClassName: string,
  className: string,
};

export default HelpGeneralQs;
