import css from './CreatorCard.module.css';
import creatorCardDesktop from '../../../assets/become-pro/creator_card_desktop.webp';
import { Button, NamedLink } from '../../../components';

export default function CreatorCard() {
  return (
    <div className={css.root}>
      <div className={css.contentContainer}>
        <div>
          <img src={creatorCardDesktop} alt="creator card" className={css.cardImage} />
        </div>
        <div className={css.textContainer}>
          <div className={css.title}>
            Singapore's first <br /> Content Creator Card
          </div>
          <div className={css.subtitle}>Shop, Vlog and Earn</div>
          <div className={css.textContent}>
            Discover over 100+ exclusive deals curated for content creators across various Shops,
            F&B and Entertainment outlets. Build and nurture relationships with your favourite
            brands and get invited to exclusive events and collaboration opportunities.
          </div>
          <NamedLink name="SignupPage" className={css.signupLink}>
            <Button className={css.button}>Join the waitlist</Button>{' '}
          </NamedLink>
        </div>
      </div>
    </div>
  );
}
