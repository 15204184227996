/**
 * Export loadData calls from ducks modules of different containers
 */
import { loadData as AuthenticationPageLoader } from './AuthenticationPage/AuthenticationPage.duck';
import { setInitialValues as CheckoutPageInitialValues } from './CheckoutPage/CheckoutPage.duck';
// import { loadData as CMSPageLoader } from './CMSPage/CMSPage.duck';
// import { loadData as ContactDetailsPageLoader } from './ContactDetailsPage/ContactDetailsPage.duck';
import { loadData as EditListingPageLoader } from './NewEditListingPage/EditListingPage.duck';
// import { loadData as EmailVerificationPageLoader } from './EmailVerificationPage/EmailVerificationPage.duck';
import { loadData as InboxPageLoader } from './InboxPage/InboxPage.duck';
import { loadData as ListingPageLoader } from './ListingPage/ListingPage.duck';
import { loadData as ManageListingsPageLoader } from './ManageListingsPage/ManageListingsPage.duck';
import { loadData as PaymentMethodsPageLoader } from './PaymentMethodsPage/PaymentMethodsPage.duck';
// import { loadData as PrivacyPolicyPageLoader } from './PrivacyPolicyPage/PrivacyPolicyPage.duck';
import { loadData as ProfilePageLoader } from './ProfilePage/ProfilePage.duck';
import { loadOwnData as CreatorListingsPageLoader } from './ProfilePage/ProfilePage.duck';
import { loadData as SearchPageLoader } from './SearchPage/SearchPage.duck';
import { loadData as StripePayoutPageLoader } from './StripePayoutPage/StripePayoutPage.duck';
import { loadData as CategoriesPageLoader } from './CategoriesPage/CategoriesPage.duck';
import { loadData as MyCalendarPageLoader } from './MyCalendarPage/MyCalendarPage.duck';

// import { loadData as TermsOfServicePageLoader } from './TermsOfServicePage/TermsOfServicePage.duck';
import {
  loadData as TransactionPageLoader,
  setInitialValues as TransactionPageInitialValues,
} from './TransactionPage/TransactionPage.duck';

import { loadData as PartnerDealsPageLoader } from './PartnerDealsPage/PartnerDealsPage.duck';
import { loadData as PartnerDealsDetailsPageLoader } from './PartnerDealsPage/Client/DealsDetails/ClientDealsDetails.duck';
import { loadData as PartnerDealsCreateNewDealPageLoader } from './PartnerDealsPage/Client/CreateNewDeal/CreateNewDeal.duck';
import { loadData as CampaignsPageLoader } from './Campaigns/index.duck';

const getPageDataLoadingAPI = () => {
  return {
    AuthenticationPage: {
      loadData: AuthenticationPageLoader,
    },
    CheckoutPage: {
      setInitialValues: CheckoutPageInitialValues,
    },
    CategoriesPage: {
      loadData: CategoriesPageLoader,
    },
    MyCalendarPage: {
      loadData: MyCalendarPageLoader,
    },
    // CMSPage: {
    //   loadData: CMSPageLoader,
    // },
    // ContactDetailsPage: {
    //   loadData: ContactDetailsPageLoader,
    // },
    EditListingPage: {
      loadData: EditListingPageLoader,
    },
    // EmailVerificationPage: {
    //   loadData: EmailVerificationPageLoader,
    // },
    // InboxPage: {
    //   loadData: InboxPageLoader,
    // },
    ListingPage: {
      loadData: ListingPageLoader,
    },
    ManageListingsPage: {
      loadData: ManageListingsPageLoader,
    },
    // PaymentMethodsPage: {
    //   loadData: PaymentMethodsPageLoader,
    // },
    // PrivacyPolicyPage: {
    //   loadData: PrivacyPolicyPageLoader,
    // },
    ProfilePage: {
      loadData: ProfilePageLoader,
    },
    SearchPage: {
      loadData: SearchPageLoader,
    },
    CreatorListingsPage: {
      loadData: CreatorListingsPageLoader,
    },
    // StripePayoutPage: {
    //   loadData: StripePayoutPageLoader,
    // },
    // TermsOfServicePage: {
    //   loadData: TermsOfServicePageLoader,
    // },
    TransactionPage: {
      loadData: TransactionPageLoader,
      setInitialValues: TransactionPageInitialValues,
    },
    PartnerDealsPage: {
      loadData: PartnerDealsPageLoader,
    },
    PartnerDealsDetailsPage: {
      loadData: PartnerDealsDetailsPageLoader,
    },
    PartnerDealsCreateNewDealPage: {
      loadData: PartnerDealsCreateNewDealPageLoader,
    },
    CampaignsPage: {
      loadData: CampaignsPageLoader,
    },
  };
};

export default getPageDataLoadingAPI;
