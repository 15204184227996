import { useMemo, useState } from 'react';
import * as validators from '../../util/validators';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import axiosInstance from '../../axios';

const useSignupFlowHook = props => {
  const { submitSignup, intl } = props;
  const history = useHistory();
  const [currentFlow, setCurrentFlow] = useState(() => {
    if (typeof window !== 'undefined') {
      return sessionStorage.getItem('currentFlow') || '';
    }
  });
  const [currentUserData, setCurrentUserData] = useState(null);
  const [selectedSignupProcess, setSelectedSignupProcess] = useState(() => {
    if (typeof window !== 'undefined') {
      return parseInt(sessionStorage.getItem('selectedSignupProcess')) || null;
    }
  });

  // learner and pro option file function and methods
  // const [selectedSignupProcess, setSelectedSignupProcess] = useState(null);

  function _onCreateButtonClick(goTO) {
    if (selectedSignupProcess) {
      setCurrentFlow(goTO);
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      sessionStorage.setItem('currentFlow', currentFlow);
    }
  }, [currentFlow]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      sessionStorage.setItem('selectedSignupProcess', selectedSignupProcess);
    }
  }, [selectedSignupProcess]);

  function _intelMessage(message) {
    return intl.formatMessage({ id: message });
  }

  // signup form methodm, states, function
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string()
          .trim()
          .required(_intelMessage('SignupForm.firstNameRequired')),
        lastName: Yup.string()
          .trim()
          .required(_intelMessage('SignupForm.lastNameRequired')),
        email: Yup.string()
          .trim()
          .required(_intelMessage('SignupForm.emailRequired'))
          .email(_intelMessage('SignupForm.emailInvalid')),
        ...(selectedSignupProcess !== 2
          ? {
              company: Yup.string()
                .trim()
                .required(_intelMessage('SignupForm.companyRequired')),
            }
          : {}),
        term: Yup.boolean().oneOf([true], 'Field must be checked'),
        password: Yup.string()
          .trim()
          .required(_intelMessage('SignupForm.passwordRequired'))
          .min(
            validators.PASSWORD_MIN_LENGTH,
            intl.formatMessage(
              { id: 'SignupForm.passwordTooShort' },
              { minLength: validators.PASSWORD_MIN_LENGTH }
            )
          )
          .max(
            validators.PASSWORD_MAX_LENGTH,
            intl.formatMessage(
              { id: 'SignupForm.passwordTooLong' },
              { maxLength: validators.PASSWORD_MAX_LENGTH }
            )
          ),
      }),
    [selectedSignupProcess]
  );

  const [createInitialValues, setCreateInitialValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    company: '',
    term: false,
  });

  function _handleCreateAccountSubmit(formValues, section) {
    setCreateInitialValues(formValues);
    const { lastName, firstName, email, password, company } = formValues;
    const params = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.trim(),
      password: password?.trim(),
      company: company.trim(),
      isAgency: selectedSignupProcess === 1 ? true : false,
      isBrand: selectedSignupProcess === 3 ? true : false,
      isCreator: selectedSignupProcess === 2 ? true : false,
      userType:
        selectedSignupProcess === 1
          ? 'agency'
          : selectedSignupProcess === 2
          ? 'creator'
          : selectedSignupProcess === 3
          ? 'brand'
          : '',
    };

    // Send Slack Notification when someone Logs In
    // Only works when the environment is production
    const fullName = `${firstName} ${lastName}`;
    if (process.env.REACT_APP_ENV === 'production') {
      if (selectedSignupProcess === 1 || selectedSignupProcess === 3) {
        axiosInstance.post('/slack/send-client-signup-notification', {
          fullName: fullName,
          email: email,
        });
      }
    }

    submitSignup(params, r => {
      setCurrentFlow('flow5');
      setCurrentUserData(r?.data?.data?.id);
    });
  }

  //welcomeProLed
  function _handleGetStarted() {
    setCurrentFlow('flow6');
  }

  return {
    //lerner and pro all states,functions
    selectedSignupProcess,
    setSelectedSignupProcess,
    currentFlow,
    setCurrentFlow,
    _onCreateButtonClick,
    currentUserData,

    // signup funtions and state
    createInitialValues,
    validationSchema,

    _handleCreateAccountSubmit,
    _intelMessage,

    //welcomePro Led
    _handleGetStarted,
    history,
    ...props,
  };
};

export default useSignupFlowHook;
