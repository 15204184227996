import React, { Component, useEffect, useState } from 'react';
import { array, bool, func, oneOf, object, shape, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import unionWith from 'lodash/unionWith';
import classNames from 'classnames';
import config from '../../config';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { SearchMap, ModalInMobile, LayoutWrapperFooter, Footer, Page } from '../../components';
import { TopbarContainer } from '../../containers';

import { searchListings, searchMapListings, setActiveListing } from './SearchPage.duck';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from './SearchPage.helpers';
import MainPanel from './MainPanel';
import css from './SearchPage.module.css';
import routeConfiguration from '../../routing/routeConfiguration';
import { hasPaidSubscription, isSubscribtionExpired } from '../CategoriesPage/CategoriesPage';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

export const SearchPageComponent = props => {
  const {
    intl,
    listings,
    filterConfig,
    sortConfig,
    history,
    location,
    mapListings,
    onManageDisableScrolling,
    pagination,
    scrollingDisabled,
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    onActivateListing,
    currentUser,
  } = props;

  const [isSearchMapOpenOnMobile, setIsSearchMapOpenOnMobile] = useState(props.tab === 'map');
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  const onMapMoveEnd = (viewportBoundsChanged, data) => {
    const { viewportBounds, viewportMapCenter } = data;

    const routes = routeConfiguration();
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      // parse query parameters, including a custom attribute named certificate
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      // const viewportMapCenter = SearchMap.getMapCenter(map);
      const originMaybe = config.sortSearchByDistance ? { origin: viewportMapCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: false,
        ...validFilterParams(rest, filterConfig),
      };

      history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
    }
  };

  const { hasAccess, paidProductId, expires_at, isCreator } =
    currentUser?.attributes?.profile?.protectedData || {};

  useEffect(() => {
    if (currentUser?.attributes?.profile?.protectedData) {
      if (isCreator) {
        history.push(`/creator/${currentUser.id.uuid}`);
      } else if (
        hasAccess ||
        (hasPaidSubscription(paidProductId) && !isSubscribtionExpired(expires_at))
      ) {
        return;
      } else {
        history.push('/pricing');
      }
    }
  }, [isCreator, hasAccess]);

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  const onOpenMobileModal = () => setIsMobileModalOpen(true);

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  const onCloseMobileModal = () => setIsMobileModalOpen(false);

  // eslint-disable-next-line no-unused-vars
  const { mapSearch, page, ...searchInURL } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  // urlQueryParams doesn't contain page specific url params
  // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
  const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

  // Page transition might initially use values from previous search
  const urlQueryString = stringify(urlQueryParams);
  const paramsQueryString = stringify(pickSearchParamsOnly(searchParams, filterConfig, sortConfig));
  const searchParamsAreInSync = urlQueryString === paramsQueryString;

  const validQueryParams = validURLParamsForExtendedData(searchInURL, filterConfig);

  const isWindowDefined = typeof window !== 'undefined';
  const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
  const shouldShowSearchMap = !isMobileLayout || (isMobileLayout && isSearchMapOpenOnMobile);

  const onMapIconClick = () => {
    useLocationSearchBounds = true;
    setIsSearchMapOpenOnMobile(true);
  };

  const { address, bounds, origin } = searchInURL || {};
  const { title, description, schema } = createSearchResultSchema(listings, address, intl);

  // Set topbar class based on if a modal is open in
  // a child component
  const topbarClasses = isMobileModalOpen
    ? classNames(css.topbarBehindModal, css.topbar)
    : css.topbar;

  // N.B. openMobileMap button is sticky.
  // For some reason, stickyness doesn't work on Safari, if the element is <button>
  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      description={description}
      title={title}
      schema={schema}
    >
      <TopbarContainer
        className={topbarClasses}
        currentPage="SearchPage"
        currentSearchParams={urlQueryParams}
      />
      <div className={css.container}>
        <MainPanel
          urlQueryParams={validQueryParams}
          listings={listings}
          searchInProgress={searchInProgress}
          searchListingsError={searchListingsError}
          searchParamsAreInSync={searchParamsAreInSync}
          onActivateListing={onActivateListing}
          onManageDisableScrolling={onManageDisableScrolling}
          onOpenModal={onOpenMobileModal}
          onCloseModal={onCloseMobileModal}
          onMapIconClick={onMapIconClick}
          pagination={pagination}
          searchParamsForPagination={parse(location.search)}
          showAsModalMaxWidth={MODAL_BREAKPOINT}
          history={history}
          origin={origin}
        />
        {/* {validQueryParams?.pub_category && ( */}
        <ModalInMobile
          className={css.mapPanel}
          id="SearchPage.map"
          isModalOpenOnMobile={isSearchMapOpenOnMobile}
          onClose={() => setIsSearchMapOpenOnMobile(false)}
          showAsModalMaxWidth={MODAL_BREAKPOINT}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.mapWrapper}>
            {shouldShowSearchMap ? (
              <SearchMap
                reusableContainerClassName={css.map}
                activeListingId={activeListingId}
                bounds={bounds}
                center={origin}
                isSearchMapOpenOnMobile={isSearchMapOpenOnMobile}
                location={location}
                listings={mapListings || []}
                onMapMoveEnd={onMapMoveEnd}
                onCloseAsModal={() => {
                  onManageDisableScrolling('SearchPage.map', false);
                }}
                messages={intl.messages}
              />
            ) : null}
          </div>
        </ModalInMobile>
        {/* )} */}
      </div>
      <LayoutWrapperFooter>
        <Footer />
      </LayoutWrapperFooter>
    </Page>
  );
};

SearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSearchMapListings: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
  } = state.SearchPage;
  const { currentUser, currentUserListing } = state.user;
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  return {
    listings: pageListings,
    mapListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    currentUser,
    currentUserListing,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SearchPageComponent);

export default SearchPage;
