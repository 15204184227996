export const getPlatformName = platform => {
  switch (platform) {
    case 'tiktok':
      return 'TikTok';
    case 'instagram':
      return 'Instagram';
    default:
      return platform.charAt(0).toUpperCase() + platform.slice(1);
  }
};
