import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '..';
import { EditListingPersonalDetailsForm } from '../../forms';
import config from '../../config';

import css from './EditListingPersonalDetailsPanel.module.css';

const EditListingPersonalDetailsPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { title, publicData } = currentListing.attributes;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingDescriptionPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );

  const certificateOptions = findOptionsForSelectFilter('certificate', config.custom.filters);
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingPersonalDetailsForm
        className={css.form}
        initialValues={{
          title,
          listingType: publicData && publicData.listingType,
          // phoneCountryCode: privateData && privateData.phoneCountryCode,
          // phoneNumber: privateData && privateData.phoneNumber,
          // description,
          // certificate: publicData.certificate,
          // usCitizen: publicData.usCitizen,
          // allowedToWork: publicData.allowedToWork,
          // convicted: publicData.convicted,
          // gender: publicData.gender,
          // ageCategory: publicData.ageCategory,
          // category: publicData.category,
          // activity: publicData.activity,
          // hearedAbout: privateData && privateData.hearedAbout,
          // grantProleadDiscretion: privateData && privateData.grantProleadDiscretion,
          // activityOther: publicData.activityOther,
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const {
            title,
            listingType,
            // phoneCountryCode,
            // phoneNumber,
            // description,
            // certificate,
            // usCitizen,
            // allowedToWork,
            // convicted,
            // hearedAbout,
            // grantProleadDiscretion,
            // gender,
            // ageCategory,
            // category,
            // activity,
            // activityOther,
          } = values;

          const updateValues = {
            title: title.trim(),
            // description,
            publicData: {
              listingType: Array.isArray(listingType) ? [...listingType] : [listingType],
              // phoneNumber,
              // certificate,
              // usCitizen,
              // allowedToWork,
              // convicted,
              // gender,
              // ageCategory,
              // category,
              // activity,
              // activityOther,
            },
            // privateData: {
            //   phoneNumber,
            //   phoneCountryCode,
            //   hearedAbout,
            //   grantProleadDiscretion,
            // },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        certificateOptions={certificateOptions}
      />
    </div>
  );
};

EditListingPersonalDetailsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingPersonalDetailsPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPersonalDetailsPanel;
