import React, { useState } from 'react';
import { InputFieldComp, SelectFieldComp } from '../../components/CommonComp/FieldComponent';
import { isUploadImageOverLimitError } from '../../../util/errors';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import getCountryCodes from '../../../translations/countryCodes';
import config from '../../../config';
import pink_profile from '../../../assets/pink-profile-pic.png';
import fire_logo from '../../../assets/signup/profile/fire.svg';
import clock_logo from '../../../assets/signup/profile/clock.svg';
import film_logo from '../../../assets/signup/profile/film.svg';
import star_logo from '../../../assets/signup/profile/star.svg';

const ProSecondStepFile = props => {
  const {
    imageUpload,
    handleClick,
    inputRef,
    _handleImageChange,
    uploadImageError,
    errors,
    setFieldValue,
    handleNiches,
    selectedNiches,
    _handleLanguages,
    selectedLanguage,
  } = props;
  const countryCodes = getCountryCodes(config.locale);
  let error = null;
  if (isUploadImageOverLimitError(uploadImageError)) {
    error = (
      <div className="">
        <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
      </div>
    );
  } else if (uploadImageError) {
    error = (
      <div className="">
        <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
      </div>
    );
  }

  const ROLES = _.map(['UGC Creator', 'Influencer', 'UGC Creator and Influencer'], r => ({
    label: r,
    value: r,
  }));

  const LANGUAGES = [
    { id: '1', title: 'English', value: 'English' },
    { id: '2', title: 'Chinese', value: 'Chinese' },
    { id: '3', title: 'Malay', value: 'Malay' },
    { id: '4', title: 'Indonesian', value: 'Indonesian' },
    { id: '5', title: 'Tagalog', value: 'Tagalog' },
    { id: '6', title: 'Tamil', value: 'Tamil' },
    // { id: '7', title: 'Other', value: 'Other' },
  ];

  const NICHES = [
    { id: '1', title: 'Gaming', value: 'gaming' },
    { id: '2', title: 'Health & Fitness', value: 'fitness' },
    { id: '3', title: 'Beauty and Fashion', value: 'beauty' },
    { id: '4', title: 'Tech Reviews and Gadgets', value: 'tech' },
    { id: '5', title: 'Cooking and Food', value: 'food' },
    { id: '6', title: 'DIY and Crafts', value: 'diy' },
    { id: '7', title: 'Educational Content', value: 'education' },
    { id: '8', title: 'Personal Finance and Investing', value: 'finance' },
    { id: '9', title: 'Comedy and Entertainment', value: 'entertainment' },
    { id: '10', title: 'Music and Dance', value: 'music' },
    { id: '11', title: 'Lifestyle and Vlogging', value: 'lifestyle' },
    { id: '12', title: 'Pet and Animal Care', value: 'animal' },
    { id: '13', title: 'Sustainability and Minimalism', value: 'sustainability' },
    { id: '14', title: 'Photography and Videography', value: 'photography' },
    { id: '15', title: 'Health and Wellness', value: 'wellness' },
    { id: '16', title: 'Business and Entrepreneurship', value: 'business' },
    { id: '17', title: 'Arts and Painting', value: 'arts' },
    { id: '18', title: 'Self-Improvement and Motivation', value: 'self-improvement' },
    { id: '19', title: 'Other', value: 'other' },
  ];

  // To define wether, OtherLanguage input must be show or not
  // True -> Show Input
  const [hasOtherLang, setOtherLang] = useState(false);

  return (
    <div className="LearnerFirst_main">
      <div className="fieldWithLabel">
        <label htmlFor="">Profile picture</label>
        <div className="uploadSection">
          <div className="imagePreview">
            <img src={imageUpload?.preview || pink_profile} alt="image-profile" />
          </div>
          <div className="file_selection">
            <input
              type="file"
              ref={inputRef}
              id="upload-button"
              style={{ display: 'none' }}
              onChange={_handleImageChange}
            />
            <button type="button" onClick={handleClick} className="uploadPhotoButton">
              Upload profile picture
            </button>
          </div>
        </div>
      </div>
      {(error || imageUpload?.errMsg) && (
        <div className="error">{error || imageUpload?.errMsg}</div>
      )}
      <div className="fieldWithLabel">
        <label htmlFor="">Display name</label>
        <InputFieldComp name="displayName" placeholder="Enter your full name here" />
      </div>
      <div className="fieldWithLabel">
        <label htmlFor="">Creator Type</label>
        <SelectFieldComp name="role" options={ROLES} error={errors?.role} />
      </div>
      <div className="fieldWithLabel">
        <label htmlFor="">About</label>
        <div className="normal_font">
          Generally we find that brands love to see your skills for each listing. Simply follow the
          below guidelines to be booked fast:
        </div>
        <div className="logo_section">
          <div className="">
            <div className="logo_item_flex">
              <img src={fire_logo} alt="" />
              <div className="normal_font">Introduce yourself</div>
            </div>
            <div className="logo_item_flex">
              <img src={clock_logo} alt="" />
              <div className="normal_font">Experience duration</div>
            </div>
          </div>
          <div className="">
            <div className="logo_item_flex">
              <img src={star_logo} alt="" />
              <div className="normal_font">Brand collaboration</div>
            </div>
            <div className="logo_item_flex">
              <img src={film_logo} alt="" />
              <div className="normal_font">Type of content</div>
            </div>
          </div>
        </div>

        <InputFieldComp name="about" placeholder="Description" type="textarea" />
        {errors?.about && <div className="error">{errors?.about}</div>}
      </div>
      <div className="fieldWithLabel">
        <label htmlFor="">Social media links</label>
        <div className="socialInputsBlock">
          <div className="socialInputWrapper">
            <InputFieldComp name="instagram" className="socialInput" placeholder="Instagram" />
          </div>
          <div className="socialInputWrapper">
            <InputFieldComp name="tiktok" className="socialInput" placeholder="TikTok" />
          </div>

          <div className="socialInputWrapper">
            <InputFieldComp name="youtube" className="socialInput" placeholder="YouTube" />
          </div>

          <div className="socialInputWrapper">
            {' '}
            <InputFieldComp name="twitter" className="socialInput" placeholder="Twitter" />
          </div>

          <div className="socialInputWrapper">
            <InputFieldComp name="facebook" className="socialInput" placeholder="Facebook" />
          </div>

          <div className="socialInputWrapper">
            <InputFieldComp name="website" className="socialInput" placeholder="Website" />
          </div>
        </div>
      </div>
      <div className="nicheTitle">
        <span>
          <b>Niche</b> (you can select multiple niches)
        </span>
      </div>
      <div className="toggleContainer">
        {_.map(NICHES, r => (
          <button
            type="button"
            className={classNames('togglebutton', {
              active: _.includes(selectedNiches, r?.value),
            })}
            onClick={() => handleNiches(r)}
            key={r?.id}
          >
            {r?.title}
          </button>
        ))}
      </div>
      <div className="fieldWithLabel">
        <label htmlFor="">Country of residence</label>
        <SelectFieldComp
          name="usCitizen"
          options={_.map(countryCodes, r => ({ label: r?.name, value: r?.code }))}
          error={errors?.usCitizen}
        />
      </div>
      <label htmlFor="">Languages</label>
      <div className="toggleContainer">
        {_.map(LANGUAGES, r => (
          <button
            type="button"
            className={classNames('togglebutton', {
              active: _.includes(selectedLanguage, r?.value),
            })}
            onClick={() => _handleLanguages(r)}
            key={r?.id}
          >
            {r?.title}
          </button>
        ))}
        <button
          type="button"
          className={classNames('togglebutton', hasOtherLang && 'active')}
          onClick={() => {
            setOtherLang(!hasOtherLang);
          }}
        >
          Other
        </button>
      </div>
      {/* If user has selected 'Other' in languages, show input for other language */}
      {hasOtherLang && (
        <div className="">
          <label htmlFor="">Other Languages</label>
          <InputFieldComp
            name="otherLanguages"
            placeholder="Use a comma to separate, e.g. English,Cantonese,Japanese"
            type="text"
          />
        </div>
      )}
    </div>
  );
};

export default ProSecondStepFile;
