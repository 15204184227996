import React, { useState } from 'react';
import { postUploadVideo } from '../../../util/api';

function Input(props) {
  const [video, setVideo] = useState('');

  const uploadVideos = files => {
    const formData = new FormData();
    setVideo(files[0]);
    formData.append('videos', files[0]);
    formData.append('id', `${Date.now()}`);
    postUploadVideo(formData).then(data => {
      setVideo(...data.urls);
      props.setUrl(...data.urls);
    });
  };
  return (
    <div>
      <input type="file" onChange={e => uploadVideos(e.target.files)} />

      {/* {video && <video width={320} src={video} controls />} */}
    </div>
  );
}

export default Input;
