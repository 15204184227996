import { func } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import IconInfo from '../IconInfo/IconInfo';
import Modal from '../Modal/Modal';
import css from './InstagramAccountsModal.module.css';
import Cookies from 'js-cookie';
import axios from 'axios';
import InstagramLogo from '../SocialIcons/InstagramLogo';
import toast, { Toaster } from 'react-hot-toast';

const InstagramAccountsModalComponent = ({ creator, currentUser, onManageDisableScrolling }) => {
  const [accounts, setAccounts] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const load = async () => {
      const { data } = await axios.get('/api/creator/me/accounts/instagram');
      setAccounts(data.data || []);
      setIsOpen(true);
    };

    const instagramAuth = Cookies.get('instagramAuth');
    Cookies.remove('instagramAuth');
    if (instagramAuth === 'true') {
      load();
    }
  }, []);

  const connectAccount = async accountId => {
    try {
      await axios.post(`/api/creator/me/accounts/instagram/${accountId}/connect`);
      Cookies.set(
        'flash',
        JSON.stringify([
          {
            type: 'success',
            content:
              'You have successfully connected your Instagram account. We will fetch your analytics data shortly.',
          },
        ])
      );
      if (typeof window !== 'undefined') {
        window.location.reload();
      }
    } catch (error) {
      setIsOpen(false);
      console.error('Instagram Account Connection Error', error);
      toast.error('Failed to connect Instagram account. Please try again later.');
    }
  };

  return (
    <Modal
      id="CreatorAnalytics.InstagramAccounts"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <div>
        <p className={css.title}>
          <InstagramLogo className={css.instagramLogo} />
          One final step.
        </p>
        <p className={css.subtitle}>Confirm which Instagram account would you like to connect?</p>
        <div className={css.wrapper}>
          {accounts.map(account => {
            return (
              <div key={account.id} className={css.card}>
                <div className={css.imageContainer}>
                  {account.profilePicture ? (
                    <img
                      className={css.image}
                      src={account.profilePicture}
                      alt={account.username}
                    />
                  ) : (
                    <svg fill="currentColor" viewBox="0 0 16 16" className={css.imagePlaceholder}>
                      <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 100-6 3 3 0 000 6z" />
                    </svg>
                  )}
                </div>
                <div className={css.username}>
                  <a
                    href={`https://www.instagram.com/${account.username}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    @{account.username}
                  </a>
                </div>
                <div className={css.stats}>
                  <div>{account.followersCount} Followrs</div>
                  <div> | </div>
                  <div>{account.mediaCount} Posts</div>
                </div>

                <button
                  className={css.connectButton}
                  type="button"
                  onClick={() => connectAccount(account.id)}
                >
                  Connect
                </button>
              </div>
            );
          })}
        </div>

        <div className={css.helpText}>
          <IconInfo className={css.icon} />
          <div>
            <p>If you don't see the account you want to connect, please ensure that:</p>
            <p>1. Your Instagram account is a business or creator account.</p>
            <p>2. Your Instagram account is connected to a Facebook page.</p>
            <p>3. You give Collabreate all required permissions when logging in with Instagram.</p>
            <br />
            <p>Login again to refresh the list of available accounts.</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

InstagramAccountsModalComponent.defaultProps = {
  creator: null,
  currentUser: null,
  onManageDisableScrolling: null,
};

InstagramAccountsModalComponent.propTypes = {
  creator: propTypes.user,
  currentUser: propTypes.currentUser,
  onManageDisableScrolling: func,
  // from injectIntl
  intl: intlShape.isRequired,
};

const InstagramAccountsModal = injectIntl(InstagramAccountsModalComponent);

export default InstagramAccountsModal;
