import React from 'react';
import { array, bool, func, oneOf, object, shape, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import unionWith from 'lodash/unionWith';
import classNames from 'classnames';
import config from '../../config';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  LayoutSideNavigation,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperAccountSideNav,
  Modal,
  NamedLink,
} from '../../components';
import { TopbarContainer } from '..';

import { searchMapListings, setActiveListing } from './CategoriesPage.duck';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from './CategoriesPage.helpers';
import MainPanel from './MainPanel';
import css from './CategoriesPage.module.css';
import { EnquiryForm, TopbarSearchForm } from '../../forms';
import { ConditionalWrapper } from '../../components/ConditionalWrapper/ConditionalWrapper';
import { requestAddAvailabilityException } from '../MyCalendarPage/MyCalendarPage.duck';
import { filterListing, putDistance } from './Filter.helpers';
import routeConfiguration from '../../routing/routeConfiguration';
import { useState } from 'react';
import { useEffect } from 'react';
import { sendInquiry, setInitialValues } from '../ListingPage/ListingPage.duck';
import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;
// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 24;
const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

export const hasPaidSubscription = subscriptionId => {
  const plans = {
    // TO DO Fetch this id's
    BASIC: 'prod_PmgzN6gDykrUrU',
    DEDICATED: 'prod_Pmh0SUv9ZS4G47',
    TRIAL: 'prod_PsYi9NMcr6j7gl',
  };

  return Object.values(plans).includes(subscriptionId);
};

export const isSubscribtionExpired = expirationTimestamp => {
  //Get seconds because Stripe returns time in seconds
  const currentTimestamp = Date.now() / 1000;
  return currentTimestamp > expirationTimestamp;
};

export const CategoriesPageComponent = props => {
  const {
    intl,
    listings,
    filterConfig,
    sortConfig,
    history,
    location,
    onManageDisableScrolling,
    pagination,
    scrollingDisabled,
    searchInProgress,
    searchListingsError,
    searchParams,
    onActivateListing,
    currentUser,
    isAuthenticated,
    sendEnquiryError,
    sendEnquiryInProgress,
    onSendEnquiry,
    callSetInitialValues,
  } = props;

  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const [isSearchMapOpenOnMobile, setIsSearchMapOpenOnMobile] = useState(props.tab === 'map');
  const [enquiryModalOpen, setEnquiryModalOpen] = useState(false);
  const [messageListing, setMessageListing] = useState();

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  const onOpenMobileModal = () => {
    setIsMobileModalOpen(true);
  };

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  const onCloseMobileModal = () => {
    setIsMobileModalOpen(false);
  };

  const { hasAccess, paidProductId, expires_at, isCreator } =
    currentUser?.attributes?.profile?.protectedData || {};

  useEffect(() => {
    if (currentUser?.attributes?.profile?.protectedData) {
      if (isCreator) {
        history.push(`/creator/${currentUser.id.uuid}`);
      } else if (
        hasAccess ||
        (hasPaidSubscription(paidProductId) && !isSubscribtionExpired(expires_at))
      ) {
        return;
      }
    }
  }, [isCreator, hasAccess]);

  const { mapSearch, page, ...searchInURL } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  // urlQueryParams doesn't contain page specific url params
  // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
  const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

  // Page transition might initially use values from previous search
  const urlQueryString = stringify(urlQueryParams);
  const paramsQueryString = stringify(pickSearchParamsOnly(searchParams, filterConfig, sortConfig));
  //
  const searchParamsAreInSync = urlQueryString === paramsQueryString;

  const validQueryParams = validURLParamsForExtendedData(searchInURL, filterConfig);

  const onMapIconClick = () => {
    useLocationSearchBounds = true;
    setIsSearchMapOpenOnMobile(true);
  };

  const { address, origin } = searchInURL || {};
  const { title, description, schema } = createSearchResultSchema(listings, address, intl);

  //Filter client side
  const filteredListings = filterListing(listings, validQueryParams, currentUser);

  const onSubmitEnquiry = values => {
    if (messageListing) {
      const routes = routeConfiguration();
      const listingId = new UUID(messageListing?.id.uuid);
      const { message } = values;

      onSendEnquiry(listingId, message.trim())
        .then(txId => {
          setEnquiryModalOpen(false);

          // Redirect to OrderDetailsPage
          history.push(
            createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
          );
        })
        .catch(() => {
          // Ignore, error handling in duck file
        });
    }
  };

  const onContactUser = listing => {
    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params?.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      setMessageListing(listing);
      setEnquiryModalOpen(true);
    }
  };

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      description={description}
      title={title}
      schema={schema}
    >
      <LayoutWrapperTopbar>
        <TopbarContainer
          currentPage={'CategoriesPage'}
          desktopClassName={css.desktopTopbar}
          mobileClassName={css.mobileTopbar}
        />
      </LayoutWrapperTopbar>{' '}
      <ConditionalWrapper
        condition={isAuthenticated}
        wrapper={children => (
          <LayoutSideNavigation containerClassName={css.sidebarContainer}>
            {children}
          </LayoutSideNavigation>
        )}
      >
        {isAuthenticated && (
          <LayoutWrapperAccountSideNav
            className={css.sideNav}
            currentTab="CategoriesPage"
            userProfile={true}
            isAvatar={true}
            currentUser={currentUser}
          />
        )}
        <LayoutWrapperMain className={css.wrapperMain}>
          <div className={css.container}>
            <MainPanel
              urlQueryParams={validQueryParams}
              listings={filteredListings}
              searchInProgress={searchInProgress}
              searchListingsError={searchListingsError}
              searchParamsAreInSync={searchParamsAreInSync}
              onActivateListing={onActivateListing}
              onManageDisableScrolling={onManageDisableScrolling}
              onOpenModal={onOpenMobileModal}
              onCloseModal={onCloseMobileModal}
              onMapIconClick={onMapIconClick}
              pagination={pagination}
              searchParamsForPagination={parse(location.search)}
              showAsModalMaxWidth={MODAL_BREAKPOINT}
              history={history}
              origin={origin}
              currentSearchParams={urlQueryParams}
              isAuthenticated={isAuthenticated}
              location={location}
              onMessage={onContactUser}
            />
          </div>
          <Modal
            id="CategoriesPage.enquiry"
            contentClassName={css.enquiryModalContent}
            isOpen={isAuthenticated && enquiryModalOpen}
            onClose={() => {
              setEnquiryModalOpen(false);
              setMessageListing(undefined);
            }}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <EnquiryForm
              className={css.enquiryForm}
              submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
              listingTitle={messageListing?.attributes.title}
              authorDisplayName={messageListing?.author.attributes.profile.displayName}
              sendEnquiryError={sendEnquiryError}
              onSubmit={onSubmitEnquiry}
              inProgress={sendEnquiryInProgress}
            />
          </Modal>
        </LayoutWrapperMain>
      </ConditionalWrapper>
    </Page>
  );
};

CategoriesPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  activeListingId: null,
};

CategoriesPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSearchMapListings: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
    userLocation,
  } = state.SearchPage;
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );
  const { currentUser, currentUserListing, currentUserListingFetched } = state.user;
  const { isAuthenticated } = state.Auth;
  const { sendEnquiryInProgress, sendEnquiryError } = state.ListingPage;

  return {
    listings: putDistance(pageListings, userLocation),
    mapListings,
    pagination,
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    isAuthenticated,
    sendEnquiryInProgress,
    sendEnquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onAddAvailabilityException: params => dispatch(requestAddAvailabilityException(params)),
  onSendEnquiry: (listingId, message) => dispatch(sendInquiry(listingId, message)),
});

const CategoriesPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CategoriesPageComponent);

// const EnhancedCategoriesPage = props => {
//   const routeConfiguration = useRouteConfiguration();

//   return <CategoriesPage routeConfiguration={routeConfiguration} {...props} />;
// };

// export default EnhancedCategoriesPage;
export default CategoriesPage;
