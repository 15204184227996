import React from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import css from './CreatorAnalytics.module.css';
import CreatorAnalyticsEmptyView from './CreatorAnalyticsEmptyView';
import CreatorAnalyticsSection from './CreatorAnalyticsSection';
import useCreatorPrivateView from './useCreatorPrivateView';

const CreatorAnalyticsComponent = ({ creator, currentUser }) => {
  const { connectedInstagram, connectedTikTok } = useCreatorPrivateView({ creator, currentUser });

  if (!connectedTikTok && !connectedInstagram) {
    return <CreatorAnalyticsEmptyView creator={creator} currentUser={currentUser} />;
  }

  return (
    <div className={css.container}>
      <CreatorAnalyticsSection platform="instagram" creator={creator} currentUser={currentUser} />
      <CreatorAnalyticsSection platform="tiktok" creator={creator} currentUser={currentUser} />
    </div>
  );
};

CreatorAnalyticsComponent.defaultProps = {
  creator: null,
  currentUser: null,
};

CreatorAnalyticsComponent.propTypes = {
  creator: propTypes.user,
  currentUser: propTypes.currentUser,
  // from injectIntl
  intl: intlShape.isRequired,
};

const CreatorAnalytics = injectIntl(CreatorAnalyticsComponent);

export default CreatorAnalytics;
