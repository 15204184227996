import React from 'react';
import PropTypes from 'prop-types';
import css from './Input.module.css';

const Input = ({ id, label, value, onChange, placeholder, icon, ...rest }) => {
  return (
    <div className={css.inputContainer}>
      {label && <label htmlFor={id}>{label}</label>}
      <div className={css.inputWrapper}>
        {icon && <span className={css.inputIcon}>{icon}</span>}
        <input
          id={id}
          type="text"
          className={css.input}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          {...rest}
        />
      </div>
    </div>
  );
};

Input.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  icon: PropTypes.node,
};

Input.defaultProps = {
  id: 'input',
  placeholder: 'Input',
  icon: null,
};

export default Input;
