import React, { useEffect, useState } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { ARRAY_ERROR } from 'final-form';
import { Form as FinalForm, Field } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';

// Import configs and util modules
import { FormattedMessage, intlShape, injectIntl } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { nonEmptyArray, composeValidators } from '../../../../util/validators';
import { isUploadImageOverLimitError } from '../../../../util/errors';

// Import shared components
import {
  Button,
  Form,
  AspectRatioWrapper,
  AddImages,
  ValidationError,
  PrimaryButton,
  IconSpinner,
  NamedLink,
} from '../../../../components';

// Import modules from this directory
import ListingImage from './ListingImage';
import css from './EditListingPhotosForm.module.css';
import EasyCrop from '../../../../forms/EditListingPhotosForm/ImageComponent';
import getCroppedImg from '../../../../forms/EditListingPhotosForm/cropImage';
import ImageComponent from '../../../../forms/EditListingPhotosForm/ImageComponent';
import { useSelector } from 'react-redux';
import { PinkButton } from '../../../../components/Button/Button';

const ACCEPT_IMAGES = 'image/*';

// Function to get the extension of a file.
const getExtension = filename => {
  const parts = filename.split('.');
  return parts[parts.length - 1];
};

// Function to check if a file is a video.
const isVideo = file => {
  const ext = getExtension(file.name);
  switch (ext.toLowerCase()) {
    case 'm4v':
    case 'avi':
    case 'mpg':
    case 'mp4':
      // Add more video file extensions if needed.
      return true;
  }
  return false;
};

const RemoveButton = props => {
  const { className, rootClassName, onClick, data } = props;
  const classes = classNames(rootClassName || css.removeButton, className);
  return (
    <button className={classes} onClick={onClick} data-rm-url={data}>
      <svg
        width="10px"
        height="10px"
        viewBox="0 0 10 10"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g strokeWidth="1" fillRule="evenodd">
          <g transform="translate(-821.000000, -311.000000)">
            <g transform="translate(809.000000, 299.000000)">
              <path
                d="M21.5833333,16.5833333 L17.4166667,16.5833333 L17.4166667,12.4170833 C17.4166667,12.1866667 17.2391667,12 17.00875,12 C16.77875,12 16.5920833,12.18625 16.5920833,12.41625 L16.5883333,16.5833333 L12.4166667,16.5833333 C12.18625,16.5833333 12,16.7695833 12,17 C12,17.23 12.18625,17.4166667 12.4166667,17.4166667 L16.5875,17.4166667 L16.5833333,21.5829167 C16.5829167,21.8129167 16.7691667,21.9995833 16.9991667,22 L16.9995833,22 C17.2295833,22 17.41625,21.81375 17.4166667,21.58375 L17.4166667,17.4166667 L21.5833333,17.4166667 C21.8133333,17.4166667 22,17.23 22,17 C22,16.7695833 21.8133333,16.5833333 21.5833333,16.5833333"
                transform="translate(17.000000, 17.000000) rotate(-45.000000) translate(-17.000000, -17.000000) "
              />
            </g>
          </g>
        </g>
      </svg>
    </button>
  );
};

const ImageUploadError = props => {
  return props.uploadOverLimit ? (
    <p className={css.error}>
      <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
    </p>
  ) : props.uploadImageError ? (
    <p className={css.error}>
      <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
    </p>
  ) : null;
};

// NOTE: PublishListingError and ShowListingsError are here since Photos panel is the last visible panel
// before creating a new listing. If that order is changed, these should be changed too.
// Create and show listing errors are shown above submit button
const PublishListingError = props => {
  return props.error ? (
    <p className={css.error}>
      <FormattedMessage id="EditListingPhotosForm.publishListingFailed" />
    </p>
  ) : null;
};

const ShowListingsError = props => {
  return props.error ? (
    <p className={css.error}>
      <FormattedMessage id="EditListingPhotosForm.showListingFailed" />
    </p>
  ) : null;
};

// Field component that uses file-input to allow user to select images.
export const FieldAddImage = props => {
  const { formApi, onImageUploadHandler, aspectWidth = 1, aspectHeight = 1, ...rest } = props;
  return (
    <Field form={null} {...rest}>
      {fieldprops => {
        const { accept, input, label, disabled: fieldDisabled } = fieldprops;
        const { name, type } = input;
        const onChange = e => {
          const file = e.target.files[0];
          formApi.change(`addImage`, file);
          formApi.blur(`addImage`);
          onImageUploadHandler(file);
        };
        const inputProps = { accept, id: name, name, onChange, type };
        return (
          <div className={css.addImageWrapper}>
            <AspectRatioWrapper width={aspectWidth} height={aspectHeight}>
              {fieldDisabled ? null : <input {...inputProps} className={css.addImageInput} />}
              <label htmlFor={name} className={css.addImage}>
                {label}
              </label>
            </AspectRatioWrapper>
          </div>
        );
      }}
    </Field>
  );
};

// Component that shows listing images from "images" field array
const FieldListingImage = props => {
  const { name, intl, onRemoveImage, aspectWidth, aspectHeight, variantPrefix } = props;
  return (
    <Field name={name}>
      {fieldProps => {
        const { input } = fieldProps;
        const image = input.value;
        return image ? (
          <ListingImage
            image={image}
            key={image?.id?.uuid || image?.id}
            className={css.thumbnail}
            savedImageAltText={intl.formatMessage({
              id: 'EditListingPhotosForm.savedImageAltText',
            })}
            onRemoveImage={() => onRemoveImage(image?.id)}
            aspectWidth={aspectWidth}
            aspectHeight={aspectHeight}
            variantPrefix={variantPrefix}
          />
        ) : null;
      }}
    </Field>
  );
};

export const EditListingPhotosFormComponent = props => {
  const [submittedImages, setSubmittedImages] = useState([]);

  const [filePath, setFilePath] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState('');
  const [imageUploadRequested, setImageUploadRequested] = useState(false);
  const [videos, setVideos] = useState(props.videos || []);
  const { uploadInProgress, uploadProgress } = useSelector(state => state.ProfileSettingsPage);

  const onImageUploadHandler = file => {
    const { listingImageConfig, onImageUpload } = props;
    if (file) {
      setImageUploadRequested(true);

      onImageUpload({ id: `${file.name}_${Date.now()}`, file }, listingImageConfig)
        .then(() => {
          setImageUploadRequested(false);
        })
        .catch(() => {
          setImageUploadRequested(false);
        });
    }
  };

  const onVideoUploadHandler = (file, form) => {
    const { onVideoUpload } = props;
    if (file) {
      const tempId = `${file.name}_${Date.now()}`;
      const formData = new FormData();
      formData.append('id', tempId);
      formData.append('videos', file);
      onVideoUpload(formData, res => {
        setVideos([...videos, ...res.urls]);
        form.change('videos', ...res.urls);
      });
    }
  };

  const onVideoRemove = value => {
    setVideos(prev => [...prev.filter(v => v !== value)]);
  };

  return (
    <FinalForm
      {...props}
      onImageUploadHandler={onImageUploadHandler}
      imageUploadRequested={imageUploadRequested}
      onSubmit={v => props.onSubmit({ ...v, videos })}
      // initialValues={images}
      // mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          form,
          className,
          fetchErrors,
          handleSubmit,
          intl,
          invalid,
          onRemoveImage,
          disabled,
          ready,
          saveActionMsg,
          updated,
          updateInProgress,
          touched,
          errors,
          values,
          listingImageConfig,
        } = formRenderProps;

        const images = values.images;
        const { aspectWidth = 1, aspectHeight = 1, variantPrefix } = listingImageConfig;

        const { publishListingError, showListingsError, updateListingError, uploadImageError } =
          fetchErrors || {};
        const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

        // imgs can contain added images (with temp ids) and submitted images with uniq ids.
        const arrayOfImgIds = imgs => imgs.map(i => (typeof i.id === 'string' ? i.imageId : i.id));
        const imageIdsFromProps = arrayOfImgIds(images);
        const imageIdsFromPreviousSubmit = arrayOfImgIds(submittedImages);
        const imageArrayHasSameImages = isEqual(imageIdsFromProps, imageIdsFromPreviousSubmit);
        const submittedOnce = submittedImages.length > 0;
        const pristineSinceLastSubmit = submittedOnce && imageArrayHasSameImages;

        const submitReady = (updated && pristineSinceLastSubmit) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled =
          invalid ||
          videos.length == 0 ||
          disabled ||
          submitInProgress ||
          imageUploadRequested ||
          ready ||
          uploadInProgress;

        const imagesError = touched.images && errors?.images && errors.images[ARRAY_ERROR];

        const classes = classNames(css.root, className);

        const chooseImageText = (
          <span className={css.chooseImageText}>
            <span className={css.chooseImage}>
              <FormattedMessage id="EditListingPhotosForm.chooseImage" />
            </span>
            <span className={css.imageTypes}>
              <FormattedMessage id="EditListingPhotosForm.imageTypes" />
            </span>
          </span>
        );

        const imageRequiredMessage = intl.formatMessage({
          id: 'EditListingPhotosForm.imageRequired',
        });

        const onCropDone = (form, file) => {
          form.change(`addImage`, file);
          form.blur(`addImage`);
          onImageUploadHandler(file);
          setSelectedImage('');
        };

        const showCroppedImage = async form => {
          try {
            const croppedImage = await getCroppedImg(selectedImage, croppedAreaPixels, rotation);
            setCroppedImage(croppedImage);
            const myFile = new File([croppedImage], 'hello.png', {
              type: 'image/png',
            });
            onCropDone(form, myFile);
          } catch (e) {
            console.error(e);
          }
        };

        const setAllCropFeatures = (data, section = 'crop') => {
          if (section === 'crop') {
            setCrop(data);
          } else {
            setCroppedAreaPixels(data);
          }
        };

        const handleVideo = (form, file) => {
          form.change('videos', file);
          form.blur(`videos`);
          onVideoUploadHandler(file, form);
        };

        return (
          <Form
            className={classes}
            onSubmit={e => {
              setSubmittedImages(images);
              handleSubmit(e);
            }}
          >
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.updateFailed" />
              </p>
            ) : null}
            <h2>Videos</h2>
            <div className={css.videosContainer}>
              {videos.map(video => (
                <div key={video} className={css.video}>
                  <video height={320} controls>
                    <source src={video} />
                  </video>
                  <RemoveButton onClick={() => onVideoRemove(video)} />
                </div>
              ))}
            </div>
            <Field type="file" name="videos" accept="video/*">
              {fieldprops => {
                const { accept, input } = fieldprops;
                const { name, type } = input;
                const onChange = e => {
                  handleVideo(form, e.target.files[0]);
                };
                const inputProps = { accept, id: name, name, type, onChange };

                return (
                  <div>
                    <div className={css.addImageWrapper}>
                      <div className={classNames(css.aspectRatioWrapper)}>
                        {uploadInProgress && <IconSpinner className={css.noPreview} />}
                        {uploadProgress && (
                          <span className={css.progress}>
                            {uploadProgress === 100 ? 'Finalizing' : `${uploadProgress}%`}
                          </span>
                        )}
                        <input {...inputProps} className={css.addImageInput} />
                        {!uploadInProgress && (
                          <label htmlFor={name} className={css.addImage}>
                            <span className={css.chooseImageText}>
                              <span className={css.chooseImage}>+ Add a video</span>
                              <span className={css.imageTypes}>.MP4, MKV, or MOV</span>
                            </span>
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                );
              }}
            </Field>

            {/* Important, remove from now, don't delete */}
            {/* <h2>Photos</h2>
            {selectedImage && <ImageComponent onImageUploadHandler={onImageUploadHandler} />}
            <>
              <AddImages
                className={css.imagesField}
                images={images}
                thumbnailClassName={css.thumbnail}
                savedImageAltText={intl.formatMessage({
                  id: 'EditListingPhotosForm.savedImageAltText',
                })}
                onRemoveImage={onRemoveImage}
              >
                <Field
                  id="addImage"
                  name="addImage"
                  accept={ACCEPT_IMAGES}
                  form={null}
                  label={chooseImageText}
                  type="file"
                  disabled={imageUploadRequested}
                >
                  {fieldprops => {
                    const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                    const { name, type } = input;
                    const onChange = e => {
                      setSelectedImage(URL.createObjectURL(e.target.files[0]));
                      setFilePath(e.target.files[0]);
                    };
                    const inputProps = { accept, id: name, name, onChange, type };
                    return (
                      <div>
                        <div className={css.addImageWrapper}>
                          <div className={css.aspectRatioWrapper}>
                            {selectedImage ? (
                              <>
                                <button
                                  type="button"
                                  style={{
                                    border: 'unset',
                                    padding: '0 10px 10px',
                                    background: '#0F69E0',
                                    color: '#fff',
                                    marginLeft: '3px',
                                    borderRadius: '30px',
                                    cursor: 'pointer',
                                    zIndex: 9999999,
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                  }}
                                  onClick={() => showCroppedImage(form)}
                                >
                                  <svg
                                    style={{
                                      width: '100%',
                                      maxWidth: '21px',
                                      height: '100%',
                                      maxHeight: '22px',
                                    }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 50 50"
                                    width="50px"
                                    height="50px"
                                  >
                                    <path d="M 41.9375 8.625 C 41.273438 8.648438 40.664063 9 40.3125 9.5625 L 21.5 38.34375 L 9.3125 27.8125 C 8.789063 27.269531 8.003906 27.066406 7.28125 27.292969 C 6.5625 27.515625 6.027344 28.125 5.902344 28.867188 C 5.777344 29.613281 6.078125 30.363281 6.6875 30.8125 L 20.625 42.875 C 21.0625 43.246094 21.640625 43.410156 22.207031 43.328125 C 22.777344 43.242188 23.28125 42.917969 23.59375 42.4375 L 43.6875 11.75 C 44.117188 11.121094 44.152344 10.308594 43.78125 9.644531 C 43.410156 8.984375 42.695313 8.589844 41.9375 8.625 Z" />
                                  </svg>
                                </button>
                                <EasyCrop
                                  others={{
                                    crop: crop,
                                    zoom: zoom,
                                    rotation: rotation,
                                    croppedAreaPixels: croppedAreaPixels,
                                    croppedImage: croppedImage,
                                  }}
                                  image={selectedImage}
                                  setAllCropFeatures={(data, section) =>
                                    setAllCropFeatures(data, section)
                                  }
                                />
                              </>
                            ) : (
                              <>
                                {fieldDisabled ? null : (
                                  <input {...inputProps} className={css.addImageInput} />
                                )}
                                <label htmlFor={name} className={css.addImage}>
                                  {label}
                                </label>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }}
                </Field>

                <Field
                  component={props => {
                    const { input, meta } = props;
                    return (
                      <div className={css.imageRequiredWrapper}>
                        <input {...input} />
                        <ValidationError fieldMeta={meta} />
                      </div>
                    );
                  }}
                  name="images"
                  type="hidden"
                  // validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
                />
              </AddImages>
            </>
            {imagesError ? <div className={css.arrayError}>{imagesError}</div> : null}
            <ImageUploadError
              uploadOverLimit={uploadOverLimit}
              uploadImageError={uploadImageError}
            />
            <p className={css.tip}>
              <FormattedMessage id="EditListingPhotosForm.addImagesTip" />
            </p> */}

            <PublishListingError error={publishListingError} />
            <ShowListingsError error={showListingsError} />
            <div className={css.buttonContainer}>
              <PrimaryButton
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </PrimaryButton>
              <NamedLink name="ManageListingsPage">Return to Profile</NamedLink>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingPhotosFormComponent.defaultProps = { fetchErrors: null, images: [] };

EditListingPhotosFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
  listingImageConfig: object.isRequired,
};

export default compose(injectIntl)(EditListingPhotosFormComponent);
