import React from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import Form from '../Form/Form';
import InstagramLogo from '../SocialIcons/InstagramLogo';
import TikTokLogo from '../SocialIcons/TikTokLogo';
import css from './ConnectButtons.module.css';
import useCreatorPrivateView from './useCreatorPrivateView';

const ConnectButtonsComponent = ({ creator, currentUser }) => {
  const { isPrivateView, connectedInstagram, connectedTikTok } = useCreatorPrivateView({
    creator,
    currentUser,
  });

  if (!isPrivateView) {
    return null;
  }

  return (
    <div className={css.wrapper}>
      {connectedTikTok || (
        <Form action="/auth/analytics/tiktok" method="POST" className={css.form}>
          <button className={css.button} type="submit">
            <TikTokLogo className={css.tiktokLogo} />
            TikTok
          </button>
        </Form>
      )}

      {connectedInstagram || (
        <Form action="/auth/analytics/instagram" method="POST" className={css.form}>
          <button className={css.button} type="submit">
            <InstagramLogo className={css.instagramLogo} />
            Instagram
          </button>
        </Form>
      )}
    </div>
  );
};

ConnectButtonsComponent.defaultProps = {};

ConnectButtonsComponent.propTypes = {
  creator: propTypes.user,
  intl: intlShape.isRequired,
};

const ConnectButtons = injectIntl(ConnectButtonsComponent);

export default ConnectButtons;
