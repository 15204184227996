import Logo from '../../Logo/Logo';
import NamedLink from '../../NamedLink/NamedLink';
import css from './DesktopToolbar.module.css';
import { PinkButton, SoftBlackButton } from '../../Button/Button';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

const DesktopToolbar = ({ isAuthenticated, onLogout, isDark = false }) => {
  const currentUser = useSelector(state => state?.user?.currentUser);
  const isCreator = currentUser?.attributes?.profile?.protectedData?.isCreator;

  return (
    <div className={`${css.desktopToolbarContainer} ${isDark ? css.bgDark : css.bgWhite}`}>
      <div className={css.desktopToolbar}>
        <NamedLink className={css.home} name="LandingPage">
          <Logo format="mobile" className={css.logo} isDark={isDark} />
        </NamedLink>
        <nav className={css.navbar}>
          <NamedLink
            className={
              isDark ? classNames(css.navigationLinkWhite, css.underlined) : css.navigationLink
            }
            name="BecomeProPage"
          >
            Creators
          </NamedLink>
          <NamedLink
            className={
              isDark ? css.navigationLinkWhite : classNames(css.navigationLink, css.underlined)
            }
            name="LandingPage"
          >
            Brands
          </NamedLink>
        </nav>
        <div className={css.navbarButtons}>
          {isAuthenticated ? (
            <>
              <SoftBlackButton rootClassName={css.logOutButton} onClick={onLogout}>
                Log out
              </SoftBlackButton>
              <NamedLink
                className={classNames(isDark ? css.navigationLinkWhite : css.navigationLink)}
                name={isCreator ? 'ProProfilePage' : 'PartnerDealsPage'}
                params={{ id: currentUser?.id?.uuid || '0' }}
              >
                <button className={css.btn_signup}>Go to your profile</button>
              </NamedLink>
            </>
          ) : (
            <>
              {isDark ? (
                <>
                  <NamedLink className={css.navigationLink} name="LoginPage">
                    <button className={css.btn_login}>Log in</button>
                  </NamedLink>
                  <NamedLink name="SignupPage" className={css.signupLink}>
                    <button className={css.btn_signup}>Sign Up</button>
                  </NamedLink>
                </>
              ) : (
                <>
                  <NamedLink className={css.navigationLink} name="LoginPage">
                    <button className={css.brand_btn_login}>Log in</button>
                  </NamedLink>
                  <NamedLink name="SignupPage" className={css.signupLink}>
                    <button className={css.brand_btn_signup}>Sign up</button>
                  </NamedLink>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DesktopToolbar;
