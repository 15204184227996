import React, { useRef } from 'react';
import { InputFieldComp, SelectFieldComp } from '../../components/CommonComp/FieldComponent';
import { _ } from 'core-js';
import PhoneNumberCountry from '../../components/CommonComp/PhoneNumberCountry';
import getCountryCodes from '../../../translations/countryCodes';
import config from '../../../config';
import DatePickerComp from '../../components/CommonComp/DatePickerComp';

const ProFirstStepFile = props => {
  const { errors, setFieldValue, values } = props;
  const countryCodes = getCountryCodes(config.locale);
  return (
    <div className="fieldsSections">
      <div className="firstDiv">
        <div className="fieldWithLabel">
          <label htmlFor="">Full Name</label>
          <InputFieldComp name="title" placeholder="Full Name" />
        </div>
        <div className="fieldWithLabel">
          <label htmlFor="">Home address</label>
          <InputFieldComp name="homeAddress" placeholder="Home address" />
        </div>{' '}
        {/* <div className="secondDiv"> */}
        <div className="fieldWithLabel">
          <label htmlFor="">Phone Number</label>
          <PhoneNumberCountry {...{ setFieldValue, values }} />
          {errors?.phoneNumber && <div className="error">{errors?.phoneNumber}</div>}
        </div>
        {/* <div className="fieldWithLabel">
            <label htmlFor="">NRIC or FIN</label>
            <InputFieldComp name="nricFin" placeholder="Password" />
          </div> */}
        {/* </div> */}
        <div className="fieldWithLabel">
          <label htmlFor="">Nationality</label>
          <SelectFieldComp
            name="nationality"
            options={_.map(countryCodes, r => ({ label: r?.name, value: r?.code }))}
            error={errors?.nationality}
          />
        </div>
        {/* <div className="fieldWithLabel">
          <label htmlFor="">Residential status</label>
          <SelectFieldComp
            name="usCitizen"
            options={_.map(['Singaporean', 'PR'], (r, i) => ({
              label: r,
              value: i === 0 ? 'yes' : 'no',
            }))}
            error={errors?.usCitizen}
          />
        </div> */}
        <div className="fieldWithLabel">
          <label htmlFor="">Gender</label>
          <SelectFieldComp
            name="gender"
            options={_.map(['male', 'Female', 'other'], row => ({
              label: _.capitalize(row),
              value: row,
            }))}
            error={errors?.gender}
          />
        </div>
        <div className="fieldWithLabel">
          <label htmlFor="">Date of birth</label>
          <DatePickerComp {...{ setFieldValue }} />
          <div className="error">{errors.DOB}</div>
        </div>
        <div className="fieldWithLabel">
          <label htmlFor="">How did you hear about Collabreate</label>
          <SelectFieldComp
            name="hearFrom"
            options={_.map(
              [
                'facebook',
                'linkedin ',
                'instagram',
                'twitter',
                'tiktok',
                'telegram',
                'email',
                'referred by a friend',
                'others',
              ],
              r => ({
                label: _.capitalize(r),
                value: r,
              })
            )}
            error={errors?.hearFrom}
          />
        </div>{' '}
      </div>
    </div>
  );
};

export default ProFirstStepFile;
