// Function to get the extension of a file.
const getExtension = filename => {
  const parts = filename.split('.');
  return parts[parts.length - 1];
};

// Function to check if a file is a video.
export const isVideo = file => {
  const ext = getExtension(file.name || file.originalname);
  switch (ext.toLowerCase()) {
    case 'm4v':
    case 'avi':
    case 'mpg':
    case 'mp4':
    case 'mov':
    case 'mkv':
      // Add more video file extensions if needed.
      return true;
  }
  return false;
};
