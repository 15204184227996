import React from 'react';
import { bool, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { fetchCurrentUser, sendVerificationEmail } from '../../ducks/user.duck';
import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  LayoutWrapperAccountSideNav,
  NamedLink,
} from '../../components';
import { ContactDetailsForm } from '../../forms';
import { TopbarContainer } from '../../containers';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import { saveContactDetails, saveContactDetailsClear } from './ContactDetailsPage.duck';
import css from './ContactDetailsPage.module.css';
import classNames from 'classnames';

export const ContactDetailsPageComponent = props => {
  const {
    saveEmailError,
    savePhoneNumberError,
    saveEmergencyContactError,
    saveHomeAddressError,
    saveContactDetailsInProgress,
    currentUser,
    currentUserListing,
    contactDetailsChanged,
    onChange,
    scrollingDisabled,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    onSubmitContactDetails,
    intl,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const currentEmail = user.attributes.email || '';
  const protectedData = user.attributes.profile.protectedData || {};
  const privateData = user.attributes.profile.privateData;
  const currentPhoneNumber = protectedData.phoneNumber || '';
  const currentPhoneNumberCountryCode = protectedData.phoneNumberCountryCode;
  const currentEmergencyContactName = protectedData?.emergencyContactName || '';
  const currentEmergencyPhoneNumber = protectedData?.emergencyPhoneNumber || '';
  const currentEmergencyPhoneCountrycode = protectedData?.emergencyCountryCode;
  const currentHomeAddress = privateData?.homeAddress;
  const { isCreator } = user?.attributes?.profile?.protectedData || {};

  const contactInfoForm = user.id ? (
    <ContactDetailsForm
      className={css.form}
      initialValues={{
        email: currentEmail,
        phoneNumber: currentPhoneNumber,
        phoneNumberCountryCode: currentPhoneNumberCountryCode,
        emergencyContactName: currentEmergencyContactName,
        emergencyPhoneNumber: currentEmergencyPhoneNumber,
        emergencyCountryCode: currentEmergencyPhoneCountrycode,
        homeAddress: currentHomeAddress,
      }}
      saveEmailError={saveEmailError}
      savePhoneNumberError={savePhoneNumberError}
      saveEmergencyContactError={saveEmergencyContactError}
      saveHomeAddressError={saveHomeAddressError}
      currentUser={currentUser}
      onResendVerificationEmail={onResendVerificationEmail}
      onSubmit={values =>
        onSubmitContactDetails({
          ...values,
          currentEmail,
          currentPhoneNumber,
          currentPhoneNumberCountryCode,
          currentEmergencyContactName,
          currentEmergencyPhoneNumber,
          currentEmergencyPhoneCountrycode,
          currentHomeAddress,
        })
      }
      onChange={onChange}
      inProgress={saveContactDetailsInProgress}
      ready={contactDetailsChanged}
      sendVerificationEmailInProgress={sendVerificationEmailInProgress}
      sendVerificationEmailError={sendVerificationEmailError}
    />
  ) : null;

  const title = intl.formatMessage({ id: 'ContactDetailsPage.title' });

  return (
    <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation containerClassName={css.sidebarContainer}>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="ContactDetailsPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          {/*<UserNav selectedPageName="ContactDetailsPage" listing={currentUserListing} />*/}
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSideNav
          currentTab="ContactDetailsPage"
          userProfile={true}
          isProUser={false}
        />

        <LayoutWrapperMain className={css.wrapperMain}>
          <div className={css.content}>
            <h1 className={css.header}>Settings</h1>
            <div className={css.switcherContainer}>
              <div name="ContactDetailsPage" className={css.switcher}>
                <NamedLink name="ProfileSettingsPage" className={css.switcherButton}>
                  Edit profile
                </NamedLink>
                <NamedLink
                  name="ContactDetailsPage"
                  className={classNames(css.switcherButton, css.active)}
                >
                  Contact details
                </NamedLink>
                <NamedLink name="PasswordChangePage" className={css.switcherButton}>
                  Password
                </NamedLink>
                {currentUserListing && (
                  <NamedLink name="StripePayoutPage" className={css.switcherButton}>
                    Payout details
                  </NamedLink>
                )}
                {!isCreator && (
                  <NamedLink name="PaymentMethodsPage" className={css.switcherButton}>
                    Payment method
                  </NamedLink>
                )}
              </div>
            </div>
            <div className={css.subHeaderContainer}>
              <div>
                <h2 className={css.subheader}>Contact details</h2>
              </div>
              <div className={css.horizontalRule} />
              {contactInfoForm}
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

ContactDetailsPageComponent.defaultProps = {
  saveEmailError: null,
  savePhoneNumberError: null,
  saveEmergencyContactError: null,
  saveHomeAddressError: null,
  currentUser: null,
  sendVerificationEmailError: null,
};

ContactDetailsPageComponent.propTypes = {
  saveEmailError: propTypes.error,
  savePhoneNumberError: propTypes.error,
  saveEmergencyContactError: propTypes.error,
  saveHomeAddressError: propTypes.error,
  saveContactDetailsInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserListing: propTypes.ownListing,
  contactDetailsChanged: bool.isRequired,
  onChange: func.isRequired,
  onSubmitContactDetails: func.isRequired,
  scrollingDisabled: bool.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const {
    currentUser,
    currentUserListing,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  } = state.user;
  const {
    saveEmailError,
    savePhoneNumberError,
    saveEmergencyContactError,
    saveHomeAddressError,
    saveContactDetailsInProgress,
    contactDetailsChanged,
  } = state.ContactDetailsPage;
  return {
    saveEmailError,
    savePhoneNumberError,
    saveEmergencyContactError,
    saveHomeAddressError,
    saveContactDetailsInProgress,
    currentUser,
    currentUserListing,
    contactDetailsChanged,
    scrollingDisabled: isScrollingDisabled(state),
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: () => dispatch(saveContactDetailsClear()),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onSubmitContactDetails: values => dispatch(saveContactDetails(values)),
});

const ContactDetailsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ContactDetailsPageComponent);

ContactDetailsPage.loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};

export default ContactDetailsPage;
