import React, { Component, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { REVIEW_TYPE_OF_PROVIDER, REVIEW_TYPE_OF_CUSTOMER, propTypes } from '../../util/types';
import { ensureCurrentUser, ensureUser } from '../../util/data';
import { withViewport } from '../../util/contextHelpers';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  AvatarLarge,
  NamedLink,
  Reviews,
  ButtonTabNavHorizontal,
  ManageListingCard,
  Avatar,
  Modal,
  LayoutWrapperAccountSideNav,
  ExternalLink,
  Heading,
  StripeConnectAccountStatusBox,
  MenuContent,
  MenuItem,
  MenuLabel,
  Menu,
  CreatorAnalytics,
  CreatorAnalyticsNotice,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';
import config from '../../config';
import facebookIcon from './FacebookLogo.svg';
import instagramIcon from './InstagramLogo.svg';
import tiktokIcon from './TiktokLogo.svg';
import twitterIcon from './TwitterLogo.svg';
import globalIcon from './global.svg';
import youtubeIcon from './YoutubeLogo.svg';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  closeListing,
  openListing,
  getOwnListingsById,
  queryOwnListings,
} from '../ManageListingsPage/ManageListingsPage.duck';

import css from './ProfilePage.module.css';
import classNames from 'classnames';
import { EnquiryForm, StripeConnectAccountForm } from '../../forms';
import { sendInquiry, setInitialValues } from '../ListingPage/ListingPage.duck';
import routeConfiguration from '../../routing/routeConfiguration';
import {
  getStripeConnectAccountLink,
  stripeAccountClearError,
} from '../../ducks/stripeConnectAccount.duck';
import {
  getBankAccountLast4Digits,
  getStripeAccountData,
  handleGetStripeConnectAccountLinkFn,
  hasRequirements,
} from '../NewEditListingPage/EditListingWizard/EditListingWizard';
import {
  STRIPE_ONBOARDING_RETURN_URL_FAILURE,
  STRIPE_ONBOARDING_RETURN_URL_SUCCESS,
  createReturnURL,
} from '../StripePayoutPage/StripePayoutPage';
import { savePayoutDetails } from '../EditListingPage/EditListingPage.duck';
import { requestUpdateListingInProfile } from '../NewEditListingPage/EditListingPage.duck';
import { updateFavorite } from '../../ducks/Favorite.duck';
import { useHistory } from 'react-router-dom';
import FileUpload from '../../components/FileUploader/FileUploader';

// import { closeListing } from '../ManageListingsPage/ManageListingsPage.duck';
const { UUID } = sdkTypes;
const MAX_MOBILE_SCREEN_WIDTH = 768;

const sections = [
  {
    id: 'listings',
    title: 'Listings',
    route: 'CreatorListingsPage',
    publicRoute: 'CreatorListingsPagePublic',
  },
  {
    id: 'reviews',
    title: 'Reviews',
    route: 'CreatorReviewsPage',
    publicRoute: 'CreatorReviewsPagePublic',
  },
  {
    id: 'portfolio',
    title: 'Portfolio',
    route: 'CreatorResumePage',
    publicRoute: 'CreatorResumePagePublic',
  },
  // {
  //   id: 'analytics',
  //   title: 'Analytics',
  //   route: 'CreatorAnalyticsPage',
  //   publicRoute: 'CreatorAnalyticsPagePublic',
  // },
  {
    id: 'internal-notes',
    guard: 'admin',
    title: 'Internal Notes',
    route: 'CreatorInternalNotesPage',
    publicRoute: 'CreatorInternalNotesPagePublic',
  },
];

export const ProfilePageComponent = props => {
  const {
    scrollingDisabled,
    currentUser,
    user,
    listings,
    userShowError,
    reviews,
    queryReviewsError,
    intl,
    onManageDisableScrolling,
    isAuthenticated,
    closingListing,
    closingListingError,
    onCloseListing,
    onOpenListing,
    openingListing,
    openingListingError,
    sendEnquiryInProgress,
    sendEnquiryError,
    location,
    onGetStripeConnectAccountLink,
    getAccountLinkInProgress,
    getAccountLinkError,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
    stripeAccountFetched,
    payoutDetailsSaveInProgress,
    payoutDetailsSaved,
    callSetInitialValues,
    params,
    onPayoutDetailsChange,
    onPayoutDetailsSubmit,
    onSendEnquiry,
    onUpdateFavorites,
    onUpdateListing,
    isCreator,
  } = props;

  const history = useHistory();
  const [showReviewsType, setShowReviewsType] = useState(REVIEW_TYPE_OF_PROVIDER);
  const [activeSection, setActiveSection] = useState(sections[0]);
  const [listingMenuOpen, setListingMenuOpen] = useState(null);
  const [enquiryModalOpen, setEnquiryModalOpen] = useState(false);
  const [isAboutExpand, setIsAboutExpand] = useState(false);
  const [stripeModalOpened, setStripeModalOpened] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);

  const [selectedListing, setSelectedListing] = useState('');

  const [showResumeInput, setShowResumeInput] = useState(false);

  const onToggleMenu = listing => {
    setListingMenuOpen(listing);
  };

  const showOfProviderReviews = () => {
    setShowReviewsType(REVIEW_TYPE_OF_PROVIDER);
  };

  const showOfCustomerReviews = () => {
    setShowReviewsType(REVIEW_TYPE_OF_CUSTOMER);
  };

  const onContactUser = () => {
    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params?.id });

      // signup and return back to listingPage.
      history?.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      setEnquiryModalOpen(true);
    }
  };

  const onSubmitEnquiry = values => {
    const routes = routeConfiguration();
    const listingId = selectedListing?.id?.uuid;
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        setEnquiryModalOpen(false);

        // Redirect to OrderDetailsPage
        history.push(`/order/${txId.uuid}`);
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  };

  const isPro = location.pathname.includes('/creator/');
  const closingErrorListingId = !!closingListingError && closingListingError.listingId;
  const openingErrorListingId = !!openingListingError && openingListingError.listingId;

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const profileUser = ensureUser(user);
  const isCurrentUser =
    ensuredCurrentUser.id && profileUser.id && ensuredCurrentUser.id.uuid === profileUser.id.uuid;
  const role = currentUser?.attributes?.profile?.publicData?.activity;
  const displayName =
    user?.attributes?.profile?.publicData?.displayName || user?.attributes?.profile?.displayName;
  const bio = profileUser.attributes.profile.bio;
  const isAdmin = currentUser?.attributes?.profile?.protectedData?.role === 'admin';
  const internalNotes = user?.attributes?.profile?.publicData?.internalNotes;

  const protectedData = user?.attributes?.profile?.protectedData;
  const publicData = user?.attributes?.profile?.publicData;

  // const hasBio = !!bio;
  const aboutText = bio;
  // const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  const { returnURLType } = params;

  const rootURL = config.canonicalRootURL;

  const routes = routeConfiguration();

  const successURL = createReturnURL(STRIPE_ONBOARDING_RETURN_URL_SUCCESS, rootURL, routes);
  const failureURL = createReturnURL(STRIPE_ONBOARDING_RETURN_URL_FAILURE, rootURL, routes);
  const currentUserLoaded = !!ensuredCurrentUser.id;
  const stripeConnected = currentUserLoaded && !!stripeAccount && !!stripeAccount.id;
  const accountId = stripeConnected ? stripeAccount.id : null;

  const handleGetStripeConnectAccountLink = handleGetStripeConnectAccountLinkFn(
    onGetStripeConnectAccountLink,
    {
      accountId,
      successURL,
      failureURL,
    }
  );

  const stripeAccountError =
    createStripeAccountError || updateStripeAccountError || fetchStripeAccountError;

  const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;

  const requirementsMissing =
    stripeAccount &&
    (hasRequirements(stripeAccountData, 'past_due') ||
      hasRequirements(stripeAccountData, 'currently_due'));

  const returnedAbnormallyFromStripe = returnURLType === STRIPE_ONBOARDING_RETURN_URL_FAILURE;
  const savedCountry = stripeAccountData ? stripeAccountData.country : null;
  const showVerificationNeeded = stripeConnected && requirementsMissing;

  const formDisabled = getAccountLinkInProgress;

  const handleStripeModalClose = () => {
    setStripeModalOpened(false);
  };

  const handleResumeModalClose = () => {
    setShowResumeInput(false);
  };

  const sitizen = currentUser?.attributes?.profile?.publicData?.usCitizen;

  useEffect(() => {
    if (
      currentUser?.attributes?.profile?.protectedData?.isCreator &&
      currentUserLoaded &&
      !(!!stripeAccount && !!stripeAccount?.id) &&
      sitizen !== 'ID' &&
      sitizen !== 'MY' &&
      sitizen !== 'PH' &&
      sitizen !== 'TH' &&
      sitizen !== 'TW' &&
      sitizen !== 'HK' &&
      sitizen !== 'VN' &&
      sitizen !== 'JP' &&
      sitizen !== 'KR'
    ) {
      setStripeModalOpened(true);
    }
  }, [currentUserLoaded]);

  useEffect(() => {
    if (isPro && isOwnListing) {
      listings.map(l => {
        if (!l?.attributes?.publicData?.authorId || !l?.attributes?.publicData?.displayName) {
          const idObj = { id: l.id };
          const authorId = currentUser?.id?.uuid;
          const displayName = currentUser?.attributes?.profile?.publicData?.displayName;

          onUpdateListing(idObj, authorId, displayName);
        }
      });
    }
  }, [listings.length]);

  const editLinkMobile = isCurrentUser ? (
    <NamedLink className={css.editLinkMobile} name="ProfileSettingsPage">
      <FormattedMessage id="ProfilePage.editProfileLinkMobile" />
    </NamedLink>
  ) : null;
  const editLinkDesktop = isCurrentUser ? (
    <NamedLink className={css.editLinkDesktop} name="ProfileSettingsPage">
      <FormattedMessage id="ProfilePage.editProfileLinkDesktop" />
    </NamedLink>
  ) : null;

  const asideContent = (
    <div className={css.asideContent}>
      <AvatarLarge className={css.avatar} user={user} disableProfileLink />
      <h1 className={css.mobileHeading}>
        {displayName ? (
          <FormattedMessage id="ProfilePage.mobileHeading" values={{ name: displayName }} />
        ) : null}
      </h1>
      {editLinkMobile}
      {editLinkDesktop}
    </div>
  );

  const reviewsError = (
    <p className={css.error}>
      <FormattedMessage id="ProfilePage.loadingReviewsFailed" />
    </p>
  );

  const reviewsOfProvider = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_PROVIDER);

  const reviewsOfCustomer = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_CUSTOMER);

  const mobileReviews = (
    <div className={css.mobileReviews}>
      <h2 className={css.mobileReviewsTitle}>
        <FormattedMessage
          id="ProfilePage.reviewsOfProviderTitle"
          values={{ count: reviewsOfProvider.length }}
        />
      </h2>
      {queryReviewsError ? reviewsError : null}
      <Reviews reviews={reviewsOfProvider} />
      <h2 className={css.mobileReviewsTitle}>
        <FormattedMessage
          id="ProfilePage.reviewsOfCustomerTitle"
          values={{ count: reviewsOfCustomer.length }}
        />
      </h2>
      {queryReviewsError ? reviewsError : null}
      <Reviews reviews={reviewsOfCustomer} />
    </div>
  );

  const desktopReviewTabs = [
    {
      text: (
        <h3 className={css.desktopReviewsTitle}>
          <FormattedMessage
            id="ProfilePage.reviewsOfProviderTitle"
            values={{ count: reviewsOfProvider.length }}
          />
        </h3>
      ),
      selected: showReviewsType === REVIEW_TYPE_OF_PROVIDER,
      onClick: showOfProviderReviews,
    },
    {
      text: (
        <h3 className={css.desktopReviewsTitle}>
          <FormattedMessage
            id="ProfilePage.reviewsOfCustomerTitle"
            values={{ count: reviewsOfCustomer.length }}
          />
        </h3>
      ),
      selected: showReviewsType === REVIEW_TYPE_OF_CUSTOMER,
      onClick: showOfCustomerReviews,
    },
  ];

  const desktopReviews = (
    <div className={css.desktopReviews}>
      <ButtonTabNavHorizontal className={css.desktopReviewsTabNav} tabs={desktopReviewTabs} />

      {queryReviewsError ? reviewsError : null}

      {showReviewsType === REVIEW_TYPE_OF_PROVIDER ? (
        <Reviews reviews={reviewsOfProvider} />
      ) : (
        <Reviews reviews={reviewsOfCustomer} />
      )}
    </div>
  );

  const panelWidth = 62.5;
  const renderSizes = [
    `(max-width: 178px) 100vw`,
    `(max-width: 281px) ${panelWidth / 2}vw`,
    `${panelWidth / 3}vw`,
  ].join(', ');

  const getCategories = listings => {
    const result = [];
    listings.map(l => {
      result.push(
        Array.isArray(l?.attributes?.publicData?.category)
          ? l?.attributes?.publicData?.category[0]
          : l?.attributes?.publicData?.category
      );
    });
    return new Set(result);
  };
  const categories = getCategories(listings);

  const isAboutTextShort = aboutText?.length < 117;

  const userId = user?.id?.uuid;
  const favorites = currentUser?.attributes?.profile?.privateData.favorites || [];
  const isFavorite = favorites?.some(value => userId === value) || false;

  const handleOnFavorite = () => {
    const { currentUser, routes, location } = props;

    // Only allow signed-in users to save favorites
    if (!currentUser) {
      const state = {
        from: `${location.pathname}${location.search}${location.hash}`,
      };

      // Sign up and return back to the listing page.
      history.push(createResourceLocatorString('SignupPage', routes, {}, {}), state);
    } else {
      const { onUpdateFavorites } = props;
      const {
        attributes: { profile },
      } = currentUser;

      let payload;

      if (!profile.privateData || !profile.privateData?.favorites) {
        payload = {
          privateData: {
            favorites: [userId],
          },
        };
      } else if (isFavorite) {
        payload = {
          privateData: {
            favorites: favorites.filter(f => f !== userId),
          },
        };
      } else {
        payload = {
          privateData: {
            favorites: [...favorites, userId],
          },
        };
      }
      onUpdateFavorites(payload);
    }
  };

  const copyUrlPage = () => {
    const userId = user?.id?.uuid;
    const userProfileLink = `${config?.canonicalRootURL}/u/${userId}`;
    navigator.clipboard.writeText(userProfileLink);
    setCopySuccess(true);
  };

  //fix bug when login as creator and redirected to CreatorListingsPagePublic, because currentUser is undefined at the beginning
  const isOwnListing = isCreator;

  //useEffect timeout for copy success message
  useEffect(() => {
    if (copySuccess) {
      const timeout = setTimeout(() => {
        setCopySuccess(false);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [copySuccess]);

  const getSectionPathname = useCallback(
    section => {
      const userId = user?.id?.uuid;
      if (!userId) {
        return null;
      }
      const sectionRoute = isOwnListing && isPro ? section.route : section.publicRoute;
      return routes.find(route => route.name === sectionRoute).path.replace(':id', userId);
    },
    [user?.id?.uuid]
  );

  const navigateToSection = section => {
    const path = getSectionPathname(section);
    if (path) {
      history.replace({ pathname: getSectionPathname(section) });
    }
  };

  useEffect(() => {
    const pathName = history.location.pathname;
    const section = sections.find(section => pathName === getSectionPathname(section));
    if (section) {
      setActiveSection(section);
    } else {
      navigateToSection(sections[0]);
    }
  }, [history.location.pathname, user?.id?.uuid]);

  const activity = user?.attributes?.profile?.publicData?.activity;
  const niches = user?.attributes?.profile?.publicData?.niches;
  const languages = user?.attributes?.profile?.publicData?.languages;

  const mainContent = (
    <div className={css.page}>
      <div className={css.rootWrapper}>
        <div className={css.container}>
          <div className={css.topbar}>
            <div className={css.avatarContainer}>
              <AvatarLarge className={css.avatar} user={user} disableProfileLink />
            </div>
            <div className={css.headerContainerWrapper}>
              <div className={css.headerContainer}>
                <div>
                  <h1 className={css.desktopHeading}>{displayName}</h1>
                  <p className={css.subTitle}>{activity}</p>
                </div>
                <div className={css.buttonsContainer}>
                  {isPro && isOwnListing ? (
                    <div className={css.buttons}>
                      <NamedLink name="ProfileSettingsPage" className={css.buttonLink}>
                        <div className={css.buttonContainer}>Edit profile</div>
                      </NamedLink>
                      <div>
                        <Menu className={css.menu} contentPosition="left" useArrow={false}>
                          <MenuLabel className={css.menuLabel}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M3.375 9C3.375 8.37868 3.87868 7.875 4.5 7.875C5.12132 7.875 5.625 8.37868 5.625 9C5.625 9.62132 5.12132 10.125 4.5 10.125C3.87868 10.125 3.375 9.62132 3.375 9ZM7.875 9C7.875 8.37868 8.37868 7.875 9 7.875C9.62132 7.875 10.125 8.37868 10.125 9C10.125 9.62132 9.62132 10.125 9 10.125C8.37868 10.125 7.875 9.62132 7.875 9ZM12.375 9C12.375 8.37868 12.8787 7.875 13.5 7.875C14.1213 7.875 14.625 8.37868 14.625 9C14.625 9.62132 14.1213 10.125 13.5 10.125C12.8787 10.125 12.375 9.62132 12.375 9Z"
                                fill="#494656"
                              />
                            </svg>
                          </MenuLabel>
                          <MenuContent className={css.menuContent}>
                            <MenuItem key="profile" className={css.menuItem}>
                              {ensuredCurrentUser?.id?.uuid && (
                                <NamedLink
                                  name={activeSection.publicRoute}
                                  params={{
                                    id: ensuredCurrentUser?.id?.uuid,
                                  }}
                                  className={css.buttonLink}
                                >
                                  <div className={css.menuButton}>View your public account</div>
                                </NamedLink>
                              )}
                            </MenuItem>
                            <MenuItem key="copy" className={css.menuItem}>
                              <div style={{ cursor: 'pointer' }} onClick={copyUrlPage}>
                                {copySuccess ? (
                                  <div className={css.menuButton}>Link copied successfully</div>
                                ) : (
                                  <div className={css.menuButton}>Copy profile link</div>
                                )}
                              </div>
                            </MenuItem>
                          </MenuContent>
                        </Menu>
                      </div>
                    </div>
                  ) : (
                    <>
                      <MenuItem key="copy" className={css.menuItem}>
                        <div style={{ cursor: 'pointer' }} onClick={copyUrlPage}>
                          <div className={css.bookmarkButtonContainer}>
                            {!copySuccess && (
                              <span className={css.icon}>
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.0088 2.80979C10.8875 1.93111 12.3121 1.93111 13.1908 2.80979C14.0695 3.68847 14.0695 5.11309 13.1908 5.99177L12.0889 7.09367C11.8253 7.35727 11.8253 7.78466 12.0889 8.04827C12.3525 8.31187 12.7799 8.31187 13.0435 8.04827L14.1454 6.94637C15.5513 5.54048 15.5513 3.26108 14.1454 1.8552C12.7395 0.449309 10.4601 0.449309 9.05422 1.8552L6.35422 4.5552C4.94833 5.96108 4.94833 8.24048 6.35422 9.64636C6.41985 9.71199 6.48747 9.77463 6.55693 9.83426C6.83977 10.0771 7.26592 10.0447 7.50877 9.76186C7.75162 9.47902 7.7192 9.05286 7.43636 8.81001C7.39281 8.77262 7.35025 8.73321 7.30881 8.69177C6.43013 7.81309 6.43013 6.38847 7.30881 5.50979L10.0088 2.80979Z"
                                    fill="#100C20"
                                  />
                                  <path
                                    d="M9.44268 6.16729C9.15984 5.92444 8.73369 5.95686 8.49084 6.23969C8.24799 6.52253 8.28041 6.94869 8.56325 7.19154C8.6068 7.22894 8.64936 7.26834 8.6908 7.30978C9.56947 8.18846 9.56947 9.61308 8.6908 10.4918L5.9908 13.1918C5.11212 14.0704 3.68749 14.0704 2.80881 13.1918C1.93013 12.3131 1.93013 10.8885 2.80881 10.0098L3.91072 8.90788C4.17432 8.64428 4.17432 8.21689 3.91072 7.95329C3.64711 7.68968 3.21973 7.68968 2.95612 7.95329L1.85422 9.05519C0.448333 10.4611 0.448333 12.7405 1.85422 14.1464C3.26011 15.5522 5.5395 15.5522 6.94539 14.1464L9.64539 11.4464C11.0513 10.0405 11.0513 7.76107 9.64539 6.35519C9.57976 6.28956 9.51213 6.22692 9.44268 6.16729Z"
                                    fill="#100C20"
                                  />
                                </svg>
                              </span>
                            )}
                            <span className={css.menuButton}>
                              {copySuccess ? 'Link copied successfully' : 'Copy link'}
                            </span>
                          </div>
                        </div>
                      </MenuItem>
                      <div className={css.bookmarkButtonContainer} onClick={handleOnFavorite}>
                        <div className={css.icon}>
                          {isFavorite ? (
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="left_icon">
                                <path
                                  id="Intersect"
                                  d="M13.195 2.49181C14.0204 2.5876 14.625 3.29925 14.625 4.13024V15.75L9 12.9375L3.375 15.75V4.13024C3.375 3.29925 3.97956 2.5876 4.80501 2.49181C6.18117 2.3321 7.581 2.25 9 2.25C10.419 2.25 11.8188 2.3321 13.195 2.49181Z"
                                  stroke="#100C20"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                            </svg>
                          ) : (
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="left_icon">
                                <path
                                  id="Intersect"
                                  d="M13.195 2.49181C14.0204 2.5876 14.625 3.29925 14.625 4.13024V15.75L9 12.9375L3.375 15.75V4.13024C3.375 3.29925 3.97956 2.5876 4.80501 2.49181C6.18117 2.3321 7.581 2.25 9 2.25C10.419 2.25 11.8188 2.3321 13.195 2.49181Z"
                                  stroke="#100C20"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  fill="none"
                                />
                              </g>
                            </svg>
                          )}
                        </div>
                        {isFavorite ? 'Saved' : 'Save'}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className={css.labelsContainer}>
                {niches?.map(n => (
                  <span key={n} className={css.niche}>
                    {n}
                  </span>
                ))}
                {languages?.map(l => (
                  <span key={l} className={css.category}>
                    {l}
                  </span>
                ))}
              </div>
            </div>
          </div>

          {/* Disable for now... */}
          {/* <CreatorAnalyticsNotice
            creator={user}
            currentUser={currentUser}
            onManageDisableScrolling={onManageDisableScrolling}
          /> */}

          <div className={css.infoBlock}>
            {aboutText && (
              <div className={css.aboutContainer}>
                <p className={css.blockTitle}>About</p>
                <div className={css.mobileAbout}>
                  {isAboutTextShort ? (
                    <div>{aboutText}</div>
                  ) : (
                    <div>
                      {isAboutExpand ? (
                        <div>
                          {aboutText}
                          <div
                            onClick={() => setIsAboutExpand(false)}
                            className={css.showMoreButton}
                          >
                            Show less
                          </div>
                        </div>
                      ) : (
                        <div>
                          <span>{`${aboutText?.substring(0, 117)}...`}</span>
                          <div
                            onClick={() => setIsAboutExpand(true)}
                            className={css.showMoreButton}
                          >
                            Show more
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {aboutText && <div className={css.desktopAbout}>{aboutText}</div>}
              </div>
            )}

            <div className={css.socialLinksContainer}>
              <div className={css.linkHeaderContainer}>
                <p className={css.blockTitle}>Links</p>
                {isPro && isOwnListing && (
                  <NamedLink name="ProfileSettingsPage">Edit links</NamedLink>
                )}
              </div>

              <div className={css.socialLinks}>
                <div className={classNames(css.socialLinksColumn, css.hideOnMobile)}>
                  {publicData?.instagram && (
                    <ExternalLink className={css.socialLink} href={publicData?.instagram}>
                      <img className={css.socialIcon} src={instagramIcon} alt="instagram"></img>
                      <span className={css.socialTitle}>Instagram</span>
                    </ExternalLink>
                  )}
                  {publicData?.twitter && (
                    <ExternalLink className={css.socialLink} href={publicData?.twitter}>
                      <img className={css.socialIcon} src={twitterIcon} alt="Twitter"></img>
                      <span className={css.socialTitle}>Twitter</span>
                    </ExternalLink>
                  )}
                  {publicData?.tiktok && (
                    <ExternalLink className={css.socialLink} href={publicData?.tiktok}>
                      <img className={css.socialIcon} src={tiktokIcon} alt="TikTok"></img>
                      <span className={css.socialTitle}>TikTok</span>
                    </ExternalLink>
                  )}
                </div>
                <div className={classNames(css.socialLinksColumn, css.hideOnMobile)}>
                  {publicData?.facebook && (
                    <ExternalLink className={css.socialLink} href={publicData?.facebook}>
                      <img className={css.socialIcon} src={facebookIcon} alt="Facebook"></img>
                      <span className={css.socialTitle}>Facebook</span>
                    </ExternalLink>
                  )}
                  {publicData?.youtube && (
                    <ExternalLink className={css.socialLink} href={publicData?.youtube}>
                      <img className={css.socialIcon} src={youtubeIcon} alt="YouTube"></img>
                      <span className={css.socialTitle}>YouTube</span>
                    </ExternalLink>
                  )}
                  {publicData?.website && (
                    <ExternalLink className={css.socialLink} href={publicData?.website}>
                      <img className={css.socialIcon} src={globalIcon} alt="Personal Site"></img>
                      <span className={css.socialTitle}>Personal Site</span>
                    </ExternalLink>
                  )}
                </div>
                {/** MOBILE VIEW */}
                <div className={classNames(css.socialLinksColumn, css.showOnMobile)}>
                  {publicData?.instagram && (
                    <ExternalLink className={css.socialLink} href={publicData?.instagram}>
                      <img className={css.socialIcon} src={instagramIcon} alt="Instagram"></img>
                      <span className={css.socialTitle}>Instagram</span>
                    </ExternalLink>
                  )}
                  {publicData?.twitter && (
                    <ExternalLink className={css.socialLink} href={publicData?.twitter}>
                      <img className={css.socialIcon} src={twitterIcon} alt="Twitter"></img>
                      <span className={css.socialTitle}>Twitter</span>
                    </ExternalLink>
                  )}
                  {publicData?.tiktok && (
                    <ExternalLink className={css.socialLink} href={publicData?.tiktok}>
                      <img className={css.socialIcon} src={tiktokIcon} alt="TikTok"></img>
                      <span className={css.socialTitle}>TikTok</span>
                    </ExternalLink>
                  )}
                  {publicData?.facebook && (
                    <ExternalLink className={css.socialLink} href={publicData?.facebook}>
                      <img className={css.socialIcon} src={facebookIcon} alt="Facebook"></img>
                      <span className={css.socialTitle}>Facebook</span>
                    </ExternalLink>
                  )}
                  {publicData?.youtube && (
                    <ExternalLink className={css.socialLink} href={publicData?.youtube}>
                      <img className={css.socialIcon} src={youtubeIcon} alt="YouTube"></img>
                      <span className={css.socialTitle}>YouTube</span>
                    </ExternalLink>
                  )}
                  {publicData?.website && (
                    <ExternalLink className={css.socialLink} href={publicData?.website}>
                      <img className={css.socialIcon} src={globalIcon} alt="Personal Site"></img>
                      <span className={css.socialTitle}>Personal Site</span>
                    </ExternalLink>
                  )}
                </div>
              </div>
            </div>
          </div>
          {!!categories.length && (
            <div className={css.categoriesContainer}>
              <p className={css.blockTitle}>Categories</p>
              <div className={css.categoriesContent}>
                {categories.map(category => {
                  return (
                    category && (
                      <NamedLink
                        className={css.categorie}
                        name="CategoriesPage"
                        to={{
                          search: `bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_category=${category}`,
                        }}
                      >
                        {category}
                      </NamedLink>
                    )
                  );
                })}
              </div>
            </div>
          )}
          <div className={css.typesContent}>
            {sections
              .filter(section => isAdmin || section.guard !== 'admin')
              .map(section => (
                <div
                  key={section.id}
                  className={
                    activeSection.id === section.id ? classNames(css.type, css.active) : css.type
                  }
                  onClick={() => navigateToSection(section)}
                >
                  {section.title}
                </div>
              ))}
          </div>
          <div className={css.horizontalLine} />
          {/* {hasBio ? <p className={css.bio}>{bio}</p> : null} */}
          {/* {isMobileLayout ? mobileReviews : desktopReviews} */}
          {activeSection.id === 'listings' && (
            <div className={css.listingPanel}>
              <div className={css.listingCards}>
                {isPro && isOwnListing && (
                  <div className={css.addListingCard}>
                    <span className={css.addListingCardDescription}>
                      Add Product or Service. Find clients and get paid.
                    </span>
                    <NamedLink className={css.createNewListingLink} name="NewListingPage">
                      <button className={css.addButton}>Add new listing</button>
                    </NamedLink>
                  </div>
                )}
                {listings.map(l => {
                  // hide listing with campaignId
                  if (l?.attributes?.publicData?.campaignId) {
                    return null;
                  }

                  if (
                    l?.attributes?.state === 'pendingApproval' ||
                    l?.attributes?.state === 'published'
                  ) {
                    return (
                      <ManageListingCard
                        className={css.listingCard}
                        key={l.id.uuid}
                        listing={l}
                        isMenuOpen={!!listingMenuOpen && listingMenuOpen.id.uuid === l.id.uuid}
                        actionsInProgressListingId={openingListing || closingListing}
                        onToggleMenu={onToggleMenu}
                        onCloseListing={onCloseListing}
                        onOpenListing={onOpenListing}
                        hasOpeningError={openingErrorListingId.uuid === l.id.uuid}
                        hasClosingError={closingErrorListingId.uuid === l.id.uuid}
                        renderSizes={renderSizes}
                        isPro={isPro && isOwnListing}
                        copyUrlPage={copyUrlPage}
                        setSelectedListing={setSelectedListing}
                        onContactUser={onContactUser}
                      />
                    );
                  }
                })}
              </div>
              {/* {paginationLinks} */}
            </div>
          )}
          {activeSection.id === 'reviews' &&
            (!!reviewsOfProvider.length ? <Reviews reviews={reviewsOfProvider} /> : 'No reviews')}

          {activeSection.id === 'portfolio' && (
            <>
              <div className={css.resumeContainer}>
                {isPro && isOwnListing && (
                  <div className={css.addListingCard}>
                    <span className={css.addListingCardDescription}>
                      Upload your portfolio as a PDF-file
                    </span>
                    <button className={css.addButton} onClick={() => setShowResumeInput(true)}>
                      Upload portfolio
                    </button>
                  </div>
                )}
                {user?.attributes?.profile?.publicData?.resumeUrl &&
                user?.attributes?.profile?.publicData?.resumeUrl.includes('.pdf') ? (
                  <div>
                    <iframe
                      src={user?.attributes?.profile?.publicData?.resumeUrl}
                      className={css.iframe}
                    />
                    <a
                      className={css.link}
                      href={user?.attributes?.profile?.publicData?.resumeUrl}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Open portfolio in a new tab
                    </a>
                  </div>
                ) : user?.attributes?.profile?.publicData?.resumeUrl ? (
                  <div className={css.downloadListingCard}>
                    <a href={user?.attributes?.profile?.publicData?.resumeUrl}>
                      Click to download the portfolio
                    </a>
                  </div>
                ) : (
                  'No portfolio'
                )}
              </div>
              <Modal
                id="Profilepage.resumeModal"
                isOpen={showResumeInput}
                onClose={handleResumeModalClose}
                onManageDisableScrolling={onManageDisableScrolling}
                usePortal
              >
                <FileUpload setShowResumeInput={setShowResumeInput} />
              </Modal>
            </>
          )}

          {isAdmin && activeSection.id === 'internal-notes' && (
            <din>{internalNotes ? internalNotes : 'No information'}</din>
          )}

          {activeSection.id === 'analytics' && (
            <CreatorAnalytics creator={user} currentUser={currentUser} />
          )}
        </div>

        {/* <button onClick={downloadPdf}> Download PDF </button> */}
      </div>
    </div>
  );

  let content;

  if (userShowError && userShowError.status === 404) {
    return <NotFoundPage />;
  } else if (userShowError) {
    content = (
      <p className={css.error}>
        <FormattedMessage id="ProfilePage.loadingDataFailed" />
      </p>
    );
  } else {
    content = mainContent;
  }

  const schemaTitle = intl.formatMessage(
    {
      id: 'ProfilePage.schemaTitle',
    },
    {
      name: displayName,
      siteTitle: config.siteTitle,
    }
  );

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ProfilePage',
        name: schemaTitle,
      }}
    >
      <LayoutSideNavigation containerClassName={css.sidebarContainer}>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage={isPro && isOwnListing ? 'ProProfilePage' : 'ProfilePage'} />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSideNav
          currentTab={isPro && isOwnListing ? 'ProProfilePage' : 'ProfilePage'}
          userProfile={true}
          isAvatar={true}
          currentUser={currentUser}
          isProUser={true}
        />
        <LayoutWrapperMain className={css.wrapperMain}>
          {content}
          <Modal
            id="ListingPage.enquiry"
            contentClassName={css.enquiryModalContent}
            isOpen={isAuthenticated && enquiryModalOpen}
            onClose={() => {
              setEnquiryModalOpen(false);
            }}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <EnquiryForm
              className={css.enquiryForm}
              submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
              listingTitle={selectedListing?.attributes?.title}
              authorDisplayName={displayName}
              sendEnquiryError={sendEnquiryError}
              onSubmit={onSubmitEnquiry}
              inProgress={sendEnquiryInProgress}
            />
          </Modal>
        </LayoutWrapperMain>
        {/* <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter> */}
      </LayoutSideNavigation>
      {currentUserLoaded && (
        <Modal
          id="EditListingWizard.payoutModal"
          isOpen={stripeModalOpened}
          onClose={handleStripeModalClose}
          onManageDisableScrolling={onManageDisableScrolling}
          usePortal
        >
          <div className="">
            <Heading as="h2" rootClassName="">
              <FormattedMessage id="EditListingWizard.payoutModalTitleOneMoreThing" />
              <br />
              <FormattedMessage id="EditListingWizard.payoutModalTitlePayoutPreferences" />
            </Heading>
            {!currentUserLoaded ? (
              <FormattedMessage id="StripePayoutPage.loadingData" />
            ) : returnedAbnormallyFromStripe && !getAccountLinkError ? (
              <p className="">
                <RedirectToStripe redirectFn={handleGetStripeConnectAccountLink} />
              </p>
            ) : (
              <>
                <p className="">
                  <FormattedMessage id="EditListingWizard.payoutModalInfo" />
                </p>
                <StripeConnectAccountForm
                  disabled={formDisabled}
                  inProgress={payoutDetailsSaveInProgress}
                  ready={payoutDetailsSaved}
                  currentUser={ensuredCurrentUser}
                  stripeBankAccountLastDigits={getBankAccountLast4Digits(stripeAccountData)}
                  savedCountry={savedCountry}
                  submitButtonText={'Save details'}
                  stripeAccountError={stripeAccountError}
                  stripeAccountFetched={stripeAccountFetched}
                  stripeAccountLinkError={getAccountLinkError}
                  onChange={onPayoutDetailsChange}
                  onSubmit={onPayoutDetailsSubmit}
                  onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink}
                  stripeConnected={stripeConnected}
                >
                  {stripeConnected && !returnedAbnormallyFromStripe && showVerificationNeeded ? (
                    <StripeConnectAccountStatusBox
                      type="verificationNeeded"
                      inProgress={getAccountLinkInProgress}
                      onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                        'custom_account_verification'
                      )}
                    />
                  ) : stripeConnected && savedCountry && !returnedAbnormallyFromStripe ? (
                    <StripeConnectAccountStatusBox
                      type="verificationSuccess"
                      inProgress={getAccountLinkInProgress}
                      disabled={payoutDetailsSaveInProgress}
                      onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                        'custom_account_update'
                      )}
                    />
                  ) : null}
                </StripeConnectAccountForm>
              </>
            )}
          </div>
        </Modal>
      )}
    </Page>
  );
};

ProfilePageComponent.defaultProps = {
  currentUser: null,
  user: null,
  userShowError: null,
  reviews: [],
  queryReviewsError: null,
};

const { bool, arrayOf, number, shape } = PropTypes;

ProfilePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  user: propTypes.user,
  userShowError: propTypes.error,
  reviews: arrayOf(propTypes.review),
  queryReviewsError: propTypes.error,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { userId, userShowError, reviews, queryReviewsError, userListingRefs } = state.ProfilePage;
  const { isAuthenticated, isCreator } = state.Auth;
  const {
    currentPageResultIds,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
  } = state.ManageListingsPage;
  const {
    getAccountLinkInProgress,
    getAccountLinkError,
    createStripeAccountInProgress,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
    stripeAccountFetched,
  } = state.stripeConnectAccount;
  const { payoutDetailsSaveInProgress, payoutDetailsSaved } = state?.StripePayoutPage;
  const { sendEnquiryInProgress, sendEnquiryError } = state.ListingPage;

  const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId }]);
  const user = userMatches.length === 1 ? userMatches[0] : null;
  const listings = getMarketplaceEntities(state, userListingRefs);
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    user,
    userShowError,
    reviews,
    queryReviewsError,
    listings,

    currentPageResultIds,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
    isAuthenticated,
    isCreator,
    sendEnquiryInProgress,
    sendEnquiryError,

    getAccountLinkInProgress,
    getAccountLinkError,
    createStripeAccountInProgress,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
    stripeAccountFetched,
    payoutDetailsSaveInProgress,
    payoutDetailsSaved,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onCloseListing: listingId => dispatch(closeListing(listingId)),
  onOpenListing: listingId => dispatch(openListing(listingId)),
  onSendEnquiry: (listingId, message) => dispatch(sendInquiry(listingId, message)),
  onGetStripeConnectAccountLink: params => dispatch(getStripeConnectAccountLink(params)),
  onPayoutDetailsChange: () => dispatch(stripeAccountClearError()),
  onPayoutDetailsSubmit: (values, isUpdateCall) =>
    dispatch(savePayoutDetails(values, isUpdateCall)),
  onUpdateFavorites: payload => dispatch(updateFavorite(payload)),
  onUpdateListing: (values, authorId, displayName) =>
    dispatch(requestUpdateListingInProfile(values, authorId, displayName)),
});

const ProfilePage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl
)(ProfilePageComponent);

export default ProfilePage;
