import styled from 'styled-components';

const ProStepperCss = styled.div`
  input[type='checkbox'] {
    width: 20px;
    height: 20px;
    border-color: #dddddd;
    border-radius: 5px;
  }
  .reduce_padding {
    margin-top: -40px;
  }

  .steps_main_container {
    .firstDiv,
    .secondDiv,
    .proSecond_main {
      display: flex;
      flex-direction: column;
      gap: 24px;
      @media (max-width: 767px) {
        gap: 32px;
      }
    }

    .secondDiv {
      margin-top: 24px;

      @media (max-width: 767px) {
        margin-top: 32px;
      }
    }

    label {
      font-size: 16px;
      padding-top: 0;
      color: #1c1c1c;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
    }

    .first_container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      @media (max-width: 767px) {
        flex-direction: column-reverse;
        margin-bottom: 30px;
      }
      .title_section_css {
        width: 100%;
        max-width: 523px;
        margin: 0 auto;
        /* padding: 0 20px; */
        h1 {
          letter-spacing: 0;
          color: #1c1c1c;
          margin-bottom: 10px;

          /* CSG 24 Heading */
          font-family: Unbounded;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 38px; /* 158.333% */
          @media (max-width: 767px) {
            font-size: 24px;
          }
        }
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          color: #494656;
          @media (max-width: 767px) {
            font-size: 14px;
          }
        }
      }

      .stepper {
        width: 100%;
        /* max-width: 591px; */

        .stepper-container {
          margin-left: 60px;
          @media (max-width: 767px) {
            margin-left: 0;
          }
          #RFS-Label {
            margin-top: 8px;
            color: var(--typo-primary, #1c1c1c);

            /* CSG 16 Regular */
            font-family: Afacad;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 137.5% */

            @media (max-width: 767px) {
              font-size: 12px;
            }
          }

          #RFS-ConnectorContainer {
            top: calc((44px) / 2);

            @media (max-width: 767px) {
              top: calc((44px) / 2);
            }
          }
        }

        .steps {
          border-radius: 44px;
          width: 44px;
          height: 44px;
          background-color: #fff;
          border: 1px solid #959595;
          margin-bottom: 0;

          @media (max-width: 767px) {
            border-radius: 44px;
            width: 44px;
            height: 44px;
          }

          span {
            color: #959595;
            font-family: 'sofiapro';
            font-size: 16px;

            @media (max-width: 767px) {
              font-size: 14px;
            }

            &.active {
              color: #fff;
            }
          }

          &.active {
            background: var(--color-ContentSG-purple-500, #dd58d6);
            border: none;
          }

          &.completed {
            background: var(--color-ContentSG-purple-500, #dd58d6);
            border: none;

            span {
              color: #fff;
            }
          }
        }
      }
    }
    .secondContainer {
      display: flex;
      justify-content: space-between;
      margin: 0 -15px;

      .mainProFirst_container {
        width: 100%;
        margin-top: -20px;
        padding-right: 50px;
        padding-left: 16px;
        @media (max-width: 767px) {
          padding-right: 16px;
          padding-left: 16px;
        }

        form {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          /* height: 100%; */
          .actionButtons {
            margin-top: 30px;
            display: flex;
            gap: 20px;
            @media (max-width: 767px) {
              flex-direction: column-reverse;
              margin-top: 64px;
              flex-wrap: wrap;
              gap: 12px;
            }
            &.apply_position {
              position: absolute;
              left: 0;
              right: 0;
              bottom: -20px;
              @media (max-width: 767px) {
                position: unset;
              }
            }
            button {
              text-align: center;

              /* CSG 20 Semibold */
              font-family: Afacad;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px; /* 120% */
            }
            .backbutton {
              display: flex;
              align-items: center;

              background: #f8f8f8;
              color: #100c20;
              padding: 12px 16px;
              border-radius: 64px;
              border: 0;
            }
            .backbutton > span {
              flex: 1 0 0;
            }
            .nextbutton {
              display: flex;
              align-items: center;
            }
            .nextbutton > span {
              flex: 1 0 0;
            }
          }
          .fieldsSections {
            .firstDiv {
              margin-bottom: 8px;
            }
          }
        }

        .proSecond_main,
        .LearnerFirst_main {
          gap: 14px;
          margin-bottom: 0px;
          display: flex;
          flex-direction: column;
          .uploadSection {
            display: flex;
            align-items: center;
            gap: 30px;
          }
          .uploadPhotoButton {
            border-radius: 48px;
            border: 1px solid #dd58d6;
            background: #fff;
            color: #dd58d6;
            cursor: pointer;
            padding: 8px 16px;
            font-size: 16px;
          }
          .imagePreview {
            width: 64px;
            height: 64px;
            border-radius: 136px;
            gap: 10px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 136px;
            }
          }
        }

        .proThird_main,
        .LearnerThird_main {
          margin-bottom: 70px;
          display: flex;
          flex-direction: column;
          gap: 25px;

          .listingTypes_options {
            p {
              font-size: 16px;
              line-height: 24px;
              color: #959595;
              margin-bottom: 16px;
            }
            .toggleContainer {
              display: flex;
              flex-wrap: wrap;
              gap: 10px;

              .togglebutton {
                max-width: max-content;
                width: 100%;
                border: 1px solid #e9e9e9;
                background: #fff;
                color: #1c1c1c;
                border-radius: 40px;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                padding: 5px 15px;

                &.active {
                  background: var(--blue-primary);
                  color: #fff;
                }
              }
            }
          }
        }

        .proFourth_main {
          display: flex;
          flex-direction: column;
          gap: 10px;
          height: 530px;
          overflow-y: auto;

          .accordion-item {
            padding: 16px;
            border: 1px solid #dddd;
            border-radius: 12px;
            @media (max-width: 767px) {
              padding: 16px 24px;
            }
            .accordion-title {
              display: flex;
              justify-content: space-between;
              /* margin-bottom: 20px; */
              .text_title {
                font-size: 20px;
                font-weight: 400;
                line-height: 30px;
                letter-spacing: 0em;
                color: #1c1c1c;
              }

              .plus_minusIcon {
                svg {
                  color: #1c1c1c;
                  background-color: #dddd;
                  border-radius: 30px;
                }
              }
            }

            .accordion-content {
              display: flex;
              flex-direction: column;
              gap: 14px;
              margin-top: 30px;
              @media (max-width: 1199px) {
                margin-bottom: 30px;
              }
              @media (max-width: 767px) {
                margin-bottom: 0px;
              }
              .fieldWithLabel {
                overflow: hidden;
                width: 100%;
                p {
                  font-size: 14px;
                  color: #1c1c1c;
                }
                &.photos_data {
                  p {
                    line-height: 20px;
                    margin-bottom: 10px;
                  }
                  .file_selection {
                    button {
                      border-radius: 48px;
                      border: 1px solid #dd58d6;
                      color: #dd58d6;
                      background: #fff;
                    }
                  }
                }
                .price_container {
                  display: flex;
                  align-items: center;
                  gap: 18px;
                  margin-bottom: 12px;

                  input {
                    max-width: 260px;
                    @media (max-width: 767px) {
                      max-width: 120px;
                    }
                  }
                }

                &.checkbox_forth {
                  label {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    p {
                      color: #1c1c1c;
                      font-size: 16px;
                    }
                  }
                }

                .para {
                  font-size: 14px;
                  font-weight: 400;
                  letter-spacing: -0.01em;
                  text-align: left;
                  @media (max-width: 767px) {
                    margin-bottom: 10px;
                  }
                  p:first-child {
                    line-height: 16px;
                  }
                }

                &.location_section {
                  p {
                    color: #656565;
                    line-height: 20px;
                    cursor: pointer;
                    border-bottom: 1px solid #656565;
                    width: 100%;
                    max-width: 145px;
                    margin-top: 10px;
                  }
                  .autocompleteField {
                    margin-bottom: 10px;
                    display: flex;
                    gap: 10px;

                    > div {
                      width: 100%;
                    }

                    input {
                      width: 100%;
                    }

                    button {
                      float: right;
                      width: 100%;
                      max-width: 40px;
                      font-size: 20px;
                      border-radius: 30px;
                      padding: 4px;
                    }
                    .deleteIcon {
                      width: 100%;
                      max-width: 20px;
                      line-height: 30px;
                      border: unset;
                      color: #f00;
                      font-weight: 600;
                    }
                  }
                }
              }
              .calender_main {
                .MyCalendarPage_root {
                  padding: 0 10px;

                  .MyCalendarPage_addExceptionButton {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: -0.01em;
                    text-align: left;
                    background: none;
                    color: #1c1c1c;
                    border-bottom: 1px solid #1c1c1c;
                    width: 100%;
                    max-width: 120px;
                    padding: 0;
                    border-radius: unset;

                    &:hover {
                      text-decoration: unset;
                    }
                  }

                  header {
                    h2 {
                      color: #1c1c1c;
                      font-weight: 600;
                      margin-top: 0;
                    }
                  }
                }
                .MyCalendarPage_week {
                  display: flex;
                  flex-direction: column;
                  gap: 20px;
                  border: unset;

                  .MyCalendarPage_weekDay {
                    width: 100%;
                    max-width: 440px;
                    background: #f2f2f2;
                    padding: 15px;
                    border-radius: 8px;

                    .MyCalendarPage_dayOfWeek {
                      span {
                        color: #1c1c1c;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        letter-spacing: 0em;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .imageSection {
        height: 600px !important;
        img {
          @media (max-width: 1199px) {
            object-fit: cover;
          }
        }
      }
      .selectButtonContainer {
        display: flex;
      }
      .selectButton {
        border: 1px solid #ddd;
        border-radius: 48px;
        padding: 8px 16px;
      }
    }
  }
  button {
    background-color: #dd58d6;
    border: 1px solid #dd58d6;
    color: #fff;
    border-radius: 48px;
    padding: 8px 20px;
    cursor: pointer;
  }

  .toggleContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .togglebutton {
      max-width: max-content;
      width: 100%;
      border: 1px solid #e9e9e9;
      background: #fff;
      color: #1c1c1c;
      border-radius: 40px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      padding: 5px 15px;
      cursor: pointer;

      &.active {
        background: #dd58d6;
        color: #fff;
      }
    }
  }

  .uploadPhotosButton {
    background: #fff;
    color: #dd58d6;
    width: fit-content;
  }
  .uploadPhotosLabel {
    color: #656565;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 16px;
  }

  .mediaList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
  }

  .video {
    position: relative;
  }

  .image {
    position: relative;
    img {
      width: 150px;
      height: auto;
    }
  }

  .removeButton {
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    width: 33px;
    height: 33px;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.5) !important;
    border: none !important;
    border-radius: 0;
    border-bottom-left-radius: 2px;
    cursor: pointer;

    & svg {
      position: absolute;
      top: 12px;
      left: 12px;
      width: 10px;
      height: 10px;
      fill: var(--matterColorAnti);
      stroke: var(--matterColorAnti);
    }

    &:hover svg {
      fill: var(--matterColorLight);
      stroke: var(--matterColorLight);
    }
  }

  .addOnsDesc {
    margin: 0;
    color: var(--typo-secondary, #656565);
    font-family: Afacad;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin-bottom: 16px;
  }

  .addOnsItem {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
    gap: 16px;
    margin-bottom: 8px;
  }

  .addOnsInput {
    display: flex;
    flex-wrap: nowrap;
    gap: 16px;
    align-items: center;
    position: relative;

    svg {
      cursor: pointer;
    }

    input {
      padding-left: 48px;
    }

    .currency {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .addOnsButton {
    color: var(--color-ContentSG-purple-500, #dd58d6);
    /* CSG 16 Semibold */
    font-family: Afacad;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 137.5% */
    padding: 0;
    background-color: transparent;
    border: none;
    width: fit-content;
    margin-bottom: 8px;
    cursor: pointer;
  }

  .nicheTitle {
    color: #1c1c1c;
  }

  .addOnsInput {
    display: flex;
    flex-wrap: nowrap;
    gap: 16px;
    align-items: center;
    position: relative;
  }

  .addOnsCurrency {
    position: absolute;
    left: 0;
    top: 25px;
    transform: translate(50%, -50%);
  }

  .logo_section {
    display: flex;
    gap: 32px;
    margin-bottom: 16px;
    margin-top: 8px;

    font-style: normal;
  }

  .normal_font {
    color: #494656;

    font-family: Afacad;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .logo_item_flex {
    display: flex;
    gap: 16px;
  }

  .socialInputsBlock {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  .socialInput {
    display: flex;
    flex: 1;
    width: 100% !important;
  }

  .socialInputWrapper {
    width: 48%;
  }
`;

export default ProStepperCss;
